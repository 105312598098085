export default {
  Title: 'Καταστήματα | Κατάστημα | Καταστήματα',

  SelectStoreDialog: {
    Title   : 'Καταστήματα',
    Subtitle: 'Επιλέξτε κατάστημα'
  },

  Status: {
    Opened: 'Ανοιχτά',
    Closed: 'Κλειστά'
  },

  OrderTime: {
    Suffix  : '@:Store.OrderTime.SuffixMD',
    SuffixXL: 'λεπτά',
    SuffixMD: 'λεπτ.',
    SuffixXS: '"'
  }
}
