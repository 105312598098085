import { API } from '@/api/Api'

export default {
  async Login (username, password, config = {}) {
    const Config = { ...config }
    return await API.RESTRequest(API.Endpoint.User.Login, {
      email   : username,
      password: password
    }, Config)
  },

  async Logout () {
    return await API.RESTRequest(API.Endpoint.User.Logout)
  },

  async ResetPassword (data = {}, config = {}) {
    const Endpoint = API.Endpoint.User.ResetPassword
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async AuthStatus (data = {}, config = {}) {
    const Endpoint = API.Endpoint.User.AuthStatus
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Register (data = {}, config = {}) {
    const Endpoint = API.Endpoint.User.Register
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async AuthGoogle (data = {}, config = {}) {
    const Endpoint = API.Endpoint.User.AuthGoogle
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async AuthFacebook (data = {}, config = {}) {
    const Endpoint = API.Endpoint.User.AuthFacebook
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async AuthSocial (provider, data = {}, config = {}) {
    // eslint-disable-next-line no-console
    console.log('>>>>> AuthSocial', provider, data, config)
    if (provider === 'google') return await this.AuthGoogle(data, config)
    if (provider === 'facebook') return await this.AuthFacebook(data, config)
  }

}
