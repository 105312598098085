import API from '@/api/Api'
import CartCommon from '@/mixins/cart/cartCommon'
import { isArray, isObject } from '@/lib/utils/type'
import NotificationTypeEnum from '@/api/enums/NotificationTypeEnum'
import StoreCommon from '@/mixins/store/storeCommon'
import CheckoutPaymentModel from '@/api/models/checkout/CheckoutPaymentModel'
import GuestModel from '@/api/models/customer/GuestModel'

export default {
  name: 'PaymentGatewayCommon',

  mixins: [CartCommon, StoreCommon],

  props: {
    form: {
      type   : Object,
      default: undefined
    },

    model: {
      type   : Object,
      default: undefined
    }
  },

  data () {
    return {
      loading : false,
      disabled: false
    }
  },

  methods: {
    completeOrder () {
      if (!this.isValidPayment()) return

      this.requestData()
    },

    submitData (data = {}) {
      if (this.loading || !this.$parent.validateForm()) return
      if (typeof this.$parent.clearServerErrorMessages === 'function') this.$parent.clearServerErrorMessages()
      this.loading = true

      const paymentDataModel = new CheckoutPaymentModel(this.$parent.model.clone())
      if (this.userIsAuthenticated) {
        paymentDataModel.Customer = new GuestModel(this.user.Info)
      }

      const payload = { ...paymentDataModel, ...(data || {}) }

      API.Resource.Checkout.Payment(payload)
        .then(response => {
          if (API.isResponseSuccess(response) && !!API.responseData(response)?.Success) {
            this.onRequestSuccess(response)
          } else {
            this.loading = false
            this.onRequestError(response)
          }
        })
        .catch(() => {
          this.loading = false
          this.onRequestError()
        })
        .finally(() => {
        })
    },

    requestData () {
      this.submitData()
    },

    onRequestSuccess (response) {
      this.requestUpdateCart(response)
      this.requestSuccessNotify(response)
      this.parseRequestMessages(response)
      this.$router.replace({ name: 'CheckoutSuccess' })
    },

    onRequestError (response) {
      this.parseRequestMessages(response)
    },

    requestUpdateCart (response) {
      // this.$bus.$emit('cart:set-data', API.responseData(response)?.Cart)
      this.$bus.$emit('cart:get-data')
    },

    requestSuccessNotify (response) {
      this.$bus.$emit('cart:payment-gateway:response', API.responseData(response))
    },

    parseRequestMessages (response) {
      if (response) {
        const ResponseMessages = API.responseData(response)?.Result || []

        if (isObject(ResponseMessages)) {
          if (ResponseMessages.Success || ResponseMessages?.Messages?.Success) {
            this.showResponseNotification(ResponseMessages.Success || ResponseMessages?.Messages?.Success, '', '', '', NotificationTypeEnum.SUCCESS)
          } else if (ResponseMessages.Info || ResponseMessages?.Messages?.Info) {
            this.showResponseNotification(ResponseMessages.Info || ResponseMessages?.Messages?.Info, '', '', '', NotificationTypeEnum.INFO)
          } else if (ResponseMessages.Warning || ResponseMessages?.Messages?.Warning) {
            this.showResponseNotification(ResponseMessages.Warning || ResponseMessages?.Messages?.Warning, '', '', '', NotificationTypeEnum.WARNING)
          } else if (ResponseMessages.Error || ResponseMessages?.Messages?.Error) {
            this.showResponseNotification(ResponseMessages.Error || ResponseMessages?.Messages?.Error, '', '', '', NotificationTypeEnum.ERROR)
          }
        }

        if (isArray(ResponseMessages)) {
          ResponseMessages.forEach(item => {
            const store = this.getStoreById(item.StoreId)

            if (item.Messages?.Success) {
              this.showResponseNotification(item.Messages.Success, store.Name || '', '', store.Logo || '', NotificationTypeEnum.SUCCESS)
            } else if (item.Messages?.Info) {
              this.showResponseNotification(item.Messages.Info, store.Name || '', '', store.Logo || '', NotificationTypeEnum.INFO)
            } else if (item.Messages?.Warning) {
              this.showResponseNotification(item.Messages.Warning, store.Name || '', '', store.Logo || '', NotificationTypeEnum.WARNING)
            } else if (item.Messages?.Error) {
              this.showResponseNotification(item.Messages.Error, store.Name || '', '', store.Logo || '', NotificationTypeEnum.ERROR)
            }

            // if (item.Fetch) this.getStoreMenu(item.StoreId)
          })
        }

        this.requestUpdateCart(response)
      }
    },

    showResponseNotification (message, title, icon, img, type = 'success') {
      if (!message) return

      this.$bus.$emit('app:show-notification', {
        title: title || '',
        body : message || '',
        type : type || 'success',
        icon : icon || '',
        img  : img || ''
      })
    },

    isValidPayment () {
      let isValid = true

      if (this.selectedPaymentMethod) {
        if (!this.orderTotalAmountValid) {
          this.setPaymentTypeErrorMessage(this.selectedPaymentMethod)
          isValid = false
        }
      } else {
        isValid = false
      }

      return isValid
    },

    setPaymentTypeErrorMessage (payment) {
      if (!payment) return

      const paymentMethodName = payment.lang && payment.lang[this.$i18n.locale] ? payment.lang[this.$i18n.locale].name : payment.name
      const minimumOrderTotal = this.$options.filters.currency((payment.data && payment.data.minimum_order_total) ? payment.data.minimum_order_total : 0)
      const minimumOrderTotalDiff = this.$options.filters.currency(this.orderMinTotalAmountDiffPayment(payment))

      this.$bus.$emit('app:show-notification', {
        title: this.$t('Cart.Message.Info.MinimumOrder.Title'),
        body : this.$t('Cart.Message.Info.MinimumOrder.Body', {
          paymentMethodName    : paymentMethodName,
          minimumOrderTotal    : minimumOrderTotal,
          minimumOrderTotalDiff: minimumOrderTotalDiff
        }),
        type   : 'error',
        icon   : '',
        timeout: 5000
      })
    }
  },

  computed: {
    modelForm () {
      return this.form
    }
  }
}
