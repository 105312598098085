<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <h1 class="d-inline-block">
          {{ $t('Checkout.Details.Title') }}
        </h1>
      </v-col>
    </v-row>

    <v-row v-if="AppType.isQRScanApp && AppQrData">
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <qr-code-order-data class="pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <v-expansion-panels
          v-model="panel"
          accordion
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header
              :color="$vuetify.theme.dark ? '' : 'grey lighten-3'"
              class="px-2 rounded"
            >
              <h2>{{ $t('Checkout.Details.OrderDetails.Title') }}</h2>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="px-0">
              <v-list
                v-if="cartProductsCount"
                three-line
              >
                <template v-if="AppConfig.Cart.GroupByStore">
                  <template v-for="(store, storeIdx) in cartProductsGroupedByStore">
                    <div
                      :key="`cart-store-${storeIdx}`"
                      class="px-4 py-2 d-flex align-center fill-width"
                    >
                      <v-avatar
                        v-if="AppConfig.Cart.GroupShowStoreLogo"
                        class="mr-2"
                        size="32"
                      >
                        <img :src="store.Logo">
                      </v-avatar>

                      <div class="title">
                        {{ store.Name }}
                      </div>

                      <v-spacer />

                      <div class="grey--text text-caption text-no-wrap pl-2">
                        <v-chip
                          v-if="storeIsOpened(store)"
                          color="brand-primary"
                          dark
                          label
                          small
                        >
                          {{ storeDefaultTime(store) }}
                        </v-chip>

                        <v-chip
                          v-else
                          color="red"
                          dark
                          label
                          small
                        >
                          {{ $t('Store.Status.Closed').toLocaleUpperCase($i18n.locale) }}
                        </v-chip>
                      </div>
                    </div>

                    <template v-for="(product, productIdx) in store.Items">
                      <v-lazy
                        :key="`cart-store-${storeIdx}-product-lazy-${productIdx}`"
                        min-height="90"
                        transition="fade-transition"
                      >
                        <checkout-product-list-item
                          :key="`checkout-store-${storeIdx}-product-${productIdx}`"
                          :product="product"
                          :store-logo="AppConfig.Cart.ProductShowStoreLogo"
                          @click="() => {}"
                        />
                      </v-lazy>

                      <v-divider :key="`cart-store-${storeIdx}-product-divider-${productIdx}`" />
                    </template>
                  </template>
                </template>

                <template v-else>
                  <template v-for="(product, idx) in AppCart.Items">
                    <v-lazy
                      :key="`cart-product-lazy-${idx}`"
                      min-height="110"
                      transition="fade-transition"
                    >
                      <checkout-product-list-item
                        :key="`checkout-product-${idx}`"
                        :product="product"
                        @click="() => {}"
                      />
                    </v-lazy>

                    <v-divider :key="`cart-product-divider-${idx}`" />
                  </template>
                </template>
              </v-list>

              <div v-else>
                <div class="text-center">
                  <div class="mb-6">
                    <v-icon
                      color="brand-primary"
                      size="128"
                    >
                      mdi-basket-plus
                    </v-icon>
                  </div>

                  <h2>{{ $t('Cart.Message.Info.NoItems.Title') }}</h2>

                  <h4 class="font-weight-regular mt-4">
                    {{ $t('Cart.Message.Info.NoItems.Subtitle') }}
                  </h4>
                </div>

                <v-btn
                  :large="$vuetify.breakpoint.xsOnly"
                  :to="{name: 'Home'}"
                  :x-large="$vuetify.breakpoint.smAndUp"
                  block
                  class="text-capitalize mt-4"
                  color="brand-primary lighten-5 brand-primary--text"
                  dark
                  depressed
                >
                  {{ $t('Cart.Button.AddItems') }}
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <h2 class="d-inline-block">
          {{ $t('Checkout.Details.CustomerDetails.Title') }}
        </h2>
      </v-col>

      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <v-alert
          border="left"
          class="ma-0"
          colored-border
          dense
          icon="mdi-help"
          text
          type="info"
        >
          <template v-if="userIsAuthenticated">
            {{ $t('Checkout.Details.CustomerDetails.Notification.Logout', {user: user.Info.Firstname}) }}
            <a
              class=""
              @click="$bus.$emit('app:logout')"
            >
              {{ $t('Auth.Button.Logout') }}
            </a>
          </template>

          <template v-else>
            {{ $t('Checkout.Details.CustomerDetails.Notification.Login') }}
            <a
              class=""
              @click="$bus.$emit('login:show-dialog')"
            >
              <span
                class="font-weight-medium"
              >{{ $t('Auth.Button.Login') }}</span>
            </a>.

            {{ $t('Checkout.Details.CustomerDetails.Notification.NoAccount') }}
            <a
              class=""
              @click="$bus.$emit('register:show-dialog')"
            >
              <span
                class="font-weight-medium"
              >{{ $t('Auth.Button.Register') }}</span>
            </a>

            {{ $t('Checkout.Details.CustomerDetails.Notification.ContinueAsGuest') }}
          </template>
        </v-alert>
      </v-col>

      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <v-form
          v-if="model"
          ref="form"
          v-model="isFormValid"
        >
          <v-container
            fluid
            pa-0
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="customerModel.Firstname"
                  :disabled="userIsAuthenticated"
                  :hint="$t('Checkout.Details.CustomerDetails.Field.Firstname.Hint')"
                  :label="$t('Checkout.Details.CustomerDetails.Field.Firstname.Label')"
                  :placeholder="$t('Checkout.Details.CustomerDetails.Field.Firstname.Placeholder')"
                  :rules="customerModelValidatorVuetifyFormFieldRules('Firstname')"
                  autocomplete="given-name"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  min="2"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="customerModel.Lastname"
                  :disabled="userIsAuthenticated"
                  :hint="$t('Checkout.Details.CustomerDetails.Field.Lastname.Hint')"
                  :label="$t('Checkout.Details.CustomerDetails.Field.Lastname.Label')"
                  :placeholder="$t('Checkout.Details.CustomerDetails.Field.Lastname.Placeholder')"
                  :rules="customerModelValidatorVuetifyFormFieldRules('Lastname')"
                  autocomplete="family-name"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  min="2"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="customerModel.Email"
                  :disabled="userIsAuthenticated"
                  :hint="$t('Checkout.Details.CustomerDetails.Field.Email.Hint')"
                  :label="$t('Checkout.Details.CustomerDetails.Field.Email.Label')"
                  :placeholder="$t('Checkout.Details.CustomerDetails.Field.Email.Placeholder')"
                  :rules="customerModelValidatorVuetifyFormFieldRules('Email')"
                  autocomplete="email"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  type="email"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="customerModel.Phone"
                  v-mask="'##############'"
                  :disabled="userIsAuthenticated"
                  :hint="$t('Checkout.Details.CustomerDetails.Field.Phone.Hint')"
                  :label="$t('Checkout.Details.CustomerDetails.Field.Phone.Label')"
                  :placeholder="$t('Checkout.Details.CustomerDetails.Field.Phone.Placeholder')"
                  :rules="customerModelValidatorVuetifyFormFieldRules('Phone')"
                  autocomplete="mobile phone"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  type="tel"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h2 class="d-inline-block">
                  {{ $t('Checkout.Details.OrderComments.Title') }}
                </h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="model.Comment"
                  :hint="$t('Checkout.Details.OrderComments.Field.Comments.Hint')"
                  :label="$t('Checkout.Details.OrderComments.Field.Comments.Label')"
                  :placeholder="$t('Checkout.Details.OrderComments.Field.Comments.Placeholder')"
                  :rules="model.validator.vuetifyFormFieldRules('Comment')"
                  auto-grow
                  color="brand-primary"
                  filled
                  hide-details="auto"
                  rows="3"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>

    <!-- Payment Methods-->
    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <select-payment-method
          v-model="selectedPaymentMethod"
          :title="$t('Checkout.Details.PaymentDetails.Title')"
        />
      </v-col>
    </v-row>

    <!-- Payment Gateways-->
    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <template v-if="orderTotalAmountValid && cartProductsCount">
          <template v-if="!AppType.isQRScanApp && !AppQrData && AppConfig.Marketplace.SelectQrDataRequired">
            <v-btn
              :large="$vuetify.breakpoint.xsOnly"
              :loading="loading"
              :x-large="$vuetify.breakpoint.smAndUp"
              block
              class="text-capitalize pl-2 pr-3 white--text"
              color="brand-primary"
              depressed
              @click="onSelectTable"
            >
              {{ $t('Common.SelectQrItemDialog.Subtitle') }}
            </v-btn>
          </template>

          <template v-else>
            <generic-payment-gateway
              v-if="selectedPaymentMethod && selectedPaymentMethod.Code === PaymentTypeEnum.CASH"
            />

            <viva-smart-payment-gateway
              v-if="selectedPaymentMethod && selectedPaymentMethod.Code === PaymentTypeEnum.VIVA"
            />
          </template>
        </template>

        <payment-minimum-order-warning v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import CheckoutProductListItem from '@/theme/default/views/checkout/CheckoutProductListItem.vue'
import CartCommon from '@/mixins/cart/cartCommon'
import Translatable from '@/mixins/translatable'
import FormDataCommon from '@/mixins/form/formDataCommon'
import SelectPaymentMethod from '@/theme/default/components/payment/common/SelectPaymentMethod.vue'
import PaymentMinimumOrderWarning from '@/theme/default/components/payment/common/PaymentMinimumOrderWarning.vue'
import GenericPaymentGateway from '@/theme/default/components/payment/GenericPaymentGateway.vue'
import PaymentTypeEnum from '@/api/enums/PaymentTypeEnum'
import VivaSmartPaymentGateway from '@/theme/default/components/payment/VivaSmartPaymentGateway.vue'
import CheckoutPaymentModel from '@/api/models/checkout/CheckoutPaymentModel'
import StoreCommon from '@/mixins/store/storeCommon'
import { clone } from '@/lib/utils/helper'
import QrCodeOrderData from '@/theme/default/components/common/QrCodeOrderData.vue'

export default {
  name      : 'CheckoutDetails',
  components: {
    QrCodeOrderData,
    VivaSmartPaymentGateway,
    GenericPaymentGateway,
    PaymentMinimumOrderWarning,
    SelectPaymentMethod,
    CheckoutProductListItem
  },
  directives: {},
  mixins    : [i18nRouteMeta, Translatable, CartCommon, FormDataCommon, StoreCommon],
  props     : {},
  enums     : { PaymentTypeEnum },
  dataStore : {
    AppCart    : 'App.Cart',
    AppStores  : 'App.Stores',
    AppType    : 'App.Type',
    AppPayments: 'App.Payments'
  },
  dataModel: CheckoutPaymentModel,
  data () {
    return {
      panel  : null,
      loading: false
    }
  },
  computed: {
    customerModel () {
      return this.model.Customer
    },
    customerModelValidator () {
      return this.customerModel.validator
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.$bus.$emit('cart:get-data')
    this.$bus.$on('Auth:Login:Success', this.onLogin)
    this.$bus.$on('Auth:Logout:Success', this.onLogout)
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('Auth:Login:Success', this.onLogin)
    this.$bus.$off('Auth:Logout:Success', this.onLogout)
  },
  destroyed () {
  },
  methods: {
    init () {
      this.$nextTick(() => {
        if (this.AppConfig.Checkout.SelectedProductsExpanded) this.panel = 0
        this.model.QrData = this.AppQrData || null
        this.model.Payment = this.AppCart?.PaymentMethod?.Code || PaymentTypeEnum.CASH
        this.onLogin()
      })
    },

    onSelectTable () {
      this.$bus.$emit('select-table:show-dialog')
    },

    onLogin () {
      if (this.userIsAuthenticated && this.model) this.model.Customer = clone(this.user.Info)
    },

    onLogout () {
      if (this.model) this.model.Customer.Id = null
    },

    customerModelValidatorVuetifyFormFieldRules (field) {
      return this.customerModelValidator?.vuetifyFormFieldRules(field) || []
    }
  }
}
</script>

<style scoped>
/deep/ .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
