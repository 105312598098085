<template>
  <div
    v-if="product"
    :class="{'grey--text lighten-1': disabled}"
  >
    <span
      v-if="showQuantity && product.Quantity > 1"
      class="font-weight-bold orange--text"
    >
      {{ product.Quantity }} x
    </span>

    <div
      v-if="product.SpecialStatus && product.IsSpecial"
      class="d-inline-block"
    >
      <span class="red--text mr-1">{{ productSpecialPrice | currency }}</span>
      <span class="grey--text caption text-decoration-line-through">{{ productPrice | currency }}</span>
    </div>

    <div
      v-else
      class="brand-primary--text d-inline-block"
    >
      <template v-if="product.UnitId > 1">
        {{ productUnitBasePriceWithOptions | currency }}
        <span
          class="grey--text text-caption"
        >/ {{ getMeasurementUnitById(product.UnitId).BaseUnit }}</span>
      </template>

      <template v-else>
        {{ productPrice | currency }}
      </template>
    </div>
  </div>
</template>
<script>

import { floatNumber } from '@/lib/helpers'

export default {
  name      : 'ProductPrice',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    product: {
      type   : Object,
      default: undefined
    },

    autoCalculate: {
      type   : Boolean,
      default: false
    },

    showQuantity: {
      type   : Boolean,
      default: false
    },

    cart: {
      type   : Boolean,
      default: false
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    productPrice () {
      if (this.cart) return floatNumber(this.product.Price)

      if (this.autoCalculate) return floatNumber(this.product.PriceFmt)

      return floatNumber(this.product.ShowCalculatedPrice ? (floatNumber(this.product.OptionsPrice) + floatNumber(this.product.PriceFmt)) : floatNumber(this.product.PriceFmt))
    },

    productSpecialPrice () {
      if (this.autoCalculate) return floatNumber(this.product.SpecialPriceFmt)

      return floatNumber(this.product.ShowCalculatedPrice ? (floatNumber(this.product.OptionsPrice) + floatNumber(this.product.SpecialPriceFmt)) : floatNumber(this.product.SpecialPriceFmt))
    },

    productUnitBasePriceWithOptions () {
      if (!this.product || this.product?.UnitId <= 1) return 0

      if (this.cart) return floatNumber(this.product.Price)

      let optionsPrice = 0
      const options = this.product?.Options || []

      for (let i = 0; i < options.length; i++) {
        const item = options[i]

        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]

          if (item.DisplayType === 'unit_value') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              optionsPrice = floatNumber(floatNumber(optionsPrice) + floatNumber(option.Price))
            }
          }
        }
      }

      const price = this.product?.OriginalUnitPrice ? floatNumber(this.product.OriginalUnitPrice) : floatNumber(this.product.PriceFmt)

      return floatNumber(price + optionsPrice)
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getMeasurementUnitById (id) {
      if (!id) return null

      return this.$t('Common.Units')?.find(item => item.Id === id) || null
    }
  }
}
</script>

<style scoped>

</style>
