/**
 *
 * 12345 => 12.345,00€
 *
 * @param {String || Number} amount value
 */
import { floatNumber } from '@/lib/helpers'
import DataStore from '@/lib/data/dataStore/DataStore'

function currency (value = 0) {
  const Locale = DataStore?.App?.Config?.Marketplace?.Currency?.locale || 'el-GR'
  const Currency = DataStore?.App?.Config?.Marketplace?.Currency?.name || 'EUR'
  value = String(value).replace(',', '')
  return new Intl.NumberFormat(Locale, {
    style   : 'currency',
    currency: Currency
  }).format(floatNumber(value))
}

export default currency
