import { API } from '@/api/Api'

export default {
  async Payment (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Checkout.Payment
    const Data = { ...data }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Success (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Checkout.Success
    const Data = { ...data }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  }
}
