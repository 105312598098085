import BaseResource from '@/lib/data/resource/BaseResource'

export default class GuestResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Firstname = payload.Firstname ?? ''
    this.Lastname = payload.Lastname ?? ''
    this.Email = payload.Email ?? ''
    this.Phone = payload.Phone ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
