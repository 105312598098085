<template>
  <v-toolbar
    elevation="3"
    style="position: fixed; width: 100%; z-index: 1;"
  >
    <v-container
      fluid
      ma-0
      pa-0
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          lg="10"
          offset-lg="1"
        >
          <div class="d-flex">
            <v-btn
              v-if="StoreInfo"
              class="px-sm-2 pr-sm-1 pa-1 pr-0 mr-2"
              color="grey"
              depressed
              height="48"
              large
              outlined
              style="min-width: 25px;"
              @click="onSelectShopClick()"
            >
              <v-avatar
                size="36"
                tile
              >
                <img :src="StoreInfo.Logo">
              </v-avatar>

              <span class="brand-primary--text mx-2 hidden-xs-only">
                {{ StoreInfo.Name }}
              </span>

              <v-icon>
                mdi-chevron-down
              </v-icon>
            </v-btn>

            <v-tabs
              v-if="StoreMenu"
              v-model="selectedTab"
              :show-arrows="$vuetify.breakpoint.mdAndUp"
              style="width: 0"
            >
              <v-tabs-slider color="brand-primary" />

              <template v-for="(category, idx) in StoreMenu.categories">
                <v-tab
                  v-if="category.Status"
                  :key="`category-${category.Id}-${idx}`"
                  :tab-value="`tab-${getTranslatedField(category, 'Slug')}`"
                  class="text-transform-none"
                  @click="onCategoryClick(category)"
                >
                  {{ toLocaleUpperCase(getTranslatedField(category)) }}
                </v-tab>
              </template>
            </v-tabs>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { toLocaleUpperCase } from '@/lib/helpers'
import Translatable from '@/mixins/translatable'

export default {
  name      : 'StoreCatalogCategories',
  components: {},
  directives: {},
  mixins    : [Translatable],
  props     : {},
  dataStore : {
    StoreMenu: 'Store.Menu',
    StoreInfo: 'Store.Info'
  },
  data () {
    return {
      scrolling  : false,
      selectedTab: null
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.$bus.$on('category:select', this.onCategorySelect)
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('category:select', this.onCategorySelect)
  },
  destroyed () {
  },
  methods: {
    toLocaleUpperCase,
    onSelectShopClick () {
      this.$bus.$emit('show:select-store')
    },

    onCategoryClick (category) {
      this.scrolling = true

      goTo(`#tab-${this.getTranslatedField(category, 'Slug')}`, {
        offset  : 65,
        duration: 500
      }).then(() => {
        setTimeout(() => {
          this.scrolling = false
        }, 100)
      })
    },

    onCategorySelect (tabId) {
      if (this.scrolling) return

      this.selectedTab = tabId
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 959px) {
  /deep/ .v-slide-group__prev, /deep/ .v-slide-group__next {
    display: none !important;
  }
}
</style>
