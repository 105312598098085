import { API } from '@/api/Api'

export default {
  async Get (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Cart.Get
    const Data = { ...data }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Add (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Cart.Add
    const Data = { ...data }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Remove (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Cart.Remove
    const Data = { ...data }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Options (storeId, productId, rowId, data = {}, config = {}) {
    const Endpoint = API.Endpoint.Cart.Options
    Endpoint.Path = Endpoint.Path.replace('{storeId}', storeId)
    Endpoint.Path = Endpoint.Path.replace('{productId}', productId)
    Endpoint.Path = Endpoint.Path.replace('{rowId}', rowId)

    const Data = { ...data }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async CouponAdd (couponCode, data = {}, config = {}) {
    const Endpoint = API.Endpoint.Cart.Coupon
    const Data = {
      coupon: {
        Action: 'add',
        Code  : couponCode
      },
      ...data
    }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async CouponRemove (couponCode, data = {}, config = {}) {
    const Endpoint = API.Endpoint.Cart.Coupon
    const Data = {
      coupon: {
        Action: 'remove',
        Code  : couponCode
      },
      ...data
    }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  }
}
