<template>
  <v-form
    v-if="model"
    ref="form"
    @submit.prevent
  >
    <v-container
      v-if="user"
      fluid
    >
      <v-row dense>
        <v-col cols="12">
          <h3>{{ $t('Account.Settings.Profile') }}</h3>
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Firstname.Label') }}
        </v-col>

        <v-col class="auto">
          <v-text-field
            v-model="model.Firstname"
            :error-messages="serverErrorMessages['first_name']"
            :hint="$t('Account.Settings.Field.Firstname.Hint')"
            :placeholder="$t('Account.Settings.Field.Firstname.Placeholder')"
            :rules="model.validator.vuetifyFormFieldRules('Firstname')"
            clearable
            dense
            flat
            name="Firstname"
            outlined
            prepend-inner-icon="mdi-account-circle"
            type="text"
          />
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Lastname.Label') }}
        </v-col>

        <v-col class="auto">
          <v-text-field
            v-model="model.Lastname"
            :error-messages="serverErrorMessages['last_name']"
            :hint="$t('Account.Settings.Field.Lastname.Hint')"
            :placeholder="$t('Account.Settings.Field.Lastname.Placeholder')"
            :rules="model.validator.vuetifyFormFieldRules('Lastname')"
            clearable
            dense
            flat
            name="Lastname"
            outlined
            prepend-inner-icon="mdi-account-circle"
            type="text"
          />
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Phone.Label') }}
        </v-col>

        <v-col class="auto">
          <v-text-field
            v-model="model.Phone"
            :error-messages="serverErrorMessages['phone']"
            :hint="$t('Account.Settings.Field.Phone.Hint')"
            :placeholder="$t('Account.Settings.Field.Phone.Placeholder')"
            :rules="model.validator.vuetifyFormFieldRules('Phone')"
            clearable
            dense
            flat
            name="phone"
            outlined
            prepend-inner-icon="mdi-cellphone-text"
            type="tel"
          />
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Email.Label') }}
        </v-col>

        <v-col class="auto">
          <v-text-field
            v-model="model.Email"
            :disabled="true"
            :error-messages="serverErrorMessages['email']"
            :hint="$t('Account.Settings.Field.Email.Hint')"
            :placeholder="$t('Account.Settings.Field.Email.Placeholder')"
            :rules="model.validator.vuetifyFormFieldRules('Email')"
            clearable
            dense
            flat
            name="email"
            outlined
            prepend-inner-icon="mdi-email"
            type="email"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <h3>{{ $t('Account.Settings.ChangePass') }}</h3>
        </v-col>
      </v-row>

      <v-row
        class="align-start"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Password.Label') }}
        </v-col>

        <v-col class="auto">
          <v-text-field
            v-model="model.NewPass"
            :error-messages="serverErrorMessages['password']"
            :hint="$t('Account.Settings.Field.NewPass.Hint')"
            :placeholder="$t('Account.Settings.Field.NewPass.Placeholder')"
            :rules="model.validator.vuetifyFormFieldRules('NewPass')"
            clearable
            dense
            flat
            name="new-password"
            outlined
            prepend-inner-icon="mdi-lock"
            type="password"
          />

          <v-text-field
            v-model="model.NewPassConfirm"
            :error-messages="serverErrorMessages['password_confirmation']"
            :hint="$t('Account.Settings.Field.NewPassConfirm.Hint')"
            :placeholder="$t('Account.Settings.Field.NewPassConfirm.Placeholder')"
            :rules="model.validator.vuetifyFormFieldRules('NewPassConfirm')"
            clearable
            dense
            flat
            name="new-password-confirm"
            outlined
            prepend-inner-icon="mdi-lock"
            type="password"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <h3>{{ $t('Account.Settings.Notification') }}</h3>
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Newsletter.Label') }}
        </v-col>

        <v-col class="auto">
          <v-switch
            v-model="model.Newsletter"
            :label="model.Newsletter ? $t('Common.Button.Yes') : $t('Common.Button.No')"
            :rules="model.validator.vuetifyFormFieldRules('Newsletter')"
            class="pt-0"
            hide-details
            inset
            name="Newsletter"
          />
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Sms.Label') }}
        </v-col>

        <v-col class="auto">
          <v-switch
            v-model="model.SMS"
            :label="model.SMS ? $t('Common.Button.Yes') : $t('Common.Button.No')"
            :rules="model.validator.vuetifyFormFieldRules('SMS')"
            class="pt-0"
            hide-details
            inset
            name="sms"
          />
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.Field.Push.Label') }}
        </v-col>

        <v-col class="auto">
          <v-switch
            v-model="model.Push"
            :label="model.Push ? $t('Common.Button.Yes') : $t('Common.Button.No')"
            :rules="model.validator.vuetifyFormFieldRules('Push')"
            class="pt-0"
            hide-details
            inset
            name="push-notifications"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          class="pt-6"
          cols="12"
        />
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <h3>{{ $t('Account.Settings.Account') }}</h3>
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.ExportAccount') }}
        </v-col>

        <v-col class="auto">
          <v-btn
            block
            class="text-capitalize white--text"
            color="warning"
            depressed
            @click="onExportClick"
          >
            {{ $t('Common.Button.Export') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        class="align-center"
        dense
      >
        <v-col>
          {{ $t('Account.Settings.DeleteAccount') }}
        </v-col>

        <v-col class="auto">
          <v-btn
            block
            class="text-capitalize white--text"
            color="red"
            depressed
            @click="onDeleteClick"
          >
            {{ $t('Common.Button.Delete') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn
            :disabled="isFormSaving"
            :loading="isFormSaving"
            block
            class="text-capitalize white--text"
            color="brand-primary"
            depressed
            x-large
            @click="onSaveClick"
          >
            {{ $t('Common.Button.Save') }}
          </v-btn>
        </v-col>
      </v-row>

      <confirm-pin-dialog
        :message="confirmDialog.content"
        :options="confirmDialog"
        :sub-title="confirmDialog.subTitle"
        :title="confirmDialog.title"
        :visible.sync="confirmDialog.visible"
        @dialog:yes="confirmDialog.callback"
      />
    </v-container>
  </v-form>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import AccountSettingsModel from '@/api/models/account/AccountSettingsModel'
import formData from '@/mixins/form/formData'
import CustomerModel from '@/api/models/customer/CustomerModel'
import API from '@/api/Api'
import ConfirmPinDialog from '@/theme/default/components/common/ConfirmPinDialog.vue'

export default {
  name      : 'AccountSettings',
  components: {
    ConfirmPinDialog
  },
  directives: {},
  mixins    : [i18nRouteMeta, formData],
  props     : {},
  enums     : {},
  dataStore : {},
  dataModel : AccountSettingsModel,
  data () {
    return {
      confirmDialog: {
        visible: false,
        input  : '',
        button : {
          no : { title: this.$t('Common.Button.Cancel') },
          yes: { title: this.$t('Common.Button.Confirm') }
        },
        title   : '',
        subTitle: '',
        content : '',
        callback: () => {
        }
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onDeleteClick () {
      this.confirmDialog.action = 'DELETE'

      this.$root.$confirm.open(
        'Αποστολή PIN',
        'Αποστολή PIN Επιβεβαίωσης',
        'Θα λάβετε PIN στο email σας για να επιβεβαιώσετε την οριστική διαγραφή του λογαριασμού σας.',
        {
          color        : 'info',
          icon         : 'mdi-alert',
          coloredBorder: false,
          dark         : true,
          button       : {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Ok') }
          }
        }
      ).then(response => {
        if (response) this.onConfirmGetPin()
      })
    },
    onConfirmDelete (pin) {
      const response = this.model.DeleteAccount({
        CustomerId: this.model.Id,
        PIN       : pin
      })

      this.handleApiResponse(response, true)
    },

    onExportClick () {
      this.confirmDialog.action = 'EXPORT'

      this.$root.$confirm.open(
        'Αποστολή PIN',
        'Αποστολή PIN Επιβεβαίωσης',
        'Θα λάβετε PIN στο email σας για να επιβεβαιώσετε την εξαγωγή δεδομένων του λογαριασμού σας.',
        {
          color        : 'info',
          icon         : 'mdi-alert',
          coloredBorder: false,
          dark         : true,
          button       : {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Ok') }
          }
        }
      ).then(response => {
        if (response) this.onConfirmGetPin()
      })
    },
    onConfirmExport (pin) {
      const response = this.model.ExportAccount({
        CustomerId: this.model.Id,
        PIN       : pin
      })

      this.handleApiResponse(response)
    },

    handleApiResponse (result, logout = false) {
      result
        .then(response => {
          const data = API.responseData(response)
          if (API.isResponseSuccess(response)) {
            if (data.Messages.Success) {
              this.confirmDialog.visible = false
              this.$bus.$emit('app:show-notification', {
                title: '',
                body : data.Messages.Success,
                type : 'success',
                icon : ''
              })
              this.$bus.$emit('app:logout')
            } else if (data.Messages.Error) {
              this.$bus.$emit('app:show-notification', {
                title: '',
                body : data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          } else {
            this.$bus.$emit('app:show-notification', {
              title: '',
              body : response.data.message,
              type : 'error',
              icon : ''
            })
          }
        })
        .catch(() => {
          this.$bus.$emit('app:show-notification', {
            title: '',
            body : this.$t('Common.Error.Generic'),
            type : 'error',
            icon : ''
          })
        })
        .finally(() => {
        })
    },

    onConfirmGetPin () {
      this.model.ConfirmPin({ CustomerId: this.model.Id })
        .then(response => {
          const data = API.responseData(response)
          if (API.isResponseSuccess(response)) {
            if (data.Messages.Success) {
              if (this.confirmDialog.action === 'EXPORT') {
                this.confirmDialog = {
                  visible      : true,
                  color        : 'info',
                  icon         : 'mdi-alert',
                  coloredBorder: false,
                  dark         : true,
                  input        : 'Εισαγωγή PIN',
                  button       : {
                    no : { title: this.$t('Common.Button.Cancel') },
                    yes: { title: this.$t('Common.Button.Confirm') }
                  },
                  title   : 'Εισαγωγή PIN',
                  subTitle: 'Εισαγωγή PIN Επιβεβαίωσης',
                  content : 'Η αποστολή του PIN επιβεβαίωσης έγινε επιτυχώς! Παρακαλούμε ελέγξτε το email σας. <br><br> Εισάγετε το PIN που λάβετε στο email σας για να επιβεβαιώσετε την εξαγωγή δεδομένων του λογαριασμού σας.',
                  callback: this.onConfirmExport
                }
              } else if (this.confirmDialog.action === 'DELETE') {
                this.confirmDialog = {
                  visible      : true,
                  color        : 'info',
                  icon         : 'mdi-alert',
                  coloredBorder: false,
                  dark         : true,
                  input        : 'Εισαγωγή PIN',
                  button       : {
                    no : { title: this.$t('Common.Button.Cancel') },
                    yes: { title: this.$t('Common.Button.Confirm') }
                  },
                  title   : 'Εισαγωγή PIN',
                  subTitle: 'Εισαγωγή PIN Επιβεβαίωσης',
                  content : 'Η αποστολή του PIN επιβεβαίωσης έγινε επιτυχώς! Παρακαλούμε ελέγξτε το email σας. <br><br> Εισάγετε το PIN που λάβετε στο email σας για να επιβεβαιώσετε την οριστική διαγραφή του λογαριασμού σας.',
                  callback: this.onConfirmDelete
                }
              }
            } else if (data.Messages.Error) {
              this.$bus.$emit('app:show-notification', {
                title: '',
                body : data.Messages.Error,
                type : 'error',
                icon : ''
              })
            }
          }
        })
        .catch(e => {

        })
    },

    onSaveClick () {
      this.saveFromData()
    },

    saveFromData () {
      if (!this.validateForm() || this.isFormSaving) return
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      this.isFormSaving = true

      this.model.SaveSettings()
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.handleFormSaveResponse(error.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },

    formSaveSuccess (data) {
      this.showGenericError = false
      this.genericError = ''
      this.$emit('form:save:success', data)

      if (!data?.Messages?.Success || !data?.CustomerInfo) return

      this.user = new CustomerModel(data.CustomerInfo)

      this.$bus.$emit('app:show-notification', {
        body   : data.Messages.Success,
        type   : 'success',
        icon   : 'mdi-check-circle',
        timeout: 5000
      })
    }
  }
}
</script>

<style scoped>

</style>
