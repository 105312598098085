<template>
  <v-app>
    <v-overlay :value="fullScreenLoader">
      <v-progress-circular
        color="brand-primary"
        indeterminate
        size="64"
      />
    </v-overlay>

    <vue-snotify />

    <cart-drawer
      :items="[]"
      :logo-visible="false"
      :navigation-drawer.sync="navigationDrawerVisible"
    />

    <app-toolbar
      :navigation-drawer.sync="navigationDrawerVisible"
      account
      cart
      lang-switch
      logo
    />

    <v-main>
      <v-container
        class="fill-height"
        fluid
        ma-0
        pa-0
      >
        <v-row v-if="showStoreCategories">
          <v-col cols="12">
            <store-catalog-categories />
          </v-col>
        </v-row>

        <v-row class="fill-height">
          <v-col
            :class="{'mt-14': showStoreCategories}"
            cols="12"
            lg="10"
            offset-lg="1"
          >
            <slot />
          </v-col>
        </v-row>

        <login-dialog :visible.sync="loginDialogVisible" />

        <register-dialog
          :item="registerInitData"
          :visible.sync="registerDialogVisible"
        />

        <reset-password-dialog :visible.sync="resetPasswordDialogVisible" />

        <select-table-dialog
          :items="AppQrItems"
          :visible.sync="selectTableDialogVisible"
        />
      </v-container>
    </v-main>

    <v-bottom-navigation
      v-if="showBottomNavigation"
      app
      background-color="transparent"
      class="mb-2 px-2 elevation-0"
      dark
      fixed
      height="46"
      mandatory
    >
      <v-btn
        class="rounded brand-primary text-capitalize font-weight-bold pl-2 pr-3 elevation-6"
        style="width: 320px; max-width: 320px; font-size: 14px;"
        @click.stop="navigationDrawerVisible = !navigationDrawerVisible"
      >
        <div
          class="d-flex"
          style="width: 100%;"
        >
          <div class="my-auto">
            <v-badge
              class="mt-2 mr-4"
              color="white"
              overlap
            >
              <template #badge>
                <span class="brand-primary--text">{{ cartProductsCount }}</span>
              </template>

              <v-icon>mdi-cart</v-icon>
            </v-badge>

            <span>{{ $t('Cart.Button.ViewCart') }}</span>
          </div>

          <v-spacer />

          <div class="ml-4 my-auto">
            {{ orderTotal | currency }}
          </div>
        </div>
      </v-btn>
    </v-bottom-navigation>

    <app-footer class="mt-12" />

    <confirm-dialog ref="confirm" />

    <cookie-law
      :button-text="$t('Common.CookieLaw.AcceptButton')"
      theme="gfp-theme"
    >
      <div
        slot="message"
        class="copyrightLinks"
      >
        {{ $t('Common.CookieLaw.Message') }}
        <router-link :to="{name: 'PoliciesCookies'}">
          {{ $t('Page.PoliciesCookies.Title') }}
        </router-link>
      </div>
    </cookie-law>
  </v-app>
</template>

<script>
import AppToolbar from '@/theme/default/components/app/AppToolbar'
import AppFooter from '@/theme/default/components/app/AppFooter'
import ConfirmDialog from '@/theme/default/components/common/ConfirmDialog'
import StoreCatalogCategories from '@/theme/default/views/store/StoreCatalogCategories.vue'
import LoginDialog from '@/theme/default/views/login/LoginDialog.vue'
import RegisterDialog from '@/theme/default/views/login/RegisterDialog.vue'
import CartDrawer from '@/theme/default/views/cart/CartDrawer.vue'
import { floatNumber } from '@/lib/helpers'
import CartCommon from '@/mixins/cart/cartCommon'
import ResetPasswordDialog from '@/theme/default/views/login/ResetPasswordDialog.vue'
import CookieLaw from 'vue-cookie-law'
import SelectTableDialog from '@/theme/default/components/common/SelectTableDialog.vue'

export default {
  name      : 'DefaultLayout',
  components: {
    SelectTableDialog,
    ResetPasswordDialog,
    CartDrawer,
    RegisterDialog,
    LoginDialog,
    StoreCatalogCategories,
    ConfirmDialog,
    AppToolbar,
    AppFooter,
    CookieLaw
  },
  mixins   : [CartCommon],
  dataStore: {
    AppCart   : 'App.Cart',
    AppQrItems: 'App.QrItems',
    AppType   : 'App.Type'
  },
  data: function () {
    return {
      loginDialogVisible        : false,
      registerDialogVisible     : false,
      resetPasswordDialogVisible: false,
      selectTableDialogVisible  : false,
      navigationDrawerVisible   : false,
      fullScreenLoader          : false,
      registerInitData          : null
    }
  },
  computed: {
    showBottomNavigation () {
      const excludedRoutes = ['CheckoutDetails', 'CheckoutSuccess']
      return this.cartProductsCount && this.$vuetify.breakpoint.xsOnly && !excludedRoutes.includes(this.$route.name)
    },

    showStoreCategories () {
      return this.$route.name === 'Store'
    }
  },
  watch: {},
  created () {
    this.$bus.$on('login:show-dialog', this.showLoginDialog)
    this.$bus.$on('register:show-dialog', this.showRegisterDialog)
    this.$bus.$on('reset-password:show-dialog', this.showResetPasswordDialog)
    this.$bus.$on('cart:show-dialog', this.showCartDialog)
    this.$bus.$on('select-table:show-dialog', this.showSelectTableDialog)
    this.$bus.$on('app:fullscreen-loader', this.showFullScreenLoader)
    this.$bus.$on('app:show-notification', this.onShowAppSnackbar)

    const excludedRoutes = ['OrderStatus', 'CheckoutSuccess', 'VivaPaymentSuccess', 'CheckoutFailure', 'VivaPaymentFailure', 'MarketplaceAccountConnected', 'VivaMarketplaceAccountConnected']
    if (!this.AppType.isQRScanApp && !this.AppQrData && this.AppConfig.Marketplace.SelectQrDataRequired && this.AppConfig.Marketplace.SelectQrDataPopup && !excludedRoutes.includes(this.$route.name)) {
      this.$bus.$emit('select-table:show-dialog')
    }
  },
  mounted () {
    this.$root.$confirm = this.$refs.confirm
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('login:show-dialog', this.showLoginDialog)
    this.$bus.$off('register:show-dialog', this.showRegisterDialog)
    this.$bus.$off('reset-password:show-dialog', this.showResetPasswordDialog)
    this.$bus.$on('cart:show-dialog', this.showCartDialog)
    this.$bus.$off('select-table:show-dialog', this.showSelectTableDialog)
    this.$bus.$off('app:fullscreen-loader', this.showFullScreenLoader)
    this.$bus.$off('app:show-notification', this.onShowAppSnackbar)
  },
  methods: {
    floatNumber,
    showLoginDialog (visible = true) {
      this.loginDialogVisible = !!visible
      this.registerDialogVisible = false
    },

    showRegisterDialog (data, visible = true) {
      this.registerInitData = data || null
      this.registerDialogVisible = !!visible
      this.loginDialogVisible = false
    },

    showResetPasswordDialog (visible = true) {
      this.resetPasswordDialogVisible = !!visible
      this.registerDialogVisible = false
      this.loginDialogVisible = false
    },

    showCartDialog (visible = true) {
      this.navigationDrawerVisible = !!visible
    },

    showSelectTableDialog () {
      this.selectTableDialogVisible = true
    },

    showFullScreenLoader (visible = true) {
      this.fullScreenLoader = !!visible
    },

    onShowAppSnackbar (data) {
      this.$snotify[data.type](data.body, data.title, {
        timeout        : data.hasOwnProperty('timeout') ? data.timeout : 3000,
        showProgressBar: data.hasOwnProperty('showProgressBar') ? data.showProgressBar : true,
        closeOnClick   : data.hasOwnProperty('closeOnClick') ? data.closeOnClick : true,
        pauseOnHover   : data.hasOwnProperty('pauseOnHover') ? data.pauseOnHover : true,
        position       : data.position ? data.position : 'centerTop',
        buttons        : data.buttons ? data.buttons : [],
        icon           : data.icon ? data.icon : null,
        img            : data.img ? data.img : null
      })
    }
  }
}

</script>

<style>

</style>
