<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="450"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar>
            <v-icon x-large>
              mdi-table-chair
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Common.SelectQrItemDialog.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Common.SelectQrItemDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            class="grey lighten-3 ma-0"
            color="black"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-0">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-list
                  :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'"
                  class="pa-0"
                >
                  <template v-for="(item, idx) in items">
                    <v-list-item
                      :key="`list-item-${idx}`"
                      ripple
                      @click="onTableSelected(item)"
                    >
                      <v-list-item-avatar
                        :color="$vuetify.theme.dark ? 'grey darken-3' : 'white'"
                        class="rounded"
                        size="64"
                        tile
                      >
                        <v-icon>{{ $t(`Common.QrSourceType.${item.type}.Icon`) }}</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content class="grey--text text--darken-2">
                        <v-list-item-title class="text-left">
                          <h3>{{ $t(`Common.QrSourceType.${item.type}.Title`) }} {{ item.value }}</h3>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider
                      :key="`list-item-${idx}-divider`"
                    />
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="brand-primary--text text--darken-1"
            outlined
            text
            @click="closeDialog"
          >
            {{ $t('Common.Button.Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import DialogVisible from '@/mixins/dialog/dialogVisible'
import AppTypeEnum from '@/api/enums/AppTypeEnum'

export default {
  name      : 'SelectTableDialog',
  components: {},
  mixins    : [DialogVisible],
  props     : {
    items: {
      type   : Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods : {
    onTableSelected (item) {
      const payload = {
        type  : item.type,
        value : item.value,
        source: this.$t(`Common.QrSourceType.${item.type}.Id`)
      }

      this.$sessionStorage.set('t', AppTypeEnum.QR_SCAN_APP)
      this.$sessionStorage.set('d', `GFP${item.type}${item.value}GFP`)
      this.$sessionStorage.set('QRData', payload)
      this.$dataStore.App.Type.QrData = payload
      this.$dataStore.App.Type.isQRScanApp = true

      this.$emit('qr-data:selected', item)

      this.closeDialog()
    }
  }
}
</script>
