import i18n from '@/lang/lang'

export default async (to, from, next, injection) => {
  const vue = injection

  if (to.meta.requiresAuth) {
    if (!vue.user) {
      next({
        replace: true,
        name   : 'Home',
        params : { lang: i18n.locale },
        query  : to?.query || {}
      })
    } else {
      next()
    }
  } else {
    next()
  }
}
