<template>
  <v-lazy
    min-height="139"
    transition="fade-transition"
  >
    <v-hover v-slot="data">
      <v-card
        v-if="product"
        :class="data.hover ? 'hover-zoom hover-zoom-active' : 'hover-zoom'"
        :disabled="productDisabled"
        :style="{filter: (productDisabled ? 'grayscale(1)' : 'grayscale(0)')}"
        @click.stop="onProductClick"
      >
        <v-list
          dense
          three-line
        >
          <v-list-item class="px-2">
            <v-list-item-content class="py-1">
              <v-list-item-title>{{ productName }}</v-list-item-title>
              <v-list-item-subtitle>{{ productDescription }}</v-list-item-subtitle>

              <div class="mt-auto d-inline-flex">
                <product-price
                  :disabled="productDisabled"
                  :product="product.DetailsData"
                />

                <v-spacer />

                <product-tags
                  :style="product.Image ? '' : 'position:absolute; bottom: 6px; right: 14px; z-index: 3;'"
                  :tags="product.Tags"
                  icon-only
                  small
                />
              </div>
            </v-list-item-content>

            <div
              class="pa-1 overflow-visible"
              style="max-width: 175px;"
            >
              <v-img
                :src="product.Image"
                :style="{filter: (productDisabled ? 'grayscale(1)' : 'grayscale(0)')}"
                class="rounded rounded-tr-0"
                height="115"
              >
                <div
                  v-if="storeIsOpenedById(product.StoreId) || (!AppConfig.StoreClosed.DisableAddToCart && storeIsOpenedById(product.StoreId))"
                  class="float-right"
                >
                  <v-btn
                    :loading="product.Loading"
                    class="pa-0 ma-0 rounded-bl-xxl rounded-tl-0 rounded-br-0 rounded-tr-0"
                    color="brand-primary lighten-5 brand-primary--text"
                    depressed
                    height="40"
                    style="min-width: 45px;"
                    @click.stop="onProductQuickAddClick"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-img>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-hover>
  </v-lazy>
</template>

<script>

import { clone } from '@/lib/utils/helper'
import Translatable from '@/mixins/translatable'
import ProductListCommon from '@/mixins/product/productListCommon'
import ProductPrice from '@/theme/default/components/product/ProductPrice'
import ProductTags from '@/theme/default/components/product/ProductTags.vue'

export default {
  name      : 'CatalogProductListItem',
  components: {
    ProductTags,
    ProductPrice
  },
  directives: {},
  mixins    : [Translatable, ProductListCommon],
  props     : {
    product: {
      type   : Object,
      default: undefined
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {
    AppCart: 'App.Cart'
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onProductClick () {
      if (!this.product || this.productDisabled || this.product?.Loading || (this.AppConfig.StoreClosed.DisableProductDialog && this.storeIsClosedById(this.product?.StoreId))) return

      this.$bus.$emit('product:details-dialog', clone(this.product))
    },

    onProductQuickAddClick () {
      if (!this.product || this.productDisabled || this.product?.Loading || (this.AppConfig.StoreClosed.DisableAddToCart && this.storeIsClosedById(this.product?.StoreId))) return

      this.$bus.$emit('cart:quick-add-product', this.product)
    }
  }
}
</script>

<style scoped>
.hover-zoom {
  overflow: hidden;
}

/deep/ .hover-zoom .v-image__image {
  transition: all 0.3s;
}

/deep/ .hover-zoom-active .v-image__image {
  transform: scale(1.1);
}
</style>
