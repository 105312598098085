<template>
  <div class="separator">
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name : 'VSeparator',
  props: {
    color: {
      type   : String,
      default: '#cccccc'
    },

    height: {
      type   : String,
      default: '1px'
    },

    marginLeft: {
      type   : String,
      default: '16px'
    },

    marginRight: {
      type   : String,
      default: '16px'
    }
  }
}
</script>

<style scoped>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: v-bind(height) solid v-bind(color);
}

.separator:not(:empty)::before {
  margin-right: v-bind(marginRight);
}

.separator:not(:empty)::after {
  margin-left: v-bind(marginLeft);
}
</style>
