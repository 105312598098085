const SourceTypeEnum = {
  ALL           : 0,
  ONLINE        : 1,
  VOIP          : 2,
  TELEPHONY     : 3,
  TABLE         : 4,
  POS           : 5,
  ONLINE_TABLE  : 6,
  ONLINE_BOOTH  : 7,
  ONLINE_ROOM   : 8,
  ONLINE_SUNBED : 9,
  MOBILE        : 10,
  MARKETPLACE   : 11,
  EFOOD         : 100,
  BOX           : 103,
  WOLT          : 104,
  FOODY         : 105,
  GETFOOD       : 120,
  ONLINE_SOURCES: [1, 6, 7, 8, 9, 10, 11]
}

export default SourceTypeEnum
