import BaseModel from '@/lib/data/model/BaseModel'
import CustomerInfoValidator from '@/api/validations/customer/CustomerInfoValidator'
import CustomerInfoResource from '@/api/resources/customer/CustomerInfoResource'

export default class CustomerInfoModel extends BaseModel {
  constructor (payload = {}, resource = CustomerInfoResource, validator = CustomerInfoValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
