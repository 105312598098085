import BaseResource from '@/lib/data/resource/BaseResource'

export default class AccountSettingsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Firstname = payload.Firstname ?? ''
    this.Lastname = payload.Lastname ?? ''
    this.NewPass = payload.NewPass ?? ''
    this.NewPassConfirm = payload.NewPassConfirm ?? ''
    this.Email = payload.Email ?? ''
    this.Phone = payload.Phone ?? ''
    this.Phone2 = payload.Phone2 ?? ''
    this.Phone3 = payload.Phone3 ?? ''
    this.Newsletter = payload.Newsletter ?? false
    this.SMS = payload.SMS ?? false
    this.Push = payload.Push ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
