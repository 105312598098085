<template>
  <v-hover v-slot="data">
    <v-card>
      <v-img
        v-if="item.Image"
        :class="hover && data.hover ? 'hover-zoom hover-zoom-active' : 'hover-zoom'"
        :height="imageHeight"
        :src="item.Image"
        class="white--text align-end"
        gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)"
      >
        <div
          v-if="item.OriginalPrice && item.OfferPrice && $vuetify.breakpoint.smAndUp"
          class="brand-primary text-center d-flex align-center justify-center"
          style="width: 60px; height: 65px; position: absolute; right: 15px; top: 0px;"
        >
          <div>
            <div class="font-weight-black">
              {{ item.OriginalPrice }}€
            </div>
            <div class="caption font-weight-medium brand-primary--text text--lighten-4 text-decoration-line-through">
              {{ item.OfferPrice }}€
            </div>
          </div>
        </div>

        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card-title
                v-if="item.Name"
                :class="{'pb-0': !item.Desc}"
                class="px-0 pt-0"
              >
                <div
                  class="overflow-hidden text-no-wrap"
                  style="text-overflow: ellipsis;"
                >
                  {{ item.Name }}
                </div>
              </v-card-title>

              <v-card-subtitle
                v-if="item.Desc"
                class="px-0 pb-0"
              >
                <div
                  class="overflow-hidden text-no-wrap"
                  style="text-overflow: ellipsis;"
                >
                  {{ item.Desc }}
                </div>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  name      : 'OfferCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    item: {
      type   : Object,
      default: () => {
        return {
          Name         : '',
          Desc         : '',
          OriginalPrice: '',
          OfferPrice   : '',
          Image        : ''
        }
      }
    },

    hover: {
      type   : Boolean,
      default: false
    }

  },
  enums: {},
  data () {
    return {}
  },
  computed: {
    imageHeight () {
      if (this.$vuetify.breakpoint.xs) return 110
      if (this.$vuetify.breakpoint.sm) return 165
      if (this.$vuetify.breakpoint.md) return 180
      if (this.$vuetify.breakpoint.lg) return 240

      return 260
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
.hover-zoom {
  overflow: hidden;
}

/deep/ .hover-zoom .v-image__image {
  transition: all 0.3s;
}

/deep/ .hover-zoom-active .v-image__image {
  transform: scale(1.1);
}
</style>
