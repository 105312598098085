<template>
  <v-app>
    <app-toolbar
      lang-switch
      logo
    />

    <v-main>
      <v-container
        class="fill-height"
        fluid
        ma-0
        pa-0
      >
        <v-row class="fill-height">
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
          >
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <app-footer class="mt-12" />
  </v-app>
</template>

<script>
import AppToolbar from '@/theme/default/components/app/AppToolbar'
import AppFooter from '@/theme/default/components/app/AppFooter'

export default {
  name      : 'SimpleLayout',
  components: {
    AppToolbar,
    AppFooter
  },
  mixins   : [],
  dataStore: {},
  data     : function () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {}
}

</script>

<style>

</style>
