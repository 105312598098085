import BaseModel from '@/lib/data/model/BaseModel'
import CustomerAddressValidator from '@/api/validations/customer/CustomerAddressValidator'
import CustomerAddressResource from '@/api/resources/customer/CustomerAddressResource'

export default class CustomerAddressModel extends BaseModel {
  constructor (payload = {}, resource = CustomerAddressResource, validator = CustomerAddressValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
