<template>
  <v-alert
    :dense="dense"
    border="left"
    class="mb-0"
    colored-border
    text
    type="info"
  >
    {{
      $t(`Cart.Message.Info.MinimumOrder.${sortText ? 'BodyAlt' : 'Body'}`, {minimumOrderTotalDiff: $options.filters.currency(orderMinTotalAmountDiff)})
    }}
  </v-alert>
</template>

<script>

import CartCommon from '@/mixins/cart/cartCommon'

export default {
  name      : 'PaymentMinimumOrderWarning',
  components: {},
  directives: {},
  mixins    : [CartCommon],
  props     : {
    dense: {
      type   : Boolean,
      default: false
    },

    sortText: {
      type   : Boolean,
      default: false
    }
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
