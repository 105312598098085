import { API } from '@/api/Api'

export default {
  async Get (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Config.Get
    const Data = { ...data }
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  }
}
