<template>
  <v-list-item
    v-if="product"
    class="px-3"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      :style="`width: ${imageWidth}px; max-width: ${imageWidth}px; height: ${imageHeight+16}px;`"
      class="pa-1 mr-2 mt-2 mb-auto"
    >
      <v-img
        v-if="product.Image"
        :height="imageHeight"
        :src="product.Image"
        class="rounded"
      />
      <div
        v-else
        :style="`width:100%; height: ${imageHeight}px;`"
        class="brand-primary lighten-5 rounded text-center justify-center align-center d-inline-flex"
      >
        <img
          v-if="!storeLogo && productStoreLogo"
          :src="productStoreLogo"
          style="width: 45px; height: auto; opacity: 0.20;"
        >
        <img
          v-else
          src="/img/logo/logo-dark.png"
          style="width: 45px; height: auto; opacity: 0.20;"
        >
      </div>

      <v-avatar
        v-if="storeLogo && product.Store && product.Store.Logo"
        :size="storeLogoStyle.Size"
        :style="`position: absolute; top: ${storeLogoStyle.Top}px; left: ${storeLogoStyle.Left}px;`"
      >
        <img :src="product.Store.Logo">
      </v-avatar>
    </div>

    <v-list-item-content>
      <v-list-item-title class="font-weight-medium">
        <product-quantity :product="product" />

        {{ productName }}
      </v-list-item-title>

      <v-list-item-subtitle>{{ cartProductSelectedOptions }}</v-list-item-subtitle>

      <div
        class="mt-auto d-inline-flex"
        style="font-size: 14px;"
      >
        <product-price
          :product="product"
          auto-calculate
          cart
        />

        <!--
        <div
          v-if="product.alcohol_percentage"
          class="d-inline-flex font-weight-light grey--text ml-2"
        >
          ({{ parseFloat(product.alcohol_percentage / 10).toFixed(2) }}% Vol)
        </div>

        <div
          v-if="product.restrictions && product.restrictions.length"
          class="d-inline-flex"
        >
          <template v-for="(restriction, restrictionIdx) in product.restrictions">
            <v-chip
              :key="`restriction-${restrictionIdx}`"
              class="ml-2 orange--text text--lighten-5"
              color="orange"
              label
              x-small
            >
              {{ restriction.age_limit }}+
            </v-chip>
          </template>
        </div>
        -->

        <v-spacer />

        <product-tags
          :tags="product.Tags"
          icon-only
          small
          style="position:absolute; bottom: 13px; right: 16px;"
        />
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

import ProductQuantity from '@/theme/default/components/product/ProductQuantity.vue'
import Translatable from '@/mixins/translatable'
import ProductListCommon from '@/mixins/product/productListCommon'
import ProductPrice from '@/theme/default/components/product/ProductPrice.vue'
import ProductTags from '@/theme/default/components/product/ProductTags.vue'

export default {
  name      : 'CheckoutProductListItem',
  components: {
    ProductTags,
    ProductPrice,
    ProductQuantity
  },
  directives: {},
  mixins    : [Translatable, ProductListCommon],
  props     : {
    product: {
      type   : Object,
      default: undefined
    },

    storeLogo: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    imageWidth () {
      return this.$vuetify.breakpoint.smAndUp ? 150 : 110
    },

    imageHeight () {
      return 75
    },

    storeLogoStyle () {
      return this.$vuetify.breakpoint.smAndUp ? {
        Size: 40,
        Top : -8,
        Left: -3
      } : {
        Size: 28,
        Top : 3,
        Left: 3
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
