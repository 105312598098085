<template>
  <v-container
    v-if="user"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <h3>{{ $t('Account.Addresses.Title') }}</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="text-justify"
        cols="12"
      />
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'

export default {
  name      : 'AccountAddresses',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  enums     : {},
  dataStore : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
