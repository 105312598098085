import BaseResource from '@/lib/data/resource/BaseResource'

export default class CustomerInfoResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Firstname = payload.Firstname ?? ''
    this.Lastname = payload.Lastname ?? ''
    this.Phone = payload.Phone ?? ''
    this.Phone2 = payload.Phone2 ?? ''
    this.Phone3 = payload.Phone3 ?? ''
    this.Email = payload.Email ?? ''
    this.SocialProvider = payload.SocialProvider ?? ''
    this.Newsletter = payload.Newsletter ?? false
    this.Push = payload.Push ?? false
    this.SMS = payload.SMS ?? false
    this.Status = payload.Status ?? false
    this.DateAdded = payload.DateAdded ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
