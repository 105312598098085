<template>
  <v-row>
    <v-col cols="12">
      <swiper
        :options="swiperOption"
        style="width: 100%;"
      >
        <template v-for="(item, idx) in items">
          <swiper-slide
            :key="`slide-${idx}`"
            class="transparent"
          >
            <div style="width: 100%; height: auto;">
              <v-card
                :href="item.Link || '#'"
                :target="item.Target || '_self'"
                color="transparent"
                flat
              >
                <video
                  v-if="item.Type === 'video'"
                  :poster="item.Poster"
                  :src="item.Src"
                  autoplay="autoplay"
                  defaultMuted
                  loop="loop"
                  muted
                  oncontextmenu="return false;"
                  playsinline
                  preload="auto"
                  style="width: 100%; height: auto; max-height: 420px; object-fit: cover; object-position: center center;"
                />

                <img
                  v-if="item.Type === 'image'"
                  :src="item.Src"
                  style="width: 100%; height: auto; max-height: 420px; object-fit: cover; object-position: center center;"
                >
              </v-card>
            </div>
          </swiper-slide>
        </template>

        <div
          v-if="pagination"
          slot="pagination"
          class="swiper-pagination"
        />

        <template v-if="buttons">
          <div
            slot="button-prev"
            class="swiper-button-prev"
          >
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-arrow-left"
              data-v-0bc8037b=""
            />
          </div>

          <div
            slot="button-next"
            class="swiper-button-next"
          >
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-arrow-right"
              data-v-0bc8037b=""
            />
          </div>
        </template>
      </swiper>
    </v-col>
  </v-row>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name      : 'GeneralSlider',
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },

    items: {
      type   : Array,
      default: () => []
    },

    pagination: {
      type   : Boolean,
      default: false
    },

    buttons: {
      type   : Boolean,
      default: false
    }
  },
  enums: {},
  data () {
    return {
      swiperOption: {
        slidesPerView : 2,
        slidesPerGroup: 2,
        spaceBetween  : 20,
        loop          : true,
        autoplay      : {
          delay               : 5000,
          disableOnInteraction: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            spaceBetween : 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween : 20
          },
          640: {
            slidesPerView: 1,
            spaceBetween : 10
          },
          320: {
            slidesPerView: 1,
            spaceBetween : 10
          }
        },
        pagination: {
          el       : '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
