export default {

  App: {
    Title: 'Stoa',
    Desc : '',
    Img  : ''
  },

  Search: {
    Title: 'Search'
  },

  Units: [
    {
      Id      : 1,
      Name    : 'Item (Itm)',
      BaseUnit: 'Itm',
      RateUnit: 'Itm',
      Rate    : 1,
      Divisor : 1.000000
    },
    {
      Id      : 2,
      Name    : 'Kilo (kg)',
      BaseUnit: 'kg',
      RateUnit: 'g',
      Rate    : 1000,
      Divisor : 0.001000
    },
    {
      Id      : 3,
      Name    : 'Liter (L)',
      BaseUnit: 'L',
      RateUnit: 'ml',
      Rate    : 1000,
      Divisor : 0.001000
    }
  ],

  Message: {
    Loading: {
      Title   : 'Loading',
      Wait    : 'Please wait',
      Progress: '%{progress}%'
    },

    Sort: {
      Title : 'Sort',
      SortBy: 'Sort by'
    },

    Group: {
      Title  : 'Group',
      GroupBy: 'Group by'
    },

    Filter: {
      Title   : 'Filter',
      FilterBy: 'Filter by category'
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Delete {Id}',
      SubTitle: 'Delete Confirmation',
      Body    : 'Are you sure you want to proceed with permanent deletion?',
      Validate: {
        Text  : 'To delete, type <span class="font-weight-regular error--text">@:Common.Confirm.Delete.Validate.Answer</span> in the text box below.',
        Answer: 'sudo delete'
      }
    }
  },

  Date: {
    Title     : 'Date | Dates',
    Today     : 'Today',
    Day       : 'Day | Days',
    Week      : 'Week | Weeks',
    Month     : 'Month | Months',
    Year      : 'Year | Years',
    Period    : 'Period | Periods',
    PeriodFrom: 'From',
    PeriodTo  : 'To',
    Expire    : 'Expire'
  },

  CookieLaw: {
    Message     : 'Η ιστοσελίδα χρησιμοποιεί cookies για βελτιστοποίηση της εμπειρίας του χρήστη. Με τη χρήση αυτού του ιστοτόπου, αποδέχεστε τη χρήση των cookies. Για περισσότερες πληροφορίες δείτε την',
    AcceptButton: 'Αποδοχή'
  },

  SelectQrItemDialog: {
    Title   : 'Tables',
    Subtitle: 'Select Table'
  },

  QrSourceType: {
    BTH: {
      Id   : '7',
      Title: 'Booth',
      Type : 'BTH',
      Icon : 'mdi-storefront'
    },
    HRM: {
      Id   : '8',
      Title: 'Room',
      Type : 'HRM',
      Icon : 'mdi-alpha-h-box'
    },
    SNB: {
      Id   : '9',
      Title: 'Sunbed',
      Type : 'SNB',
      Icon : 'mdi-umbrella-beach'
    },
    TBL: {
      Id   : '6',
      Title: 'Table',
      Type : 'TBL',
      Icon : 'mdi-table-chair'
    }
  },

  Button: {
    All     : 'All',
    Save    : 'Save',
    Update  : 'Update',
    Confirm : 'Confirm',
    Copy    : 'Copy',
    View    : 'View',
    ViewAll : 'View All',
    New     : 'New',
    Create  : 'Create',
    Continue: 'Continue',
    Edit    : 'Edit',
    Comments: 'Comments',
    Export  : 'Export',
    Import  : 'Import',
    Delete  : 'Delete',
    Remove  : 'Remove',
    Reset   : 'Reset',
    Login   : 'Login',
    Logout  : 'Logout',
    Cancel  : 'Cancel',
    Change  : 'Change',
    Close   : 'Close',
    Send    : 'Send',
    Yes     : 'Yes',
    No      : 'No',
    Ok      : 'Ok',
    Next    : 'Next',
    Prev    : 'Previous',
    Toggle  : {
      Active  : 'Active',
      Inactive: 'Inactive',

      ActiveF   : 'Active',
      InactiveF : 'Inactive',
      ActiveFP  : 'Active',
      InactiveFP: 'Inactive',

      ActiveM   : 'Active',
      InactiveM : 'Inactive',
      ActiveMP  : 'Active',
      InactiveMP: 'Inactive'
    }
  },

  Error: {
    Generic    : 'There was a problem. Please try again.',
    Staging    : 'Caution! This is DEMO environment.',
    Development: 'Caution! This is a DEV environment.'
  },

  Misc: {
    From     : 'From',
    To       : 'To',
    Locale   : 'Language',
    CreatedAt: '@:Common.Button.Create',
    UpdatedAt: '@:Common.Button.Update',
    DeletedAt: '@:Common.Button.Delete'
  },

  Marketplace: {
    Account: {
      Connected: {
        Title   : 'Successfully Connected',
        Subtitle: 'Your account has been successfully connected to the marketplace'
      }
    }
  },

  Footer: {
    Company: {
      Title: 'Company',
      Items: [
        {
          Title: 'Who are we',
          To   : { name: 'PageAbout' }
        },
        {
          Title: 'How it Works',
          To   : { name: 'PageHowItWorks' }
        },
        {
          Title: 'FAQs',
          To   : { name: 'PageFaq' }
        },
        {
          Title: 'Contact',
          To   : { name: 'PageContact' }
        }
      ]
    },
    Stores: {
      Title: 'Stores'
    },
    Terms: {
      Title: 'Terms'
    },
    FollowUs: {
      Title: 'Follow Us',
      Items: [
        {
          Title: 'Blog',
          Link : ''
        },
        {
          Title: 'Instagram',
          Link : ''
        },
        {
          Title: 'Facebook',
          Link : ''
        },
        {
          Title: 'LinkedIn',
          Link : ''
        }
      ]
    }
  }
}
