<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar>
            <v-icon
              color="brand-primary"
              x-large
            >
              mdi-login
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Auth.LoginDialog.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Auth.LoginDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            class="grey lighten-3 ma-0"
            color="black"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-4">
          <v-form
            v-if="model"
            ref="form"
            @submit.prevent
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  {{ $t('Auth.LoginDialog.Social.Title') }}
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    :class="{'white': $vuetify.theme.dark}"
                    :color="$vuetify.theme.dark ? 'white' : 'grey lighten-1'"
                    block
                    class="text-none px-2"
                    outlined
                    x-large
                    @click="authSocialProvider('google')"
                  >
                    <div class="grow">
                      <div class="float-left">
                        <svg
                          fill="#000000"
                          height="22px"
                          preserveAspectRatio="xMidYMid"
                          viewBox="-3 0 262 262"
                          width="22px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g stroke-width="0" />
                          <g
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <g>
                            <path
                              d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                              fill="#4285F4"
                            />
                            <path
                              d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                              fill="#34A853"
                            />
                            <path
                              d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                              fill="#FBBC05"
                            />
                            <path
                              d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                              fill="#EB4335"
                            />
                          </g>
                        </svg>
                      </div>

                      <div class="black--text pt-1">
                        {{ $t('Auth.LoginDialog.Social.Google') }}
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <!--                <v-col cols="12">
                                  <v-btn
                                    block
                                    class="text-none px-2"
                                    color="black"
                                    dark
                                    depressed
                                    x-large
                                  >
                                    <div class="grow">
                                      <div class="float-left">
                                        <v-icon>
                                          mdi-apple
                                        </v-icon>
                                      </div>

                                      <div class="white--text pt-1">Continue with Apple</span>
                                    </div>
                                  </v-btn>
                                </v-col>-->

                <v-col cols="12">
                  <v-btn
                    block
                    class="text-none px-2"
                    color="blue darken-2"
                    dark
                    depressed
                    x-large
                    @click="authSocialProvider('facebook')"
                  >
                    <div class="grow">
                      <div class="float-left">
                        <v-icon>
                          mdi-facebook
                        </v-icon>
                      </div>

                      <div class="white--text pt-1">
                        {{ $t('Auth.LoginDialog.Social.Facebook') }}
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-separator class="my-4">
                    {{ $t('Auth.LoginDialog.Account.Title') }}
                  </v-separator>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="model.Username"
                    :hint="$t('Auth.LoginDialog.Account.Field.Email.Hint')"
                    :label="$t('Auth.LoginDialog.Account.Field.Email.Label')"
                    :placeholder="$t('Auth.LoginDialog.Account.Field.Email.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('Username')"
                    clearable
                    name="Username"
                    outlined
                    prepend-inner-icon="mdi-email"
                    type="text"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="model.Password"
                    :hint="$t('Auth.LoginDialog.Account.Field.Password.Hint')"
                    :label="$t('Auth.LoginDialog.Account.Field.Password.Label')"
                    :placeholder="$t('Auth.LoginDialog.Account.Field.Password.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('Password')"
                    clearable
                    name="Password"
                    outlined
                    prepend-inner-icon="mdi-lock"
                    type="password"
                  />
                </v-col>

                <v-col
                  v-if="showGenericError && genericError"
                  class="py-0"
                  cols="12"
                >
                  <v-alert
                    border="left"
                    dense
                    text
                    type="error"
                  >
                    {{ genericError }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    :disabled="isFormSaving"
                    :loading="isFormSaving"
                    block
                    class="text-capitalize white--text"
                    color="brand-primary"
                    depressed
                    x-large
                    @click="onLoginClick"
                  >
                    {{ $t('Auth.Button.Login') }}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    block
                    class="text-none mt-3"
                    plain
                    small
                    @click="onForgotPasswordClick"
                  >
                    {{ $t('Auth.Button.ForgotPassword') }}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    block
                    class="text-none"
                    plain
                    small
                    @click="onRegisterClick"
                  >
                    {{ $t('Auth.Button.NoAccount') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import DialogVisible from '@/mixins/dialog/dialogVisible'
import VSeparator from '@/theme/default/components/widgets/VSeparator.vue'
import DialogData from '@/mixins/dialog/dialogData'
import LoginModel from '@/api/models/user/LoginModel'
import Auth from '@/mixins/auth'

export default {
  name      : 'LoginDialog',
  components: { VSeparator },
  mixins    : [DialogVisible, DialogData, Auth],
  props     : {},
  dataModel : LoginModel,
  data () {
    return {}
  },
  computed: {},
  methods : {
    onLoginClick () {
      this.saveFromData()
    },

    saveFromData () {
      if (!this.validateForm() || this.isFormSaving) return
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()

      this.isFormSaving = true

      this.login(this.model.Username, this.model.Password)
    },

    onRegisterClick () {
      this.closeDialog()
      this.$bus.$emit('register:show-dialog')
    },

    onForgotPasswordClick () {
      this.closeDialog()
      this.$bus.$emit('reset-password:show-dialog')
    }
  }
}
</script>

<style scoped>

</style>
