<template>
  <v-container
    v-if="user"
    fluid
    pa-0
  >
    <template v-if="!init && loading">
      <v-row dense>
        <v-col cols="12">
          <template v-for="(item) in 5">
            <v-skeleton-loader
              :key="`loader-${item}`"
              type="list-item-avatar-three-line"
            />
          </template>
        </v-col>
      </v-row>
    </template>

    <template v-else-if="init && !totalItems">
      <v-row dense>
        <v-col
          class="text-center"
          cols="12"
        >
          <v-icon
            color="brand-primary"
            size="128"
          >
            mdi-cart
          </v-icon>

          <h2>{{ $t('Account.OrderHistory.NoOrders.Title') }}</h2>
          <h4 class="font-weight-regular">
            {{ $t('Account.OrderHistory.NoOrders.Subtitle') }}
          </h4>
        </v-col>
      </v-row>
    </template>

    <template v-else-if="init && totalItems">
      <v-row dense>
        <v-col cols="12">
          <v-list
            color="transparent"
            three-line
          >
            <template v-for="(item, index) in items">
              <v-list-item
                :key="`order-${item.OrderId}-${index}`"
                class="px-0"
              >
                <v-list-item-action class="mr-2 mr-sm-4 mr-md-6 align-self-center">
                  <v-chip
                    class="white--text px-sm-2 px-1 mb-2"
                    color="brand-primary"
                    label
                    style="min-width: 120px;"
                  >
                    {{ item.OrderCode }}
                  </v-chip>

                  <v-chip
                    class="white--text px-sm-2 px-1"
                    color="brand-primary"
                    label
                    style="min-width: 120px; display: inline-grid;"
                  >
                    <v-icon
                      class="d-flex mr-2"
                      size="16"
                    >
                      {{ item.Payment === PaymentTypeEnum.CASH ? 'mdi-currency-eur' : 'mdi-credit-card' }}
                    </v-icon>

                    <div class="text-right fill-width">
                      {{ item.OrderTotal | currency }}
                    </div>
                  </v-chip>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    <v-tooltip
                      :color="getOrderStatusById(item.Stores[0].OrderStatusId).Color"
                      :style="`color: ${getContrastColor(getOrderStatusById(item.Stores[0].OrderStatusId).Color, 168)}`"
                      open-delay="250"
                      top
                    >
                      <template #activator="{on}">
                        <v-badge
                          v-if="item.Stores.length === 1 && getStoreById(item.Stores[0].StoreId)"
                          :color="getOrderStatusById(item.Stores[0].OrderStatusId).Color"
                          class="mr-3"
                          overlap
                          v-on="on"
                        >
                          <template #badge>
                            <v-icon>{{ getOrderStatusById(item.Stores[0].OrderStatusId).Icon }}</v-icon>
                          </template>

                          <v-avatar
                            size="36"
                            v-on="on"
                          >
                            <img :src="storeLogoById(item.Stores[0].StoreId)">
                          </v-avatar>
                        </v-badge>
                      </template>

                      <span>{{ getOrderStatusById(item.Stores[0].OrderStatusId).Name }}</span>
                    </v-tooltip>

                    {{ item.Stores.length === 1 ? storeNameById(item.Stores[0].StoreId) : '' }}

                    <template v-if="item.Stores.length > 1">
                      <template v-for="(store, idx) in item.Stores">
                        <v-tooltip
                          :key="`order-${item.OrderId}-${index}-store-${store.StoreId}-${idx}-tooltip`"
                          :color="getOrderStatusById(store.OrderStatusId).Color"
                          :style="`color: ${getContrastColor(getOrderStatusById(store.OrderStatusId).Color, 168)}`"
                          open-delay="250"
                          top
                        >
                          <template #activator="{on}">
                            <v-badge
                              :key="`order-${item.OrderId}-${index}-store-${store.StoreId}-${idx}-badge`"
                              :color="getOrderStatusById(store.OrderStatusId).Color"
                              class="mr-4"
                              overlap
                              v-on="on"
                            >
                              <template #badge>
                                <v-icon>{{ getOrderStatusById(store.OrderStatusId).Icon }}</v-icon>
                              </template>

                              <v-avatar
                                v-if="getStoreById(store.StoreId)"
                                :key="`order-${item.OrderId}-${index}-store-${store.StoreId}-${idx}`"
                                size="36"
                                v-on="on"
                              >
                                <img :src="storeLogoById(store.StoreId)">
                              </v-avatar>
                            </v-badge>
                          </template>

                          <span>{{ getOrderStatusById(store.OrderStatusId).Name }}</span>
                        </v-tooltip>
                      </template>
                    </template>
                  </v-list-item-title>

                  <v-list-item-subtitle class="grey--text text--darken-1">
                    {{ getStoreOrderItemsName(item) }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="grey--text caption">
                    {{ item.DateAdded }} {{ item.TimeAdded }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider :key="`order-divider-${item.OrderId}-${index}`" />
            </template>
          </v-list>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col class="text-center">
          <v-pagination
            v-model="currentPage"
            :disabled="loading"
            :length="totalItems"
            :total-visible="7"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import API from '@/api/Api'
import StoreCommon from '@/mixins/store/storeCommon'
import Translatable from '@/mixins/translatable'
import PaymentTypeEnum from '../../../../api/enums/PaymentTypeEnum'
import { getContrastColor } from '@/lib/utils/color'

export default {
  name      : 'AccountOrderHistory',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta, StoreCommon, Translatable],
  props     : {},
  enums     : {},
  dataStore : {},
  data () {
    return {
      init        : false,
      loading     : false,
      currentPage : 1,
      itemsPerPage: 5,
      totalItems  : 0,
      items       : []
    }
  },
  computed: {
    PaymentTypeEnum () {
      return PaymentTypeEnum
    }
  },
  watch: {
    currentPage () {
      this.getData()
    }
  },
  beforeCreate () {
  },
  created () {
    this.getData()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    getContrastColor,
    getStoreOrderItemsName (item) {
      const products = []
      const stores = item.Stores || []
      stores.forEach(store => {
        store.Items.forEach(item => {
          item.Items.forEach(product => {
            products.push(this.getTranslatedField(product))
          })
        })
      })
      return products.join(', ')
    },

    getOrderStatusById (statusId) {
      const statuses = this.$te('Cart.OrderStatus') ? this.$t('Cart.OrderStatus') : []

      return statuses.find(status => status.Id === statusId)
    },

    getData () {
      this.loading = true

      API.Resource.Account.Orders({
        limit: this.itemsPerPage,
        page : this.currentPage
      })
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const data = API.responseData(response)
            this.totalItems = Math.ceil((data?.count || 0) / this.itemsPerPage)
            this.items = data?.items || []
          }
        })
        .catch(e => {
        })
        .finally(() => {
          this.loading = false
          this.init = true
        })
    }
  }
}
</script>

<style scoped>

</style>
