import { AES, HmacMD5 } from '@/lib/crypto/crypto'
import AuthRestApi from '@/mixins/auth/authRestApi'
import PermissionEnum from '@/api/enums/PermissionEnum'
import ApiResponseEnum from '@/api/enums/ApiResponseEnum'
import CustomerModel from '@/api/models/customer/CustomerModel'
import AuthSocialApi from '@/mixins/auth/authSocialApi'

export default {
  name: 'Auth',
  data () {
    return {
      authError        : false,
      isAuthLoading    : false,
      isMaintenanceMode: false
    }
  },
  mixins: [AuthRestApi, AuthSocialApi],
  enums : {
    PermissionEnum,
    ApiResponseEnum
  },
  watch: {
    userIsAuthenticated () {
      this.checkIfUserIsAuthenticated()
    }
  },
  created () {
    this.checkIfUserIsAuthenticated()
  },
  methods: {
    checkIfUserIsAuthenticated () {
      if (!this.userIsAuthenticated && this.$route?.meta?.requiresAuth && this.$route?.name !== 'Home') this.$router.replace({ name: 'Home' })
    }
  },
  computed: {
    $storage () {
      return this.$localStorage // this.$sessionStorage
    },

    userKey () {
      return HmacMD5.hash('user')
    },

    authorizationTokenKey () {
      return HmacMD5.hash('authorizationToken')
    },

    authorizationToken: {
      get () {
        return AES.decrypt(this.$storage.get(this.authorizationTokenKey))
      },
      set (val) {
        this.$storage.set(this.authorizationTokenKey, AES.encrypt(val))
      }
    },

    user: {
      get () {
        const user = this.$storage.get(this.userKey)
        if (this.$dataStore.User) {
          return new CustomerModel(AES.decrypt(this.$dataStore.User))
        } else if (user) {
          this.$dataStore.User = user
          return new CustomerModel(AES.decrypt(user))
        }
        return null
      },
      set (val) {
        this.$dataStore.User = AES.encrypt(val)
        this.$storage.set(this.userKey, this.$dataStore.User)

        if (val === null || !val) {
          this.$dataStore.User = null
          this.$storage.remove(this.userKey)
          this.$storage.remove(this.authorizationTokenKey)
        }
      }
    },

    userIsAuthenticated () {
      return !!this.user
    },

    userInitials () {
      if (!this.user) return ''

      return (this.user?.Info?.Firstname?.slice(0, 1)?.toLocaleUpperCase(this.$i18n.locale) || '') + (this.user?.Info?.Lastname?.slice(0, 1)?.toLocaleUpperCase(this.$i18n.locale) || '')
    }
  }

}
