<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <v-container
          fluid
          pa-0
        >
          <v-row>
            <v-col cols="12" />
          </v-row>

          <v-row v-if="$vuetify.breakpoint.mdAndDown">
            <v-col cols="12">
              <v-tabs
                background-color="white"
                class="rounded"
                color="brand-primary"
                light
                show-arrows
                slider-color="brand-primary"
              >
                <v-tab
                  v-for="(item, i) in items"
                  :key="i"
                  :to="{name: item.to}"
                  class="brand-primary--text"
                >
                  <v-icon
                    v-if="item.icon"
                    class="mr-3 brand-primary--text text--lighten-3"
                    v-text="item.icon"
                  />

                  {{ item.text }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-if="$vuetify.breakpoint.lgAndUp"
              class="shrink"
            >
              <v-list
                nav
              >
                <v-list-item-group color="brand-primary">
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :to="{name: item.to}"
                  >
                    <v-list-item-icon v-if="item.icon">
                      <v-icon v-text="item.icon" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>

            <v-col>
              <router-view
                :page-content="$t(`Page.${$route.name}.Body`)"
                :page-title="$t(`Page.${$route.name}.Title`)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'

export default {
  name      : 'Pages',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  enums     : {},
  dataStore : {},
  data () {
    return {
      items: [
        {
          text: this.$t('Page.PageAbout.Name'),
          icon: 'mdi-store',
          to  : 'PageAbout'
        },
        {
          text: this.$t('Page.PageHowItWorks.Name'),
          icon: ' mdi-basket',
          to  : 'PageHowItWorks'
        },
        {
          text: this.$t('Page.PageFaq.Name'),
          icon: 'mdi-help-box',
          to  : 'PageFaq'
        },
        {
          text: this.$t('Page.PageContact.Name'),
          icon: 'mdi-card-account-mail',
          to  : 'PageContact'
        },
        {
          text: this.$t('Page.PoliciesTerms.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesTerms'
        },
        {
          text: this.$t('Page.PoliciesPrivacy.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesPrivacy'
        },
        {
          text: this.$t('Page.PoliciesCopyright.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesCopyright'
        },
        {
          text: this.$t('Page.PoliciesCookies.Name'),
          icon: 'mdi-cookie',
          to  : 'PoliciesCookies'
        },
        {
          text: this.$t('Page.PoliciesPayments.Name'),
          icon: 'mdi-account-credit-card',
          to  : 'PoliciesPayments'
        },
        {
          text: this.$t('Page.PoliciesReturns.Name'),
          icon: 'mdi-cash-sync',
          to  : 'PoliciesReturns'
        },
        {
          text: this.$t('Page.PoliciesAllergens.Name'),
          icon: 'mdi-food-off',
          to  : 'PoliciesAllergens'
        }
      ]
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
