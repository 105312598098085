import BaseValidator from '@/lib/data/validator/BaseValidator'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class RegisterValidator extends BaseValidator {
  validatorRules = {
    Firstname: {
      required: true,
      type    : ValidatorTypesEnum.String,
      minLen  : 2,
      maxLen  : 32
    },
    Lastname: {
      required: true,
      type    : ValidatorTypesEnum.String,
      minLen  : 2,
      maxLen  : 32
    },
    Email: {
      required: true,
      type    : ValidatorTypesEnum.Email,
      maxLen  : 96
    },
    Phone: {
      required  : true,
      type      : ValidatorTypesEnum.String,
      betweenLen: [10, 14]
    },
    Password: {
      requiredIf: ['FromSocialProvider', false],
      type      : ValidatorTypesEnum.String,
      minLen    : 8
    },
    PasswordConfirm: {
      requiredIf: ['FromSocialProvider', false],
      type      : ValidatorTypesEnum.String,
      equals    : 'Password',
      minLen    : 8
    },
    TermsCondition: {
      required: true,
      type    : ValidatorTypesEnum.Boolean
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
