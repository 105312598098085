export default {
  name   : 'Translatable',
  mixins : [],
  methods: {
    getTranslatedField (item, field = 'Name', LangObjName = 'Lang', fallback = true, fallbackField = '') {
      let retVal = item && field && item[LangObjName] && item[LangObjName][this.$i18n.locale] && item[LangObjName][this.$i18n.locale][field] || ''

      if (!retVal && fallback) {
        // console.log('!!!!')
        // console.log(item, field, LangObjName, fallback, fallbackField)
        // console.log(item, field, item[LangObjName], item[LangObjName][this.$i18n.locale], item[LangObjName][this.$i18n.locale][field])
        fallbackField = fallbackField || field
        retVal = item && fallbackField && item[fallbackField] || item && fallbackField && item[LangObjName] && item[LangObjName][this.$i18n.defaultLocale] && item[LangObjName][this.$i18n.defaultLocale][fallbackField] || ''
      }
      return retVal
    }
  }
}
