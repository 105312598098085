import BaseModel from '@/lib/data/model/BaseModel'
import formDataCommon from '@/mixins/form/formDataCommon'

export default {
  name     : 'FormData',
  dataModel: BaseModel,
  data () {
    return {}
  },
  mixins: [formDataCommon]
}
