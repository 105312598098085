<template>
  <v-footer
    clipped-right
    dark
    padless
  >
    <v-container fluid>
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
          lg="10"
          offset-lg="1"
        >
          <v-row class="justify-space-between py-2">
            <v-col>
              <h4 class="pb-2 grey--text text--lighten-1">
                {{ $t('Common.Footer.Company.Title') }}
              </h4>

              <template v-for="(item, index) in $t('Common.Footer.Company.Items')">
                <router-link
                  :key="index"
                  :to="item.To"
                  class="d-flex pt-1"
                >
                  {{ item.Title }}
                </router-link>
              </template>
            </v-col>

            <v-col>
              <h4 class="pb-2 grey--text text--lighten-1">
                {{ $t('Common.Footer.Stores.Title') }}
              </h4>

              <template v-for="(store, idx) in AppStores">
                <router-link
                  v-if="!storeIsHidden(store) || !storeIsDisabled(store)"
                  :key="`store-link-${idx}`"
                  :to="{name: 'Store', params: {slug: store.Slug, id: store.Id}}"
                  class="d-flex pt-1"
                >
                  {{ store.Name }}
                </router-link>

                <div
                  v-if="storeIsDisabled(store)"
                  :key="`store-div-${idx}`"
                  class="grey--text pt-1"
                >
                  {{ store.Name }}
                </div>
              </template>
            </v-col>

            <v-col>
              <h4 class="pb-2 grey--text text--lighten-1">
                {{ $t('Common.Footer.Terms.Title') }}
              </h4>

              <template v-for="(item, i) in policies">
                <router-link
                  :key="i"
                  :to="{name: item.to}"
                  class="d-flex pt-1"
                >
                  {{ item.text }}
                </router-link>
              </template>
            </v-col>

            <v-col>
              <h4 class="pb-2 grey--text text--lighten-1">
                {{ $t('Common.Footer.FollowUs.Title') }}
              </h4>

              <template v-for="(item, index) in $t('Common.Footer.FollowUs.Items')">
                <a
                  :key="index"
                  :href="item.Link"
                  class="d-flex pt-1"
                  target="_blank"
                >
                  {{ item.Title }}
                </a>
              </template>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row
            class="align-center justify-center justify-space-between py-2"
            no-gutters
          >
            <v-col
              :style="`min-width: ${$vuetify.breakpoint.xsOnly ? 68 : 75}px; min-height:20px;`"
              class="text-no-select"
              cols="auto"
              @click="showVersion = !showVersion"
            >
              <v-slide-x-transition>
                <span
                  v-show="showVersion"
                  class="caption ml-2 grey--text text--darken-2"
                >v{{ $dataStore.App.Version }}</span>
              </v-slide-x-transition>
            </v-col>

            <v-col cols="auto">
              <div class="pa-1 mt-1">
                <img
                  :alt="$t('Common.App.Title')"
                  height="16px"
                  src="/img/logo/logo-light.png"
                >
                &copy; {{ new Date().getFullYear() }} - Powered by <strong><a
                  href="https://getfoodpro.gr/el"
                  target="_blank"
                >GETFOOD Pro</a></strong>
              </div>
            </v-col>

            <v-col cols="auto">
              <v-btn
                v-if="AppConfig.Theme.ToggleDarkButton"
                class="mr-2"
                depressed
                fab
                x-small
                @click="toggleThemeDark"
              >
                <v-icon>mdi mdi-invert-colors</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name      : 'AppFooter',
  components: {},
  directives: {},
  mixins    : [StoreCommon],
  props     : {},
  dataStore : {
    AppConfig: 'App.Config',
    AppStores: 'App.Stores'
  },
  data () {
    return {
      showVersion: false,
      policies   : [
        {
          text: this.$t('Page.PoliciesTerms.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesTerms'
        },
        {
          text: this.$t('Page.PoliciesPrivacy.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesPrivacy'
        },
        {
          text: this.$t('Page.PoliciesCopyright.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesCopyright'
        },
        {
          text: this.$t('Page.PoliciesCookies.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesCookies'
        },
        {
          text: this.$t('Page.PoliciesPayments.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesPayments'
        },
        {
          text: this.$t('Page.PoliciesReturns.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesReturns'
        },
        {
          text: this.$t('Page.PoliciesAllergens.Name'),
          icon: 'mdi-file-document',
          to  : 'PoliciesAllergens'
        }
      ]
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.$vuetify.theme.dark = this.$localStorage.get('dark', this.AppConfig?.Theme?.Dark || this.$vuetify.theme.dark)
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    toggleThemeDark () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$localStorage.set('dark', this.$vuetify.theme.dark)
    }
  }
}
</script>

<style scoped>
a:link {
  color: #FFFFFF;
  font-size: 14px;
  text-decoration: none;
}

a:visited {
  color: #FFFFFF;
  font-size: 14px;
  text-decoration: none;
}

a:hover {
  color: #FFFFFF;
  font-size: 14px;
  text-decoration: none;
}

a:active {
  color: #FFFFFF;
  font-size: 14px;
  text-decoration: none;
}
</style>
