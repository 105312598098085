<template>
  <v-container
    fluid
    pa-0
  >
    <v-row>
      <v-col
        v-if="title"
        cols="12"
      >
        <h2 class="d-inline-block">
          {{ title }}
        </h2>
      </v-col>

      <v-col cols="12">
        <v-list two-line>
          <v-list-item-group
            v-model="vModel"
            color="brand-primary"
            mandatory
          >
            <template v-for="(item, i) in paymentMethods">
              <v-list-item
                v-if="item.Status"
                :key="i"
                :value="item"
              >
                <v-list-item-icon
                  v-if="item.Icon"
                  class="my-auto"
                >
                  <v-icon
                    v-text="item.Icon"
                  />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="getTranslatedField(item)"
                  />

                  <v-list-item-subtitle class="text-caption">
                    {{ $t('Cart.Message.Info.MinimumOrder.Title') }} {{ paymentMinOrderTotal(item) | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VModel from '@/mixins/vModel'
import Translatable from '@/mixins/translatable'
import CartCommon from '@/mixins/cart/cartCommon'

export default {
  name      : 'SelectPaymentMethod',
  components: {},
  directives: {},
  mixins    : [Translatable, VModel, CartCommon],
  props     : {
    title: {
      type   : String,
      default: 'Payment Details'
    }
  },
  dataStore: {
    AppCart    : 'App.Cart',
    AppPayments: 'App.Payments'
  },
  data () {
    return {}
  },
  computed: {
    paymentMethods () {
      return this.AppPayments || []
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
