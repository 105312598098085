import OrderStatusEnum from '@/api/enums/OrderStatusEnum'

export default {
  Title: 'Your Order',

  Button: {
    ViewCart   : 'View Order',
    Checkout   : 'Complete Order',
    AddItems   : 'Add Products',
    UpdateOrder: 'Update',
    AddToOrder : 'Add'
  },

  Coupon: {
    Title : 'Do you have a discount coupon?',
    Label : 'Coupon Code',
    Item  : 'COUPON',
    Button: {
      Apply : 'Apply Coupon',
      Remove: 'Remove Coupon'
    }
  },

  BrandSlider: {
    Title: 'Favorite Brands ✨'
  },

  OrderStatus: [
    {
      Id   : OrderStatusEnum.NotComplete,
      Name : 'NOT COMPLETE',
      Color: 'deep-orange',
      Icon : 'mdi-priority-high'
    },
    {
      Id   : OrderStatusEnum.Pending,
      Name : 'PENDING',
      Color: 'grey',
      Icon : 'mdi-swap-vertical-circle'
    },
    {
      Id   : OrderStatusEnum.Received,
      Name : 'WAITING ACCEPTANCE',
      Color: 'grey',
      Icon : 'mdi-call-received'
    },
    {
      Id   : OrderStatusEnum.Accepted,
      Name : 'ACCEPTED',
      Color: 'green',
      Icon : 'mdi-check'
    },
    {
      Id   : OrderStatusEnum.Preparation,
      Name : 'PREPARATION',
      Color: 'purple',
      Icon : 'room_service'
    },
    {
      Id   : OrderStatusEnum.Delivery,
      Name : 'FOR COLLECTION',
      Color: 'brown',
      Icon : 'mdi-run'
    },
    {
      Id   : OrderStatusEnum.Completed,
      Name : 'COMPLETED',
      Color: 'light-green',
      Icon : 'mdi-check-all'
    },
    {
      Id   : OrderStatusEnum.Canceled,
      Name : 'CANCELED',
      Color: 'red',
      Icon : 'close'
    }
  ],

  Message: {
    Info: {
      Security    : 'Secure Transactions with 128-bit Encryption.',
      MinimumOrder: {
        Body   : 'You need {minimumOrderTotalDiff} for the minimum order. Please add more products to your cart.',
        BodyAlt: 'You need {minimumOrderTotalDiff} for the minimum order.',
        Title  : 'Minimum order'
      },
      NoItems: {
        Title   : 'No Items in your order',
        Subtitle: 'Your order is lonely without items. Let\'s add some!'

      }
    },
    Error: {
      Product: {
        Option: {
          Between: {
            Message: 'Please make {MinChoices} to {MaxChoices} choices | Please select from {MinChoices} to {MaxChoices} | Please make {MinChoices} to {MaxChoices} choices'
          },
          MaxChoices: {
            Message: 'Please make up to {MaxChoices} choices | Please make up to {MaxChoices} choice | Please make up to {MaxChoices} choices'
          },
          MinChoices: {
            Message: 'Please make {MinChoices} choices | Please make {MinChoices} selection | Please make {MinChoices} choices'
          },
          MinChoicesRequired: {
            Message: 'Please make at least {MinChoices} choices | Please make at least {MinChoices} choice | Please make at least {MinChoices} choices'
          },
          MinimumQuantity: {
            Body : 'Inventory for \'{menuItem}\' is less than the minimum order quantity ({minimumQuantity}).',
            Title: ''
          }
        },
        Stock: {
          Invalid: {
            Body : 'No stock available for product \'{menuItem}\'.',
            Title: ''
          },
          MinimumQuantity: {
            Body : 'Stock for \'{menuItem}\' is less than the minimum order quantity ({minimumQuantity}).',
            Title: ''
          }
        }
      }
    }
  }
}
