import BaseValidator from '@/lib/data/validator/BaseValidator'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class AccountSettingsValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: true,
      type    : BaseValidator.Type.Number
    },
    Firstname: {
      required: true,
      type    : ValidatorTypesEnum.String,
      minLen  : 2,
      maxLen  : 32
    },
    Lastname: {
      required: true,
      type    : ValidatorTypesEnum.String,
      minLen  : 2,
      maxLen  : 32
    },
    Phone: {
      required  : true,
      type      : ValidatorTypesEnum.String,
      betweenLen: [10, 14]
    },
    NewPass: {
      requiredIfNot: ['NewPassConfirm', ''],
      type         : ValidatorTypesEnum.String,
      minLen       : 8
    },
    NewPassConfirm: {
      requiredIfNot: ['NewPass', ''],
      type         : ValidatorTypesEnum.String,
      equals       : 'NewPass',
      minLen       : 8
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
