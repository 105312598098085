import { API } from '@/api/Api'

export default {

  async Menu (storeId, data = {}, config = {}) {
    const Endpoint = API.Endpoint.Store.Menu
    Endpoint.Path = Endpoint.Path.replace('{storeId}', storeId)

    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  }

}
