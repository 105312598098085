<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>{{ pageTitle }}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="text-justify"
        cols="12"
        v-html="pageContent"
      />
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'

export default {
  name      : 'PageContent',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {
    pageTitle: {
      type   : String,
      default: ''
    },

    pageContent: {
      type   : String,
      default: ''
    }
  },
  enums    : {},
  dataStore: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
