export default {
  Details: {
    Title: 'Complete Order',

    OrderDetails: {
      Title: 'Selected Products'
    },

    CustomerDetails: {
      Title       : 'Customer Details',
      Notification: {
        Login          : 'Already have an account?',
        Logout         : 'Welcome back {user}. Aren\'t you?',
        NoAccount      : 'No, I don\'t have an account.Do',
        ContinueAsGuest: 'or continue as a guest.'
      },
      Field: {
        Firstname: {
          Label      : 'Name',
          Placeholder: '',
          Hint       : ''
        },
        Lastname: {
          Label      : 'Surname',
          Placeholder: '',
          Hint       : ''
        },
        Email: {
          Label      : 'Email',
          Placeholder: '',
          Hint       : ''
        },
        Phone: {
          Label      : 'Mobile',
          Placeholder: '',
          Hint       : ''
        }
      }
    },

    OrderComments: {
      Title: 'Order Comments',
      Field: {
        Comments: {
          Label      : 'Order Comments',
          Placeholder: '',
          Hint       : ''
        }
      }
    },

    PaymentDetails: {
      Title: 'Order Payment'
    }
  },

  Success: {
    Title: 'Order Status',

    Status: {
      Title: 'Order Status',
      Body : {
        Stay   : 'Stay on the page and follow the progress of the order. When it will be ready (status',
        Receive: ') you will be able to collect it from the store with the order code',
        Change : '. Do you want to change something? Contact the store directly.'
      }
    },

    BrandSlider: {
      Title: 'Favorite Brands ✨'
    },

    Stores: {
      Title : 'Stores',
      Status: {
        Time: 'Estimated Time'
      }
    },

    NotFound: {
      Title   : 'Order not found!',
      Subtitle: 'Discover amazing stores, add products and create an order!'
    }
  },

  Failure: {
    Title: 'Order Failure',

    Status: {
      Title: 'Order Failure',
      Body : 'Unfortunately, the order failed and was not executed. Try again later.'
    },

    BrandSlider: {
      Title: 'Favorite Brands ✨'
    },

    Stores: {
      Title : 'Stores',
      Status: {
        Time: 'Estimated Time'
      }
    },

    NotFound: {
      Title   : 'Order not found!',
      Subtitle: 'Discover amazing stores, add products and create an order!'
    }
  },

  Button: {
    CompleteOrder: 'Complete Order'
  }
}
