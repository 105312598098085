import BaseResource from '@/lib/data/resource/BaseResource'

export default class RegisterSaveResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.first_name = payload.Firstname ?? ''
    this.last_name = payload.Lastname ?? ''
    this.email = payload.Email ?? ''
    this.phone = payload.Phone ?? ''
    this.password = payload.Password ?? ''
    this.password_confirm = payload.PasswordConfirm ?? ''
    this.terms_condition = payload.TermsCondition ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
