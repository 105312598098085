import BaseModel from '@/lib/data/model/BaseModel'
import AccountSettingsValidator from '@/api/validations/account/AccountSettingsValidator'
import AccountSettingsResource from '@/api/resources/account/AccountSettingsResource'
import API from '@/api/Api'

export default class AccountSettingsModel extends BaseModel {
  constructor (payload = {}, resource = AccountSettingsResource, validator = AccountSettingsValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['Email']
  }

  /* PROPERTIES */

  /* METHODS */
  async SaveSettings () {
    const payload = { Info: this.clone() }
    return await API.Resource.Account.Details(payload)
  }

  async DeleteAccount (payload) {
    return await API.Resource.Account.Delete(payload)
  }

  async ExportAccount (payload) {
    return await API.Resource.Account.Export(payload)
  }

  async ConfirmPin (payload) {
    return await API.Resource.Account.Pin(payload)
  }
}
