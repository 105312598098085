import API from '@/api/Api'
import { Providers } from 'universal-social-auth'

export default {
  name: 'AuthSocialApi',
  data () {
    return {}
  },
  methods: {
    authSocialProvider (provider) {
      this.$bus.$emit('login:show-dialog', false)
      this.$bus.$emit('app:fullscreen-loader', true)
      const providerData = Providers[provider]
      this.$Oauth.authenticate(provider, providerData).then((response) => {
        if (response.code) this.socialLogin(provider, response.code)
      })
        .catch(e => {
        })
        .finally(() => {
          this.$bus.$emit('app:fullscreen-loader', false)
        })
    },

    socialLogin (provider, code) {
      if (!provider || !code) return
      const redirectUri = this.AppConfig?.Auth?.Providers[provider]?.redirectUri || ''

      API.Resource.User.AuthSocial(provider, {
        code       : code,
        redirectUri: redirectUri
      })
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const data = API.responseData(response)
            if (data.CustomerInfo && data.IsLogged && data.SessionId && data.access_token) {
              this.onLoginSuccess(response, data)
            } else {
              this.$bus.$emit('register:show-dialog', data.CustomerInfo)
            }
          }
        })
        .catch(e => {
        })
        .finally(() => {
        })
    }
  }
}
