export default {
  Title: 'Profile',

  PersonalInfo: {
    Title: 'Personal Info'
  },
  Addresses: {
    Title      : 'Addresses',
    NoAddresses: {
      Title   : 'You have not added addresses yet',
      Subtitle: 'You will find all your addresses here'
    }
  },
  OrderHistory: {
    Title   : 'Order History',
    NoOrders: {
      Title   : 'You haven’t made any orders yet',
      Subtitle: 'You’ll find all the details of your orders here'
    }
  },
  Settings: {
    Title: 'Settings',

    Profile      : 'Profile',
    ChangePass   : 'Change Password',
    Notification : 'Ενημερώσεις',
    Account      : 'Account',
    DeleteAccount: 'Permanent Account Deletion',
    ExportAccount: 'Export Personal Data',

    Field: {
      Firstname: {
        Label      : 'Firstname',
        Placeholder: 'Firstname',
        Hint       : ''
      },
      Lastname: {
        Label      : 'Lastname',
        Placeholder: 'Lastname',
        Hint       : ''
      },
      Email: {
        Label      : 'Email',
        Placeholder: 'Email',
        Hint       : ''
      },
      Phone: {
        Label      : 'Mobile Phone',
        Placeholder: 'Mobile Phone',
        Hint       : ''
      },
      Password: {
        Label      : 'Password',
        Placeholder: 'Password',
        Hint       : ''
      },
      NewPass: {
        Label      : 'New Password',
        Placeholder: 'New Password',
        Hint       : ''
      },
      NewPassConfirm: {
        Label      : 'Confirm Password',
        Placeholder: 'Confirm Password',
        Hint       : ''
      },
      Newsletter: {
        Label      : 'Newsletter',
        Placeholder: 'Newsletter',
        Hint       : ''
      },
      Push: {
        Label      : 'Push Notifications',
        Placeholder: 'Push Notifications',
        Hint       : ''
      },
      Sms: {
        Label      : 'SMS',
        Placeholder: 'SMS',
        Hint       : ''
      }
    }
  }
}
