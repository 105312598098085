<template>
  <v-container fluid>
    <general-slider
      :items="offers.general"
      buttons
    />

    <v-row>
      <v-col cols="12">
        <h2>{{ $tc('Store.Title', 0) }}</h2>
      </v-col>

      <template v-for="(store, idx) in AppStores">
        <v-col
          v-if="!storeIsHidden(store)"
          :key="`store-${idx}`"
          cols="12"
          lg="4"
          sm="6"
        >
          <store-card
            :item="store"
            :to="{name: 'Store', params: {slug: store.Slug, id: store.Id}}"
            hover
          />
        </v-col>
      </template>
    </v-row>

    <offer-slider :offers="offers.onePlusOne" />

    <!--
    <brand-slider
        :stores="AppStores"
        title="Αγαπημένα Brands ✨"
    />

    <category-slider
      :stores="AppStores"
      title="Κατηγορίες"
    />

    <offer-slider
      :offers="offers.giveItaTry"
      title="Give it a try -30%👇"
    />
    -->
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import StoreCard from '@/theme/default/views/home/StoreCard'
import OfferSlider from '@/theme/default/views/home/OfferSlider'
import GeneralSlider from '@/theme/default/views/home/GeneralSlider'
import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name      : 'Home',
  components: {
    GeneralSlider,
    OfferSlider,
    StoreCard
  },
  directives: {},
  mixins    : [i18nRouteMeta, StoreCommon],
  props     : {},
  enums     : {},
  dataStore : {
    AppStores: 'App.Stores'
  },
  data () {
    return {
      offers: {
        general: [
          {
            Type  : 'video',
            Poster: 'https://discovery-cdn.wolt.com/content_editor/banners/images/07b44888-32be-11ef-bd44-becf28ec4bdf_2f66e2ed_44ee_4b2a_8727_5b4630acbc9a.png',
            Src   : '/banner/simply-burgers-1719947876112.mp4',
            Link  : '/el/store/simply-burgers/5',
            Target: '_self'
          },
          {
            Type  : 'video',
            Poster: 'https://discovery-cdn.wolt.com/content_editor/banners/images/d0923d8a-f18e-11ee-a59e-5265a9e0dc69_25e20d0a_d6da_44e4_a294_c44db0fc5a5f.png',
            Src   : '/banner/pizza-fan-1719947916105.mp4',
            Link  : '/el/store/pizza-fan/3',
            Target: '_self'
          },
          {
            Type  : 'video',
            Poster: 'https://discovery-cdn.wolt.com/content_editor/banners/images/a31a30aa-2c7c-11ef-a4e3-d2638f3ae13a_c2fc5163_2e25_4019_8608_12f01938362e.png',
            Src   : '/banner/mailos-1719948459454.mp4',
            Link  : 'https://www.mailos.gr/',
            Target: '_blank'
          },
          {
            Type  : 'image',
            Src   : '/banner/kfc-banner-image.png',
            Link  : 'https://www.kfc.gr/',
            Target: '_blank'
          }
        ],
        giveItaTry: [
          {
            Name         : 'Chicken Buttermilk Burger',
            Desc         : 'Nob Burger',
            OriginalPrice: '9.00',
            OfferPrice   : '6.30',
            Image        : 'https://imageproxy.wolt.com/wolt-frontpage-images/content_editor/banners/images/639c4446-e2ac-11ee-8e8d-1297afd1f1f9_2e3da79d_b950_40ec_a205_602ade6d1035.png?w=960'
          },
          {
            Name         : 'Greek Island Pasta',
            Desc         : 'Salad Plus',
            OriginalPrice: '8.40',
            OfferPrice   : '5.88',
            Image        : 'https://imageproxy.wolt.com/wolt-frontpage-images/content_editor/banners/images/49287856-ec15-11ee-84a9-82c8900357c2_4ea90a35_8963_40fd_925f_37553bcc86b4.png?w=960'
          },
          {
            Name         : 'Prime ',
            Desc         : 'Akoko',
            OriginalPrice: '7.90',
            OfferPrice   : '5.53',
            Image        : 'https://imageproxy.wolt.com/wolt-frontpage-images/content_editor/banners/images/e77cc94e-c758-11ee-baec-1e414e01d85a_d2187e17_1615_4fa5_978b_f98c949c6058.png?w=960'
          },
          {
            Name         : 'Wings',
            Desc         : 'Tsiken ',
            OriginalPrice: '5.70',
            OfferPrice   : '3.99',
            Image        : 'https://imageproxy.wolt.com/wolt-frontpage-images/content_editor/banners/images/adc28ce6-ec3f-11ee-ae9d-6eb140c14f6c_e76e89c7_4299_4bab_8950_a68238955c8f.png?w=960'
          },
          {
            Name         : 'Mama Crepa',
            Desc         : 'Mama Crepa',
            OriginalPrice: '8.90',
            OfferPrice   : '6.23',
            Image        : 'https://imageproxy.wolt.com/wolt-frontpage-images/content_editor/banners/images/d15dc4a0-929d-11ee-9671-5e599f23a545_0cebef03_4933_4978_86d0_226a2d253a6b.png?w=960'
          }
        ],
        onePlusOne: [
          {
            Name         : '1+1 Mix Vegetable Curry',
            Desc         : 'Kolkata Indian Restaurant',
            OriginalPrice: '15.00',
            OfferPrice   : '7.50',
            Image        : 'https://imageproxy.wolt.com/menu/menu-images/627e53677c4f2dec6524b735/44e144e8-dcd7-11ec-958f-ee13eedbdc42__8.jpeg?w=960'
          },
          {
            Name         : '1+1 California Roll',
            Desc         : 'Nakama Sushi Bar Nea Smirni',
            OriginalPrice: '10.00',
            OfferPrice   : '5.00',
            Image        : 'https://imageproxy.wolt.com/menu/menu-images/689ac310-4594-11e9-bd94-0a586460013f_25.jpeg?w=960'
          },
          {
            Name         : '1+1 The Chicken Burger',
            Desc         : 'Bistro di Atene Athens',
            OriginalPrice: '11.80',
            OfferPrice   : '5.90',
            Image        : 'https://imageproxy.wolt.com/menu/menu-images/6569c8250d365e4c28f315b9/1ba2276a-9053-11ee-9aac-9230372d9ab3_image_recipe_594df7b0_4fd2_417a_a6ca_e0a11b96f348.jpg?w=960'
          },
          {
            Name         : '1+1 Pesto-holic',
            Desc         : 'Pastaholic Athens',
            OriginalPrice: '17.60',
            OfferPrice   : '8.80',
            Image        : 'https://imageproxy.wolt.com/menu/menu-images/6359320049367d9358f24a5c/ffd9c790-61fe-11ee-ab1e-aedb6b0c5370_image_recipe_35e21556_e4fe_49c0_b01d_14e8a8481f8a.jpg?w=960'
          },
          {
            Name         : 'French Fries with Melted Cheddar and Crispy Bacon',
            Desc         : 'Holy Burger',
            OriginalPrice: '10.00',
            OfferPrice   : '5.00',
            Image        : 'https://imageproxy.wolt.com/menu/menu-images/61c785bd9c69bdc56905301f/032b9142-7261-11ec-ae48-123f16fcd387_holyb11.jpeg?w=960'
          }
        ]
      }
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.$bus.$emit('cart:get-data')
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
