import { themeUtils } from '@/lib/utils'
import { i18n } from '@/lang/lang'
import { HmacMD5 } from '@/lib/crypto/crypto'

// eslint-disable-next-line no-unused-vars
const PlaceHolder = () => themeUtils.importThemeComponent('components/common/PlaceHolder')

const Home = () => themeUtils.importThemeComponent('views/home/Home')
const Store = () => themeUtils.importThemeComponent('views/store/Store')
const CheckoutDetails = () => themeUtils.importThemeComponent('views/checkout/CheckoutDetails')
const CheckoutSuccess = () => themeUtils.importThemeComponent('views/checkout/CheckoutSuccess')
const CheckoutFailure = () => themeUtils.importThemeComponent('views/checkout/CheckoutFailure')
const MarketplaceAccountConnected = () => themeUtils.importThemeComponent('views/marketplace/MarketplaceAccountConnected')
const Pages = () => themeUtils.importThemeComponent('views/pages/Pages')
const PageContent = () => themeUtils.importThemeComponent('views/pages/PageContent')
const PageContact = () => themeUtils.importThemeComponent('views/pages/PageContact')
const Account = () => themeUtils.importThemeComponent('views/account/Account')
const AccountPersonalInfo = () => themeUtils.importThemeComponent('views/account/AccountPersonalInfo')
const AccountAddresses = () => themeUtils.importThemeComponent('views/account/AccountAddresses')
const AccountOrderHistory = () => themeUtils.importThemeComponent('views/account/AccountOrderHistory')
const AccountSettings = () => themeUtils.importThemeComponent('views/account/AccountSettings')

export const AppRoutes = {
  SimpleRoutes: [
    {
      name    : 'Default',
      path    : '*',
      redirect: () => {
        return '/' + i18n.locale
      }
    },
    {
      name    : 'VivaPaymentSuccess',
      path    : '/payment/viva/success',
      redirect: to => {
        const language = i18n.languages.find(lang => lang.code === to.query.lang)
        const storageKey = HmacMD5.hash('ORDERID')
        const item = sessionStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}_${storageKey}`) || ''
        const orderId = item ? JSON.parse(item) : ''

        return {
          name  : 'CheckoutSuccess',
          params: {
            lang    : language?.locale ?? process.env.VUE_APP_I18N_LOCALE,
            orderId : orderId,
            redirect: true
          },
          query: {}
        }
      },
      meta: {
        requiresAuth: false
      }
    },
    {
      name    : 'VivaPaymentFailure',
      path    : '/payment/viva/failure',
      redirect: to => {
        const language = i18n.languages.find(lang => lang.code === to.query.lang)
        const storageKey = HmacMD5.hash('ORDERID')
        const item = sessionStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}_${storageKey}`) || ''
        const orderId = item ? JSON.parse(item) : ''

        return {
          name  : 'CheckoutFailure',
          params: {
            lang    : language?.locale ?? process.env.VUE_APP_I18N_LOCALE,
            orderId : orderId,
            redirect: true,
            error   : true
          },
          query: {}
        }
      },
      meta: {
        requiresAuth: false
      }
    },
    {
      name    : 'VivaMarketplaceAccountConnected',
      path    : '/marketplace/viva/account/connected',
      redirect: to => {
        const language = i18n.languages.find(lang => lang.code === to.query.lang)

        return {
          name  : 'MarketplaceAccountConnected',
          params: {
            lang    : language?.locale ?? process.env.VUE_APP_I18N_LOCALE,
            redirect: true
          },
          query: {}
        }
      },
      meta: {
        requiresAuth: false
      }
    }
  ],

  LangRoutes: [
    {
      name     : 'Home',
      path     : '',
      component: Home,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    {
      name     : 'Store',
      path     : 'store/:slug?/:id?',
      component: Store,
      props    : true,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    {
      name     : 'CheckoutDetails',
      path     : 'checkout/details',
      component: CheckoutDetails,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    {
      name     : 'CheckoutSuccess',
      path     : 'checkout/success/:orderId?',
      component: CheckoutSuccess,
      props    : true,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    {
      name     : 'CheckoutFailure',
      path     : 'checkout/failure/:orderId?',
      component: CheckoutFailure,
      props    : true,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    {
      name     : 'OrderStatus',
      path     : 'order/status/:orderId?',
      component: CheckoutSuccess,
      props    : true,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    },
    {
      name     : 'MarketplaceAccountConnected',
      path     : 'marketplace/viva/account/connected',
      component: MarketplaceAccountConnected,
      props    : true,
      meta     : {
        requiresAuth: false,
        layout      : 'simple'
      }
    },
    {
      name     : 'Account',
      path     : 'account',
      component: Account,
      redirect : { name: 'AccountPersonalInfo' },
      meta     : {
        requiresAuth: true,
        layout      : 'default'
      },
      children: [
        {
          name     : 'AccountPersonalInfo',
          path     : 'personal-info',
          component: AccountPersonalInfo,
          meta     : {
            requiresAuth: true,
            layout      : 'default'
          }
        },
        {
          name     : 'AccountAddresses',
          path     : 'addresses',
          component: AccountAddresses,
          meta     : {
            requiresAuth: true,
            layout      : 'default'
          }
        },
        {
          name     : 'AccountOrderHistory',
          path     : 'order-history',
          component: AccountOrderHistory,
          meta     : {
            requiresAuth: true,
            layout      : 'default'
          }
        },
        {
          name     : 'AccountSettings',
          path     : 'settings',
          component: AccountSettings,
          meta     : {
            requiresAuth: true,
            layout      : 'default'
          }
        }
      ]
    },
    {
      name     : 'Pages',
      path     : 'page',
      component: Pages,
      redirect : { name: 'PageAbout' },
      meta     : {
        requiresAuth: false
      },
      children: [
        {
          name     : 'PageContact',
          path     : 'contact',
          component: PageContact,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PageAbout',
          path     : 'about',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PageHowItWorks',
          path     : 'how-it-works',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PageFaq',
          path     : 'faq',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PoliciesTerms',
          path     : 'policies/terms',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PoliciesPrivacy',
          path     : 'policies/privacy',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PoliciesCopyright',
          path     : 'policies/copyright',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PoliciesCookies',
          path     : 'policies/cookies',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PoliciesPayments',
          path     : 'policies/payments',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PoliciesReturns',
          path     : 'policies/returns',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        },
        {
          name     : 'PoliciesAllergens',
          path     : 'misc/allergens',
          component: PageContent,
          meta     : {
            requiresAuth: false
          }
        }
      ]
    }
  ]
}

export default AppRoutes
