<template>
  <div
    v-if="product"
    :class="{'grey--text lighten-1': disabled}"
    class="orange--text d-inline-flex"
    style="min-width: 26px;"
  >
    <template v-if="product.UnitId > 1">
      {{ formatUnitQuantity($i18n.locale, product.UnitQuantity, getMeasurementUnitById(product.UnitId)) }}
    </template>
    <template v-else>
      {{ product.Quantity }}x
    </template>
  </div>
</template>
<script>

import { formatUnitQuantity } from '@/lib/utils/currency/currency'

export default {
  name      : 'ProductQuantity',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    product: {
      type   : Object,
      default: undefined
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    formatUnitQuantity,
    getMeasurementUnitById (id) {
      if (!id) return null

      return this.$t('Common.Units')?.find(item => item.Id === id) || null
    }
  }
}
</script>

<style scoped>

</style>
