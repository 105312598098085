import BaseModel from '@/lib/data/model/BaseModel'
import LoginValidator from '@/api/validations/user/LoginValidator'
import LoginResource from '@/api/resources/user/LoginResource'

export default class LoginModel extends BaseModel {
  constructor (payload = {}, resource = LoginResource, validator = LoginValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
