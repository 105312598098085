<template>
  <v-container
    v-if="showData"
    :class="{'fill-height': !orderFound}"
    fluid
  >
    <v-row
      v-if="!orderFound"
      class="fill-height align-center justify-center text-center"
    >
      <v-col
        cols="12"
        md="8"
        sm="10"
      >
        <div class="text-center">
          <div class="mb-6">
            <v-icon
              color="brand-primary"
              size="256"
            >
              mdi-basket-plus
            </v-icon>
          </div>

          <h1>{{ $t('Checkout.Success.NotFound.Title') }}</h1>

          <h4 class="font-weight-regular mt-4">
            {{ $t('Checkout.Success.NotFound.Subtitle') }}
          </h4>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="8"
        sm="10"
      >
        <brand-slider
          :stores="AppStores"
          :title="$t('Checkout.Success.BrandSlider.Title')"
          dense
        />
      </v-col>
    </v-row>

    <template v-else>
      <v-row>
        <v-col
          cols="12"
          md="8"
          offset-md="2"
          offset-sm="1"
          sm="10"
        >
          <div class="d-flex">
            <h1 class="d-inline-flex">
              {{ $t('Checkout.Success.Status.Title') }}
            </h1>

            <v-spacer />

            <v-chip
              class="light-blue justify-center font-weight-bold d-inline-flex my-auto px-1"
              dark
              label
              style="min-width: 125px;"
            >
              {{ orderCode }}
            </v-chip>
          </div>
          {{ $t('Checkout.Success.Status.Body.Stay') }}
          <v-chip
            :color="getOrderStatusColorById(OrderStatusEnum.Delivery)"
            class="px-1 font-weight-bold"
            dark
            label
            x-small
          >
            {{ getOrderStatusNameById(OrderStatusEnum.Delivery) }}
          </v-chip>
          {{ $t('Checkout.Success.Status.Body.Receive') }}
          <v-chip
            class="px-1 light-blue font-weight-bold"
            dark
            label
            x-small
          >
            {{ orderCode }}
          </v-chip>
          {{ $t('Checkout.Success.Status.Body.Change') }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="8"
          offset-md="2"
          offset-sm="1"
          sm="10"
        >
          <h2 class="d-inline-block">
            {{ $t('Checkout.Success.Stores.Title') }}
          </h2>
        </v-col>

        <v-col
          cols="12"
          md="8"
          offset-md="2"
          offset-sm="1"
          sm="10"
        >
          <v-list
            class="pa-0"
            two-line
          >
            <template v-for="(order, idx) in orders">
              <v-list-item
                :key="`store-list-item-${idx}`"
                class="pa-1 pa-sm-3"
              >
                <div class="mr-3 text-center">
                  <v-avatar size="65">
                    <img :src="order.StoreLogo">
                  </v-avatar>
                </div>

                <v-list-item-content>
                  <template v-if="$vuetify.breakpoint.smAndUp">
                    <v-list-item-title class="font-weight-bold title mb-2">
                      {{ order.StoreName }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="order.StoreDesc">
                      {{ order.StoreDesc }}
                    </v-list-item-subtitle>
                  </template>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    v-if="order.StatusId !== OrderStatusEnum.Completed && order.StatusId !== OrderStatusEnum.Canceled"
                    class="font-weight-medium"
                  >
                    <v-chip
                      class="px-2 justify-center"
                      label
                      small
                      style="min-width: 180px;"
                    >
                      {{ toLocaleUpperCase($t('Checkout.Success.Stores.Status.Time')) }} {{ order.Time }}"
                    </v-chip>
                  </v-list-item-action-text>

                  <v-list-item-action-text class="font-weight-medium">
                    <v-chip
                      :color="getOrderStatusColorById(order.StatusId)"
                      class="px-2 mt-2"
                      dark
                      label
                      small
                      style="min-width: 180px; display: inline-grid;"
                    >
                      <v-progress-circular
                        v-if="order.StatusId !== OrderStatusEnum.Completed && order.StatusId !== OrderStatusEnum.Canceled"
                        :size="16"
                        :width="2"
                        class="d-inline-flex mr-2"
                        color="white"
                        indeterminate
                      />
                      <v-icon
                        v-else-if="order.StatusId === OrderStatusEnum.Completed"
                        class="d-flex mr-2"
                        size="=16"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon
                        v-else-if="order.StatusId === OrderStatusEnum.Canceled"
                        class="d-flex mr-2"
                        size="16"
                      >
                        mdi-close
                      </v-icon>

                      <div class="text-center fill-width">
                        {{ getOrderStatusNameById(order.StatusId) }}
                      </div>
                    </v-chip>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <v-divider :key="`store-list-item-divider-${idx}`" />
            </template>
          </v-list>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import { HmacMD5 } from '@/lib/crypto/crypto'
import API from '@/api/Api'
import BrandSlider from '@/theme/default/views/home/BrandSlider.vue'
import AutoUpdateApiData from '@/mixins/api/autoUpdateApiData'
import OrderStatusEnum from '@/api/enums/OrderStatusEnum'
import { toLocaleUpperCase } from '@/lib/helpers'
import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name      : 'CheckoutSuccess',
  components: { BrandSlider },
  directives: {},
  mixins    : [i18nRouteMeta, AutoUpdateApiData, StoreCommon],
  props     : {
    orderId: {
      type   : [Number, String],
      default: undefined
    }
  },
  enums    : { OrderStatusEnum },
  dataStore: {
    AppCart  : 'App.Cart',
    AppStores: 'App.Stores'
  },
  data () {
    return {
      autoUpdateApiDataOptions: {
        timeout  : 5000, // 10 * 60 * 1000,
        autoStart: false
      },
      isLoading : true,
      showData  : false,
      orderFound: false,
      orders    : [],
      orderCode : ''
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    const storageKey = HmacMD5.hash('ORDERID')
    this.$sessionStorage.remove(storageKey)

    if (this.orderId) {
      this.getData()
      this.startAutoUpdateApiData(this.getData)
    }
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    toLocaleUpperCase,
    getData () {
      if (!this.orderId) return

      this.isLoading = true

      const payload = {
        OrderId: this.orderId
      }

      API.Resource.Checkout.Success(payload)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.orderFound = true
            const data = API.responseData(response)
            this.orders = data.Stores
            this.orderCode = data.Code
          } else {
            this.stopAutoUpdateApiData()
          }
        })
        .catch(e => {
          this.stopAutoUpdateApiData()
        })
        .finally(() => {
          this.isLoading = false
          this.showData = true
        })
    },

    getOrderStatusById (statusId) {
      const statuses = this.$te('Cart.OrderStatus') ? this.$t('Cart.OrderStatus') : []

      return statuses.find(status => status.Id === statusId)
    },

    getOrderStatusNameById (statusId) {
      return this.getOrderStatusById(statusId)?.Name || ''
    },

    getOrderStatusColorById (statusId) {
      return this.getOrderStatusById(statusId)?.Color || ''
    }
  }
}
</script>

<style scoped>
.v-chip .v-chip__content {
  width: 100%;
}
</style>
