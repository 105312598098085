<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex">
        <h2 class="d-inline-flex my-auto">
          {{ title }}
        </h2>

        <v-spacer />

        <div class="d-inline-flex my-auto">
          <v-btn
            :disabled="!hasPrev"
            class="mx-1 brand-primary lighten-5"
            color="brand-primary"
            icon
            @click="onAffixClick('prev')"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            :disabled="!hasNext"
            class="mx-1 brand-primary lighten-5"
            color="brand-primary"
            icon
            @click="onAffixClick('next')"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>

    <v-col cols="12">
      <v-slide-group
        ref="offersSlideGroup"
        class="offers-slider-group"
        show-arrows="always"
      >
        <v-slide-item
          v-for="(offer, idx) in offers"
          :key="`category-${idx}`"
        >
          <div
            :style="`width: ${cardWidth}px;`"
            class="ml-1 mr-5 mb-1"
          >
            <offer-card
              :item="offer"
              hover
            />
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-col>
  </v-row>
</template>

<script>

import OfferCard from '@/theme/default/views/home/OfferCard.vue'

export default {
  name      : 'OfferSlider',
  components: {
    OfferCard
  },
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: function () {
        return this.$tc('Offer.Title', 0)
      }
    },

    offers: {
      type   : Array,
      default: () => []
    }
  },
  enums: {},
  data () {
    return {
      hasPrev: false,
      hasNext: false
    }
  },
  computed: {
    cardWidth () {
      if (this.$vuetify.breakpoint.xs) return 200
      if (this.$vuetify.breakpoint.sm) return 300
      if (this.$vuetify.breakpoint.md) return 350
      if (this.$vuetify.breakpoint.lg) return 450

      return 500
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.$watch(() => this.$refs.offersSlideGroup.hasPrev, (newValue) => {
      this.hasPrev = newValue
    })
    this.$watch(() => this.$refs.offersSlideGroup.hasNext, (newValue) => {
      this.hasNext = newValue
    })
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    /**
     * onAffixClick
     *
     * @param location {String}
     */
    onAffixClick (location) {
      this.$refs.offersSlideGroup.onAffixClick(location)
    }
  }
}
</script>

<style scoped>
/deep/ .offers-slider-group .v-slide-group__prev, /deep/ .offers-slider-group .v-slide-group__next {
  display: none;
}

/deep/ .theme--dark.v-btn.v-btn--disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

/deep/ .theme--dark.v-btn.v-btn--disabled .v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>
