import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class CustomerValidator extends BaseValidator {
  validatorRules = {
    Id: {
      required: false,
      type    : BaseValidator.Type.Number
    },
    Name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2
    },
    Description: {
      required  : true,
      type      : BaseValidator.Type.String,
      betweenLen: [2, 250]
    },
    Active: {
      required: true,
      type    : BaseValidator.Type.Boolean
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
