import { toLocaleTitleCase } from '@/lib/helpers'
import { sum } from '@/lib/utils/array'
import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name    : 'productListCommon',
  mixins  : [StoreCommon],
  computed: {
    productName () {
      return this.getProductName(this.product)
    },

    productDescription () {
      return this.getProductDescription(this.product)
    },

    productStoreLogo () {
      return this.getStoreById(this.product.StoreId)?.Logo || ''
    },

    productDisabled () {
      return this.isProductDisabled(this.product)
    },

    productSelectedOptions () {
      return this.getProductSelectedOptions(this.product)
    },

    cartProductSelectedOptions () {
      return this.getCartProductSelectedOptions(this.product)
    }
  },

  methods: {
    getProductName (product) {
      return product ? toLocaleTitleCase(this.getTranslatedField(product)) : ''
    },

    getProductDescription (product) {
      return product ? this.getTranslatedField(product, 'Description') : ''
    },

    isProductDisabled (product) {
      if (this.disabled || !product) return true

      return (product?.Status ? (product?.SubtractStock && (product?.Quantity <= 0 || product?.Quantity <= this.getProductQuantityInCart(product))) : !product?.Status)
    },

    getProductSelectedOptions (product) {
      const options = product?.Options || []
      const selectedOptionValues = []

      options.forEach(option => {
        const optionValues = option?.OptionValues || []
        optionValues.forEach(value => {
          if (value.Selected) selectedOptionValues.push(this.getTranslatedField(value))
        })
      })

      return product && !!selectedOptionValues.length ? selectedOptionValues.join(', ') : this.getProductDescription(product)
    },

    getCartProductSelectedOptions (product) {
      const options = product?.Options[this.$i18n.locale] || []

      return product && !!options.length ? options.join(', ') : this.getProductDescription(product)
    },

    getProductQuantityInCart (product) {
      if (!product) return 0

      const products = this.AppCart?.Items?.filter(item => item.Id === product.Id) || []

      return sum(products, 'Quantity') || 0
    }
  }

}
