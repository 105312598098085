<template>
  <div>
    <v-text-field
      v-model="model"
      :class="{'closed': searchClosed && !model}"
      :placeholder="$t('Common.Search.Title')"
      class="expanding-search"
      clearable
      flat
      hide-details
      prepend-inner-icon="mdi-magnify"
      single-line
      solo
      @focusin="searchClosed = false"
      @focusout="searchClosed = true"
    />
  </div>
</template>

<script>

export default {
  name      : 'DataTableSearch',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : String,
      default: ''
    }
  },
  enums: {},
  data () {
    return {
      searchClosed: true
    }
  },
  computed: {
    model: {
      get () {
        return this.value || ''
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
.expanding-search {
  width: 300px;
  max-width: 400px;
  transition: max-width 0.3s;
}

.expanding-search.closed {
  max-width: 48px;
}

/deep/ .expanding-search.closed .v-input__control .v-input__slot {
  cursor: pointer !important;
}
</style>
