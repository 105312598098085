import BaseResource from '@/lib/data/resource/BaseResource'

export default class CustomerAddressResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.Id ?? null
    this.Street = payload.Street ?? ''
    this.Number = payload.Number ?? ''
    this.Active = payload.Active ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
