<template>
  <v-hover v-slot="data">
    <v-card>
      <v-img
        v-if="image"
        :class="hover && data.hover ? 'hover-zoom hover-zoom-active' : 'hover-zoom'"
        :height="imageHeight"
        :src="image"
      >
        <div>
          <v-chip
            class="pa-2 ma-2"
            color="brand-primary"
            dark
            label
            small
          >
            Popular
          </v-chip>

          <div class="float-right">
            <v-btn
              class="pa-0 ma-0 rounded-bl-xxl"
              color="brand-primary lighten-5 brand-primary--text"
              depressed
              height="40"
              style="min-width: 45px"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </v-img>

      <v-container fluid>
        <v-row class="align-center">
          <v-col cols="12">
            <div class="brand-primary--text">
              {{ price }}€
            </div>

            <div
              v-if="title"
              class="font-weight-medium"
            >
              <div
                class="overflow-hidden text-no-wrap"
                style="text-overflow: ellipsis;"
              >
                {{ title }}
              </div>
            </div>

            <v-card-subtitle
              v-if="subtitle"
              class="px-0 pb-0"
            >
              <div
                class="overflow-hidden text-no-wrap"
                style="text-overflow: ellipsis;"
              >
                {{ subtitle }}
              </div>
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-hover>
</template>

<script>

export default {
  name      : 'RecommendationCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },

    subtitle: {
      type   : String,
      default: ''
    },

    price: {
      type   : String,
      default: ''
    },

    image: {
      type   : String,
      default: ''
    },

    hover: {
      type   : Boolean,
      default: false
    }

  },
  enums: {},
  data () {
    return {}
  },
  computed: {
    imageHeight () {
      if (this.$vuetify.breakpoint.xs) return 100
      if (this.$vuetify.breakpoint.sm) return 130
      if (this.$vuetify.breakpoint.md) return 130
      if (this.$vuetify.breakpoint.lg) return 130

      return 130
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
.hover-zoom {
  overflow: hidden;
}

/deep/ .hover-zoom .v-image__image {
  transition: all 0.3s;
}

/deep/ .hover-zoom-active .v-image__image {
  transform: scale(1.1);
}
</style>
