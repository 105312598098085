import Account from './Account'
import Common from './Common'
import Route from './Route'
import Store from './Store'
import Checkout from './Checkout'
import Cart from './Cart'
import Auth from './Auth'
import Offer from './Offer'
import Page from './Page'

export default {
  Account : Account,
  Common  : Common,
  Route   : Route,
  Store   : Store,
  Checkout: Checkout,
  Cart    : Cart,
  Auth    : Auth,
  Offer   : Offer,
  Page    : Page
}
