import BaseModel from '@/lib/data/model/BaseModel'
import ResetPasswordValidator from '@/api/validations/user/ResetPasswordValidator'
import ResetPasswordResource from '@/api/resources/user/ResetPasswordResource'
import API from '@/api/Api'

export default class ResetPasswordModel extends BaseModel {
  constructor (payload = {}, resource = ResetPasswordResource, validator = ResetPasswordValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async ResetPassword () {
    return await API.Resource.User.ResetPassword({ email: this.Email })
  }
}
