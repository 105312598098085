import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class CheckoutPaymentValidator extends BaseValidator {
  validatorRules = {
    Customer: {
      required: true,
      type    : BaseValidator.Type.Object
    },
    Payment: {
      required: true,
      type    : BaseValidator.Type.String
    },
    QrData: {
      required: false,
      type    : BaseValidator.Type.Object
    },
    Comment: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 2
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
