import BaseModel from '@/lib/data/model/BaseModel'
import GuestValidator from '@/api/validations/customer/GuestValidator'
import GuestResource from '@/api/resources/customer/GuestResource'

export default class GuestModel extends BaseModel {
  constructor (payload = {}, resource = GuestResource, validator = GuestValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
