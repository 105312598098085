/* IMPORTS START */
import ApiResponseEnum from '@/api/enums/ApiResponseEnum'
import Http from '@/lib/data/api/Http'
import GQL from '@/lib/data/api/GQL'
import User from '@/api/endpoint/User'
import Store from '@/api/endpoint/Store'
import Config from '@/api/endpoint/Config'
import Cart from '@/api/endpoint/Cart'
import Checkout from '@/api/endpoint/Checkout'
import Account from '@/api/endpoint/Account'
import Contact from '@/api/endpoint/Contact'

/* IMPORTS END */

export const API = {
  get RESTRequest () {
    return Http.Request
  },
  get RESTRequestAll () {
    return Http.RequestAll
  },
  get RESTClient () {
    return Http
  },

  get GQLRequest () {
    return GQL.Request
  },

  get Resource () {
    return {
      /* RESOURCES */
      Contact: Contact,

      Store   : Store,
      User    : User,
      Config  : Config,
      Cart    : Cart,
      Checkout: Checkout,
      Account : Account
    }
  },

  get Endpoint () {
    return {
      /* ENDPOINTS */
      Contact: {
        Send: {
          Path  : 'contact/send',
          Method: Http.Method.Post
        }
      },

      Config: {
        Get: {
          Path  : 'config',
          Method: Http.Method.Get
        }
      },

      Account: {
        Details: {
          Path  : 'account/details',
          Method: Http.Method.Post
        },
        Orders: {
          Path  : 'account/orders',
          Method: Http.Method.Post
        },
        Delete: {
          Path  : 'account/delete',
          Method: Http.Method.Post
        },
        Export: {
          Path  : 'account/export',
          Method: Http.Method.Post
        },
        Pin: {
          Path  : 'account/pin',
          Method: Http.Method.Post
        }
      },

      Checkout: {
        Payment: {
          Path  : 'checkout/payment',
          Method: Http.Method.Post
        },
        Success: {
          Path  : 'checkout/success',
          Method: Http.Method.Post
        }
      },

      Cart: {
        Get: {
          Path  : 'cart',
          Method: Http.Method.Get
        },
        Add: {
          Path  : 'cart/add',
          Method: Http.Method.Post
        },
        Remove: {
          Path  : 'cart/remove',
          Method: Http.Method.Post
        },
        Options: {
          Path  : 'cart/options/{storeId}/{productId}/{rowId}',
          Method: Http.Method.Get
        },
        Coupon: {
          Path  : 'cart/coupon',
          Method: Http.Method.Post
        }
      },

      Store: {
        Menu: {
          Path  : 'menu/{storeId}',
          Method: Http.Method.Get
        }
      },

      User: {
        Login: {
          Path  : 'auth/login',
          Method: Http.Method.Post
        },
        Logout: {
          Path  : 'auth/logout',
          Method: Http.Method.Post
        },
        ResetPassword: {
          Path  : 'auth/reset',
          Method: Http.Method.Post
        },
        AuthStatus: {
          Path  : 'auth/status',
          Method: Http.Method.Post
        },
        Register: {
          Path  : 'auth/register',
          Method: Http.Method.Post
        },
        AuthGoogle: {
          Path  : 'auth/google',
          Method: Http.Method.Post
        },
        AuthFacebook: {
          Path  : 'auth/facebook',
          Method: Http.Method.Post
        }
      }
    }
  },

  responseData     : (response) => response?.data?.data || null,
  responseErrors   : (response) => response?.data?.error || null,
  isResponseSuccess: (response) =>
    response?.status === ApiResponseEnum.SUCCESS &&
    response?.data?.status === ApiResponseEnum.SUCCESS &&
    API.responseData(response) && !API.responseErrors(response)
}

export { default as Http } from '@/lib/data/api/Http'
export default API
