import API from '@/api/Api'
import CustomerModel from '@/api/models/customer/CustomerModel'

export default {
  name: 'AuthRestApi',
  data () {
    return {}
  },
  methods: {
    login (username, password) {
      this.isFormSaving = true
      this.isAuthLoading = true
      API.Resource.User.Login(username, password)
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.handleFormSaveResponse(error.response)
        })
        .finally(() => {
          this.isFormSaving = false
          this.isAuthLoading = false
        })
    },

    autoLogin (response, data) {
      this.onLoginSuccess(response, data)
    },

    handleFormSaveResponse (response) {
      const data = API.responseData(response) || {}
      const message = data?.Messages

      if (API.isResponseSuccess(response)) {
        if (message.Success && data.CustomerInfo && data.IsLogged && data.SessionId) {
          this.formSaveSuccess(data)
          this.onLoginSuccess(response, data)
        } else {
          this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
          this.onLoginError(response, data)
        }
      } else {
        this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
        this.onLoginError(response, data)
      }
    },

    onLoginSuccess (response, data) {
      this.authError = false
      this.authorizationToken = data.SessionId
      this.user = new CustomerModel(data.CustomerInfo)

      this.$nextTick(() => {
        this.$bus.$emit('Auth:Login:Success', this.user)

        this.$bus.$emit('app:show-notification', {
          body   : this.$t('Auth.Login.Success'),
          type   : 'success',
          icon   : 'mdi-check-circle',
          timeout: 5000
        })
      })
    },

    onLoginError (response, data) {
      this.$bus.$emit('Auth:Login:Error')
      this.authError = true
    },

    logout () {
      this.isAuthLoading = true

      API.Resource.User.Logout()
        .then(response => {
        })
        .catch(e => {
        })
        .finally(() => {
          this.$dataStore.User = null
          this.isAuthLoading = false
          this.authError = false
          this.authorizationToken = ''
          this.user = null
          this.$bus.$emit('Auth:Logout:Success')

          this.$bus.$emit('app:show-notification', {
            body   : this.$t('Auth.Logout.Success'),
            type   : 'success',
            icon   : 'mdi-check-circle',
            timeout: 5000
          })
        })
    }
  }
}
