import BaseResource from '@/lib/data/resource/BaseResource'

export default class ResetPasswordResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Email = payload.Email ?? null
  }

  /* PROPERTIES */

  /* METHODS */
}
