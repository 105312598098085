<template>
  <v-list-item
    v-if="product"
    :disabled="disabled || product.LoadingShow || product.LoadingRemove"
    class="px-3"
    @click.stop="onProductClick(product)"
  >
    <div
      class="pa-1 mr-2 mt-2 mb-auto"
      style="width: 85px; max-width: 85px; height: 73px;"
    >
      <v-img
        v-if="product.Image"
        :src="product.Image"
        class="rounded"
        height="65"
      />
      <div
        v-else
        class="brand-primary lighten-5 rounded text-center justify-center align-center d-inline-flex"
        style="width: 100%; height: 100%;"
      >
        <img
          v-if="!storeLogo && productStoreLogo"
          :src="productStoreLogo"
          style="width: 45px; height: auto; opacity: 0.20;"
        >
        <img
          v-else
          src="/img/logo/logo-dark.png"
          style="width: 45px; height: auto; opacity: 0.20;"
        >
      </div>

      <v-avatar
        v-if="storeLogo && productStoreLogo"
        size="28"
        style="position: absolute; top: 3px; left: 3px;"
      >
        <img :src="productStoreLogo">
      </v-avatar>
    </div>

    <v-list-item-content>
      <v-list-item-title class="font-weight-medium">
        <product-quantity :product="product" />

        {{ productName }}
      </v-list-item-title>

      <v-list-item-subtitle>{{ cartProductSelectedOptions }}</v-list-item-subtitle>

      <div
        class="mt-auto d-inline-flex"
        style="font-size: 14px;"
      >
        <product-price
          :product="product"
          auto-calculate
          cart
        />

        <!--
        <div
          v-if="product.alcohol_percentage"
          class="d-inline-flex font-weight-light grey--text ml-2"
        >
          ({{ parseFloat(product.alcohol_percentage / 10).toFixed(2) }}% Vol)
        </div>

        <div
          v-if="product.restrictions && product.restrictions.length"
          class="d-inline-flex"
        >
          <template v-for="(restriction, restrictionIdx) in product.restrictions">
            <v-chip
              :key="`restriction-${restrictionIdx}`"
              class="ml-2 orange--text text--lighten-5"
              color="orange"
              label
              x-small
            >
              {{ restriction.age_limit }}+
            </v-chip>
          </template>
        </div>
        -->

        <v-spacer />

        <product-tags
          :tags="product.Tags"
          icon-only
          small
          style="position:absolute; bottom: 13px; right: 16px;"
        />
      </div>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        :disabled="disabled || product.LoadingShow || product.LoadingRemove"
        :loading="product.LoadingRemove"
        class="pa-0"
        color="grey lighten-1"
        plain
        style="min-width: 36px;"
        @click.stop="onRemoveClick(product)"
      >
        <v-icon color="brand-primary">
          mdi-close
        </v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>

import { clone } from '@/lib/utils/helper'
import Translatable from '@/mixins/translatable'
import ProductPrice from '@/theme/default/components/product/ProductPrice.vue'
import ProductQuantity from '@/theme/default/components/product/ProductQuantity.vue'
import ProductListCommon from '@/mixins/product/productListCommon'
import ProductTags from '@/theme/default/components/product/ProductTags.vue'

export default {
  name      : 'CartProductListItem',
  components: {
    ProductTags,
    ProductQuantity,
    ProductPrice
  },
  directives: {},
  mixins    : [Translatable, ProductListCommon],
  props     : {
    product: {
      type   : Object,
      default: undefined
    },

    storeLogo: {
      type   : Boolean,
      default: false
    },

    disabled: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    onProductClick (product) {
      if (!product || this.disabled) return

      this.$bus.$emit('product:details-dialog', clone(product))
    },

    onRemoveClick (product) {
      if (!product || this.disabled) return

      this.$bus.$emit('cart:remove-product', clone(product))
    }
  }
}
</script>

<style scoped>

</style>
