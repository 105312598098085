import { groupBy, sortCompareAlphabetically } from '@/lib/utils/array'
import { clone } from '@/lib/utils/helper'
import { floatNumber } from '@/lib/helpers'

export default {
  name: 'CartCommon',

  dataStore: {
    AppCart    : 'App.Cart',
    AppPayments: 'App.Payments'
  },

  data () {
    return {
      productDetailsDialogEditMode: false
    }
  },

  computed: {
    cartProductsCount () {
      return parseInt(this.AppCart.TotalItems || 0)
    },

    cartTotal () {
      return floatNumber(this.AppCart.CartTotal || 0)
    },

    orderTotal () {
      return floatNumber(this.AppCart.OrderTotal || 0)
    },

    orderTotalAmountValid () {
      return this.orderTotalAmountValidPayment(this.selectedPaymentMethod)
    },
    orderMinTotalAmountDiff () {
      return this.orderMinTotalAmountDiffPayment(this.selectedPaymentMethod)
    },

    selectedPaymentMethod: {
      get () {
        return this.AppCart?.PaymentMethod || null
      },
      set (val) {
        this.AppCart.PaymentMethod = val
        this.model.Payment = val.Code
      }
    },

    cartProducts () {
      return this.AppCart?.Items || []
    },

    cartCoupon () {
      return this.AppCart?.Totals?.find(item => item.name === 'coupon') || null
    },

    cartProductsGroupedByStore () {
      const items = this.cartProducts
      const groups = groupBy(items, item => item.StoreId)
      const retVal = []

      Object.keys(groups).forEach(storeItem => {
        const store = clone(this.getStoreById(storeItem))
        store.Items = groups[storeItem].sort(sortCompareAlphabetically('Name'))
        retVal.push(store)
      })

      retVal.sort(sortCompareAlphabetically('Name'))

      return retVal
    }
  },

  methods: {
    orderTotalAmountValidPayment (payment) {
      return this.orderMinTotalAmountDiffPayment(payment) <= 0
    },

    orderMinTotalAmountDiffPayment (payment) {
      return this.paymentMinOrderTotal(payment) - this.cartTotal
    },

    paymentMinOrderTotal (payment) {
      let retVal = 0
      payment = payment || this.selectedPaymentMethod || this.AppPayments.find(payment => payment.Status) || null
      const paymentMinOrderTotal = floatNumber(payment.MinOrderTotal || 0)
      if (!payment) return retVal

      // if (this.isCollection) retVal = this.minCollectionOrderTotal
      // if (this.isDelivery) retVal = this.minDeliveryOrderTotal
      if (paymentMinOrderTotal > retVal) retVal = paymentMinOrderTotal

      return floatNumber(retVal)
    }
  }
}
