<template>
  <v-dialog
    v-model="isVisible"
    :max-width="config.width"
    :persistent="config.persistent"
    @keydown.esc="closeDialog"
  >
    <data-card
      :color="config.color"
      :colored-border="config.coloredBorder"
      :dark="config.dark"
      :dense="config.dense"
      :icon="config.icon"
      :sub-title="subTitle"
      :title="title"
      tile
    >
      <template #toolbar-append>
        <v-btn
          icon
          @click.native="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <template #default>
        <v-card-text class="pa-4">
          <slot name="default">
            <div v-html="message" />
          </slot>

          <div class="mt-5 caption">
            <span v-html="config.input" />
            <v-text-field
              v-model="inputData"
              v-mask="'######'"
              :color="config.color"
              autofocus
              clearable
              filled
              hide-details
              hide-spin-buttons
              max="6"
              min="6"
              prepend-inner-icon="mdi-lock"
              type="number"
            />
          </div>
        </v-card-text>
      </template>

      <template #card-actions>
        <v-divider />
        <v-card-actions>
          <slot name="card-actions">
            <v-spacer />
            <v-btn
              v-if="config.button.no"
              color="grey"
              outlined
              tile
              @click="closeDialog"
            >
              {{ config.button.no.title }}
            </v-btn>

            <v-btn
              v-if="config.button.yes"
              :color="config.color"
              :dark="isValid ? config.dark : false"
              :disabled="!isValid"
              depressed
              tile
              @click="confirmDialog"
            >
              {{ config.button.yes.title }}
            </v-btn>
          </slot>
        </v-card-actions>
      </template>
    </data-card>
  </v-dialog>
</template>

<script>
import DataCard from './DataCard'
import DialogVisible from '@/mixins/dialog/dialogVisible'

export default {
  name      : 'ConfirmPinDialog',
  components: { DataCard },
  directives: {},
  mixins    : [DialogVisible],
  props     : {
    title: {
      type   : String,
      default: ''
    },
    subTitle: {
      type   : String,
      default: ''
    },
    message: {
      type   : String,
      default: ''
    },
    options: {
      type   : Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      inputData     : '',
      config        : {},
      defaultOptions: {
        icon         : 'mdi-alert',
        input        : '',
        color        : 'error',
        dense        : false,
        width        : 610,
        persistent   : true,
        dark         : false,
        coloredBorder: true,
        button       : {
          no : { title: this.$t('Common.Button.No') },
          yes: { title: this.$t('Common.Button.Yes') }
        }
      }
    }
  },
  computed: {
    isValid () {
      return !!this.inputData && this.inputData.length === 6
    }
  },
  watch: {
    isVisible (newVal) {
      if (newVal) this.initDialog()
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    initDialog () {
      this.inputData = ''
      this.config = { ...this.defaultOptions, ...this.options }
    },

    confirmDialog () {
      this.$emit('dialog:yes', this.inputData)
    }
  }
}
</script>

<style scoped>
</style>
