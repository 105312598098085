import BaseResource from '@/lib/data/resource/BaseResource'

export default class RegisterResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Firstname = payload.Firstname ?? ''
    this.Lastname = payload.Lastname ?? ''
    this.Email = payload.Email ?? ''
    this.Phone = payload.Phone ?? ''
    this.Password = payload.Password ?? ''
    this.PasswordConfirm = payload.PasswordConfirm ?? ''
    this.TermsCondition = payload.TermsCondition ?? null
    this.FromSocialProvider = !!payload.SocialProvider
  }

  /* PROPERTIES */

  /* METHODS */
}
