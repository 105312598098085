import BaseModel from '@/lib/data/model/BaseModel'
import RegisterResource from '@/api/resources/user/RegisterResource'
import RegisterValidator from '@/api/validations/user/RegisterValidator'
import API from '@/api/Api'
import RegisterSaveResource from '@/api/resources/user/RegisterSaveResource'

export default class RegisterModel extends BaseModel {
  constructor (payload = {}, resource = RegisterResource, validator = RegisterValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
  async Register () {
    const payload = this.clone(false, RegisterSaveResource)
    return await API.Resource.User.Register(payload)
  }
}
