<template>
  <v-container
    v-if="AppType.isQRScanApp && AppQrData"
    fluid
  >
    <v-row dense>
      <v-col cols="12">
        <v-alert
          border="left"
          class="mb-0 pr-2"
          colored-border
          dense
          text
          type="info"
        >
          {{ sourceType }} <span class="font-weight-medium">{{ qrValue }}</span>

          <template #prepend>
            <v-icon
              class="mr-2"
              color="info"
              size="28"
            >
              {{ icon }}
            </v-icon>
          </template>

          <template
            v-if="AppConfig.Marketplace.CanChangeQrData || AppConfig.Marketplace.CanClearQrData"
            #append
          >
            <v-btn
              v-if="AppConfig.Marketplace.CanChangeQrData"
              class="ml-1"
              color="info"
              depressed
              small
              @click="showSelectQrItemDialog"
            >
              {{ $t('Common.Button.Change') }}
            </v-btn>

            <v-btn
              v-if="AppConfig.Marketplace.CanClearQrData"
              class="ml-1 px-0"
              color="grey darken-2"
              dark
              depressed
              small
              style="min-width: 28px"
              @click="clearQrData"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name     : 'QrCodeOrderData',
  mixins   : [],
  props    : {},
  dataStore: {
    AppType: 'App.Type'
  },
  data () {
    return {}
  },
  computed: {
    sourceType () {
      return this.$te(`Common.QrSourceType.${this.AppQrData?.type}.Title`) ? this.$t(`Common.QrSourceType.${this.AppQrData.type}.Title`) : ''
    },

    qrValue () {
      return this.AppQrData?.value || ''
    },

    icon () {
      return this.$te(`Common.QrSourceType.${this.AppQrData?.type}.Icon`) ? this.$t(`Common.QrSourceType.${this.AppQrData.type}.Icon`) : 'mdi-information-outline'
    }
  },
  methods: {
    showSelectQrItemDialog () {
      this.$bus.$emit('cart:show-dialog', false)
      this.$bus.$emit('select-table:show-dialog')
    },

    clearQrData () {
      this.$bus.$emit('cart:show-dialog', false)

      this.$sessionStorage.remove('t')
      this.$sessionStorage.remove('d')
      this.$sessionStorage.remove('QRData')
      this.$dataStore.App.Type.QrData = null
      this.$dataStore.App.Type.isQRScanApp = false
    }
  }
}
</script>

<style scoped>

</style>
