<template>
  <div>
    <v-badge
      ref="fly"
      class="text-no-select"
      color="brand-primary lighten-5 brand-primary--text"
      inline
      style="width: 30px; height:30px; pointer-events: none; opacity:0; position: absolute; left: 66px; bottom: 14px;"
    >
      <template #badge>
        <span style="font-size: 16px;">{{ quantityAnim }}</span>
      </template>
    </v-badge>

    <div
      :style="$vuetify.breakpoint.xsOnly ? 'height: 44px;' : 'height: 52px;'"
      class="brand-primary lighten-5 rounded d-inline-flex justify-center align-center pa-2"
      style="height: 52px;"
    >
      <v-btn
        :disabled="vModel === parseInt(min)"
        class="white"
        color="brand-primary"
        icon
        small
        @click="onDecrementClick"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>

      <div
        ref="quantity"
        class="px-2 brand-primary--text text-center text-no-select"
        style="min-width: 66px;"
      >
        <span>{{ quantity }}</span>
      </div>

      <v-btn
        :disabled="vModel === parseInt(max)"
        class="white"
        color="brand-primary"
        icon
        small
        @click="onIncrementClick"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

import VModel from '@/mixins/vModel'
import { formatUnitQuantity } from '@/lib/utils/currency/currency'

export default {
  name      : 'ProductQuantitySelector',
  components: {},
  directives: {},
  mixins    : [VModel],
  props     : {
    min: {
      type   : [Number, String],
      default: 1
    },

    max: {
      type   : [Number, String],
      default: undefined
    },

    step: {
      type   : [Number, String],
      default: 1
    },

    unitId: {
      type   : Number,
      default: 1
    },

    quantityAnimation: {
      type   : Boolean,
      default: false
    },

    flyAnimation: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    quantity () {
      return this.unitId > 1 ? formatUnitQuantity(this.$i18n.locale, this.vModel, this.getMeasurementUnitById(this.unitId)) : this.vModel
    },
    quantityAnim () {
      return this.unitId > 1 ? formatUnitQuantity(this.$i18n.locale, this.vModel, this.getMeasurementUnitById(this.unitId), false) : this.vModel
    }
  },
  watch: {
    min: {
      immediate: true,
      handler (newVal) {
        if (newVal > this.vModel) this.vModel = newVal
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    formatUnitQuantity,
    onDecrementClick () {
      if (this.vModel > parseInt(this.min)) this.vModel -= parseInt(this.step || 1)
      this.triggerQuantityAnimation()
      this.triggerFlyAnimation()
    },

    onIncrementClick () {
      if (!this.max || this.vModel < parseInt(this.max)) this.vModel += parseInt(this.step || 1)
      this.triggerQuantityAnimation()
      this.triggerFlyAnimation()
    },

    triggerFlyAnimation () {
      if (!this.flyAnimation) return

      const el = this.$refs.fly?.$el
      if (!el) return

      el.style.animation = 'none'
      // eslint-disable-next-line no-unused-expressions
      el.offsetHeight /* trigger reflow */
      el.style.animation = null
      el.classList.add('quantityFlyAnimation')
    },

    triggerQuantityAnimation () {
      if (!this.quantityAnimation) return

      const el = this.$refs.quantity
      if (!el) return

      el.style.animation = 'none'
      // eslint-disable-next-line no-unused-expressions
      el.offsetHeight /* trigger reflow */
      el.style.animation = null
      el.classList.add('quantityTextAnimation')
    },

    getMeasurementUnitById (id) {
      if (!id) return null

      return this.$t('Common.Units')?.find(item => item.Id === id) || null
    }
  }
}
</script>

<style scoped>
@keyframes quantityFlyAnimationFrames {
  0% {
    bottom: 14px;
    left: 66px;
    opacity: 0;
  }
  40% {
    bottom: 58px;
    left: 66px;
    opacity: 0.75;
  }
  100% {
    bottom: 58px;
    left: 66px;
    opacity: 1;
  }
}

.quantityFlyAnimation {
  opacity: 0;
  position: absolute;
  left: 66px;
  bottom: 14px;
  animation: quantityFlyAnimationFrames 500ms linear;
}

@keyframes quantityTextAnimationFrames {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.quantityTextAnimation {
  animation: quantityTextAnimationFrames 250ms ease;
}
</style>
