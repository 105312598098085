import BaseModel from '@/lib/data/model/BaseModel'
import CustomerValidator from '@/api/validations/customer/CustomerValidator'
import CustomerResource from '@/api/resources/customer/CustomerResource'

export default class CustomerModel extends BaseModel {
  constructor (payload = {}, resource = CustomerResource, validator = CustomerValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
