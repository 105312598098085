import BaseResource from '@/lib/data/resource/BaseResource'

export default class ContactResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Firstname = payload.Firstname ?? ''
    this.Lastname = payload.Lastname ?? ''
    this.Phone = payload.Phone ?? ''
    this.Email = payload.Email ?? ''
    this.Comments = payload.Comments ?? ''
    this.TermsCondition = payload.TermsCondition ?? null
  }

  /* PROPERTIES */

  /* METHODS */
}
