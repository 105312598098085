import BaseValidator from '@/lib/data/validator/BaseValidator'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class LoginValidator extends BaseValidator {
  validatorRules = {
    Username: {
      required: true,
      type    : ValidatorTypesEnum.Email
    },
    Password: {
      required: true,
      type    : ValidatorTypesEnum.String,
      minLen  : 6
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
