import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class ContactValidator extends BaseValidator {
  validatorRules = {
    Firstname: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 32
    },
    Lastname: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2,
      maxLen  : 32
    },
    Email: {
      required: true,
      type    : BaseValidator.Type.Email,
      maxLen  : 96
    },
    Phone: {
      required  : true,
      type      : BaseValidator.Type.String,
      betweenLen: [10, 14]
    },
    Comments: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 2
    },
    TermsCondition: {
      required: true,
      type    : BaseValidator.Type.Boolean
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
