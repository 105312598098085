<template>
  <v-container
    v-if="user"
    fluid
    pa-0
  >
    <v-row>
      <v-col cols="12">
        <div class="d-flex align-start">
          <div class="d-inline-block">
            <v-avatar
              class="white--text rounded mr-4 mt-1"
              color="brand-primary"
              size="44"
              tile
            >
              {{ userInitials }}
            </v-avatar>
          </div>

          <div class="d-inline-block">
            <div class="text-h5">
              {{ user.Info.Firstname }} {{ user.Info.Lastname }}
            </div>

            <div class="caption">
              {{ $t('Auth.AccountDialog.MemberSince') }} {{
                user.Info.DateAdded
              }}
            </div>
          </div>
        </div>

        <div>
          <div class="mt-4 ml-15">
            <div class="d-inline-flex mr-12">
              <div>
                <div class="subtitle-1 font-weight-bold">
                  Phone
                </div>
                <div>{{ user.Info.Phone }}</div>
              </div>
            </div>

            <div class="d-inline-flex">
              <div>
                <div class="subtitle-1 font-weight-bold">
                  Email
                </div>
                <div>{{ user.Info.Email }}</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'

export default {
  name      : 'AccountPersonalInfo',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  enums     : {},
  dataStore : {},
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
