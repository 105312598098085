import BaseModel from '@/lib/data/model/BaseModel'
import CheckoutPaymentValidator from '@/api/validations/checkout/CheckoutPaymentValidator'
import CheckoutPaymentResource from '@/api/resources/checkout/CheckoutPaymentResource'

export default class CheckoutPaymentModel extends BaseModel {
  constructor (payload = {}, resource = CheckoutPaymentResource, validator = CheckoutPaymentValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
