import OrderTypeEnum from '@/api/enums/OrderTypeEnum'
import SourceTypeEnum from '@/api/enums/SourceTypeEnum'

export const App = {
  get Version () {
    return process.env.VUE_APP_VERSION
  },

  Theme: {
    get Default () {
      return process.env.VUE_APP_DEFAULT_THEME
    },
    Active: process.env.VUE_APP_ACTIVE_THEME
  },

  Type: {
    isQRScanApp      : false,
    isMobileApp      : false,
    isSelfOrderingApp: false,
    QrData           : null
  },

  Config: {
    Marketplace: {
      SelectQrDataRequired: true,
      SelectQrDataPopup   : true,
      CanChangeQrData     : true,
      CanClearQrData      : true,
      SupportsDelivery    : false,
      SupportsCollection  : true,
      DefaultOrderType    : OrderTypeEnum.COLLECTION,
      Currency            : {
        name  : 'EUR',
        locale: 'el-GR'
      },
      ActiveLocales: ['el', 'en'],
      DefaultLocale: 'el'
    },
    Toolbar: {
      AppToolbarExtended: false,
      ShowRegisterButton: false
    },
    Theme: {
      Dark            : false,
      ToggleDarkButton: true
    },
    Cart: {
      GroupByStore        : true,
      GroupShowStoreLogo  : true,
      ProductShowStoreLogo: false,
      CouponsEnabled      : true
    },
    Checkout: {
      SelectedProductsExpanded: false
    },
    StoreClosed: {
      HideStore           : false,
      DisableStore        : false,
      DisableProducts     : false,
      DisableProductDialog: false,
      DisableAddToCart    : true
    },
    Auth: {
      Email    : true,
      Facebook : true,
      Google   : true,
      Providers: {
        facebook: {
          url        : '/auth/facebook',
          redirectUri: 'https://marketplace.dev.getfood.gr/auth/callback',
          clientId   : '1927931864143671'
        },
        google: {
          url        : '/auth/google',
          redirectUri: 'https://marketplace.dev.getfood.gr/auth/callback',
          clientId   : '940670880302-8fv61dhoquurj35j1n9bsot9cmd0cfup.apps.googleusercontent.com'
        }
      }
    }
  },

  Cart: {
    Items        : [],
    Stores       : [],
    Totals       : [],
    Messages     : [],
    CartTotal    : 0,
    OrderTotal   : 0,
    TotalItems   : 0,
    PaymentMethod: null
  },

  Stores: [],

  Payments: [],

  QrItems: [
    {
      type  : 'TBL',
      value : '1',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '2',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '3',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '4',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '5',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '6',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '7',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '8',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '9',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '10',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '11',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '12',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '13',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '14',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '15',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '16',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '17',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '18',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '19',
      source: SourceTypeEnum.ONLINE_TABLE
    },
    {
      type  : 'TBL',
      value : '20',
      source: SourceTypeEnum.ONLINE_TABLE
    }
  ]
}
