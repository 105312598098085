import BaseResource from '@/lib/data/resource/BaseResource'
import { collect } from '@/lib/utils/array'
import CustomerAddressModel from '@/api/models/customer/CustomerAddressModel'
import CustomerInfoModel from '@/api/models/customer/CustomerInfoModel'

export default class CustomerResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Addresses = collect(payload.Addresses ?? [], CustomerAddressModel)
    this.Info = new CustomerInfoModel(payload.Info)
  }

  /* PROPERTIES */

  /* METHODS */
}
