<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="520"
      persistent
      scrollable
    >
      <v-card v-if="selectedProduct">
        <v-toolbar
          v-if="!selectedProduct.Image"
          flat
          height="110"
        >
          <v-toolbar-title>
            <div class="title">
              <strong>{{ getProductName(selectedProduct) }}</strong>
            </div>

            <product-price
              :product="selectedProduct"
              auto-calculate
              class="title font-weight-regular"
            />
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            depressed
            icon
            @click="onCancelButtonClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text
          ref="cardText"
          class="pa-0 fill-height"
        >
          <v-btn
            v-if="selectedProduct.Image && !toolbarVisible"
            absolute
            class="safe-area-top dialog-cancel-button"
            dark
            depressed
            fab
            right
            small
            style="top: 14px;"
            top
            @click="onCancelButtonClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <div
            v-if="selectedProduct.Image"
            :style="`position: absolute; z-index: 3; height: ${toolbarHeight}px; width: 100%;`"
          >
            <v-scroll-y-transition>
              <div v-if="toolbarVisible">
                <v-toolbar
                  :height="toolbarHeight"
                  :style="`min-height: ${toolbarHeight}px; height: ${toolbarHeight}px; width: 100%;`"
                  class="py-2"
                  flat
                  prominent
                >
                  <div
                    v-if="selectedProduct.Image && $vuetify.breakpoint.smAndUp"
                    style="width: 150px; height: 100px;"
                  >
                    <v-lazy min-height="100">
                      <img
                        :src="selectedProduct.Image"
                        style="width: 150px; height: 100px; border-radius: 5px; object-fit: cover;"
                      >
                    </v-lazy>
                  </div>

                  <v-toolbar-title :class="$vuetify.breakpoint.smAndUp ? 'ml-4 mb-7' : 'ml-0 mb-2'">
                    <div class="title">
                      <strong>{{ getProductName(selectedProduct) }}</strong>
                    </div>

                    <product-price
                      :product="selectedProduct"
                      auto-calculate
                      class="title font-weight-regular"
                    />
                  </v-toolbar-title>

                  <v-spacer />

                  <v-btn
                    depressed
                    icon
                    @click="onCancelButtonClick"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-divider />
              </div>
            </v-scroll-y-transition>
          </div>

          <v-img
            v-if="selectedProduct.Image"
            v-intersect.quiet="{
              handler: visibilityChanged,
              options: {
                threshold: 0.32
              }
            }"
            :src="selectedProduct.Image"
            max-height="350"
          >
            <v-container
              align-end
              fill-height
              fluid
            >
              <v-row>
                <v-col cols="12">
                  <div
                    class="pa-2 d-inline-block align-end justify-end"
                    style="background-color: rgba(255,255,255,0.75); border-radius: 5px;"
                  >
                    <div class="title black--text">
                      <strong>{{ getProductName(selectedProduct) }}</strong>
                    </div>

                    <product-price
                      :product="selectedProduct"
                      auto-calculate
                      class="title font-weight-regular pt-2"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-img>

          <div class="ma-2">
            <div
              v-if="selectedProduct.Description"
              class="body-1 mt-4 mx-1"
              v-html="getProductDescription(selectedProduct)"
            />

            <v-divider
              v-if="selectedProduct.Description"
              class="my-4"
            />

            <v-container
              fluid
              pa-1
            >
              <template v-if="selectedProduct.Options && selectedProduct.Options.length">
                <template v-for="option in selectedProduct.Options">
                  <v-row :key="`product-option-${option.Id}-title`">
                    <v-col cols="12">
                      <template v-if="option.DisplayType === 'separator'">
                        <div
                          v-if="option.DisplayName"
                          class="separator-title"
                        >
                          <h3 class="d-inline-block">
                            {{ getTranslatedField(option) }}
                          </h3>
                        </div>
                        <v-divider v-else />
                      </template>

                      <h3 v-else>
                        {{ getTranslatedField(option) }}
                      </h3>

                      <v-alert
                        v-if="option.Error"
                        class="mb-0 mt-1 pa-1 caption"
                        color="red"
                        dense
                        text
                        type="warning"
                      >
                        {{ option.Error.Message }}
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-row
                    :key="`product-option-${option.Id}`"
                    dense
                  >
                    <template
                      v-if="option.DisplayType === 'radio' || option.DisplayType === 'unit_value' || option.DisplayType === 'unit'"
                    >
                      <v-col cols="12">
                        <v-radio-group
                          v-model="option.DefaultValueId"
                          :mandatory="option.Required"
                          class="ma-0 pa-0"
                          dense
                          hide-details
                        >
                          <template v-for="(value, idx) in option.OptionValues">
                            <v-radio
                              v-show="value.Visible !== false"
                              :key="`product-option-${option.Id}-${idx}-value-${value.Id}`"
                              :label="getTranslatedField(value)"
                              :value="value.Id"
                              color="brand-primary"
                              @click="onRadioClick(option, value)"
                            >
                              <template #label>
                                <v-label
                                  :class="[{'font-weight-bold': option.DefaultValueId === value.Id}]"
                                  class="text-capitalize v-label"
                                  style="left: 0px; right: auto; position: relative; cursor: pointer; height: auto;"
                                >
                                  {{ getTranslatedField(value) }}
                                </v-label>

                                <div
                                  v-if="value.ShowPrice && option.DisplayType !== 'unit'"
                                  :class="[{'font-weight-bold': option.DefaultValueId === value.Id}, parseFloat(value.Price) !== 0 ? (value.Mandatory && value.Selected || value.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                  class="grey--text grow text-right"
                                >
                                  +{{ value.Price | currency }}
                                </div>
                              </template>
                            </v-radio>
                          </template>
                        </v-radio-group>
                      </v-col>
                    </template>

                    <template v-if="option.DisplayType === 'checkbox'">
                      <template v-for="(value, idx) in option.OptionValues">
                        <v-col
                          v-show="value.Visible !== false"
                          :key="`product-option-${option.Id}-${idx}-value-${value.Id}`"
                          cols="12"
                        >
                          <v-checkbox
                            v-model="value.Selected"
                            :disabled="(value.Mandatory && value.Selected) || value.Disabled"
                            :required="value.Mandatory"
                            :value="value.Selected"
                            class="mt-0 pt-0"
                            color="brand-primary"
                            dense
                            hide-details
                            @click="onCheckBoxClick(value)"
                          >
                            <template #label>
                              <v-label
                                :class="{'font-weight-bold': value.Selected}"
                                class="text-capitalize v-label"
                                style="left: 0px; right: auto; position: relative; cursor: pointer; height: auto;"
                                v-html="getTranslatedField(value)"
                              />

                              <div
                                v-if="value.ShowPrice"
                                :class="[{'font-weight-bold': value.Selected}, parseFloat(value.Price) !== 0 ? (value.Mandatory && value.Selected || value.Disabled) ? 'grey--text text--lighten-1' : 'grey--text text--darken-1' : 'grey--text text--lighten-1']"
                                class="grey--text grow text-right"
                              >
                                +{{ value.Price | currency }}
                              </div>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </template>
                    </template>
                  </v-row>
                </template>
              </template>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="selectedProduct.Comment"
                    :label="$t('Common.Button.Comments')"
                    auto-grow
                    color="brand-primary"
                    filled
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions
          v-if="storeIsOpenedById(product.StoreId) || (!AppConfig.StoreClosed.DisableAddToCart && storeIsOpenedById(product.StoreId))"
          class="pa-3"
        >
          <product-quantity-selector
            v-model="productQuantity"
            :max="productMaxQuantity"
            :min="productMinQuantity"
            :step="productQuantityStep"
            :unit-id="selectedProduct.UnitId"
            fly-animation
            quantity-animation
          />

          <v-spacer />

          <v-btn
            :large="$vuetify.breakpoint.xsOnly"
            :x-large="$vuetify.breakpoint.smAndUp"
            class="text-capitalize px-3"
            color="brand-primary"
            dark
            depressed
            @click="onSaveButtonClick"
          >
            {{ editMode ? $t('Cart.Button.UpdateOrder') : $t('Cart.Button.AddToOrder') }}

            <v-spacer />

            <span
              class="ml-4 text-end"
              style="min-width: 75px;"
            >{{ productTotal | currency }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import ProductQuantitySelector from '@/theme/default/components/product/ProductQuantitySelector.vue'
import DialogVisible from '@/mixins/dialog/dialogVisible'
import Translatable from '@/mixins/translatable'
import ProductPrice from '@/theme/default/components/product/ProductPrice.vue'
import ProductCommon from '@/mixins/product/productCommon'
import ProductListCommon from '@/mixins/product/productListCommon'
import { clone } from '@/lib/utils/helper'

export default {
  name      : 'ProductDetailsDialog',
  components: {
    ProductPrice,
    ProductQuantitySelector
  },
  mixins: [DialogVisible, Translatable, ProductCommon, ProductListCommon],
  props : {
    product: {
      type   : Object,
      default: undefined
    },
    editMode: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  data () {
    return {
      toolbarVisible: false
    }
  },
  computed: {
    toolbarHeight () {
      if (this.$vuetify.breakpoint.smAndUp) return 125
      return 84
    }
  },
  watch: {
    isVisible (newVal) {
      if (!newVal && this.$refs.cardText) this.$refs.cardText.scrollTop = 0

      if (newVal) {
        this.selectedProduct = clone(this.product)
        this.initProduct()
      }
    }
  },
  methods: {
    visibilityChanged (entries, observer, isIntersecting) {
      this.toolbarVisible = !isIntersecting
    },

    onSaveButtonClick () {
      if (!this.isRequiredOptionsValid()) return

      this.$bus.$emit('cart:add-product', this.selectedProduct)
      this.onCancelButtonClick()
    },

    onCancelButtonClick () {
      this.isVisible = false
      this.toolbarVisible = false
      this.selectedProduct = null
    }
  }
}
</script>

<style scoped>
.separator-title {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}

.separator-title:before, .separator-title:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 48%;
  height: 1px;
  content: '\a0';
  background-color: #cccccc;
  margin-left: 2%;
}

.separator-title:before {
  margin-left: -50%;
  text-align: right;
}
</style>
