import { getUrlParameter } from '@/lib/utils/browser'
import AppTypeEnum from '@/api/enums/AppTypeEnum'

export default (route, injection = window.Vue) => {
  const vue = injection
  initAppType(route, vue)
}

const initAppType = (to, vue) => {
  const url = new URL(window.location)
  const acceptedAppType = Object.values(AppTypeEnum)
  let appType = ''

  if (url?.searchParams?.has('t')) {
    appType = String(url.searchParams.get('t') || '').trim().toLowerCase()
  } else if (vue.$sessionStorage.get('t')) {
    appType = String(vue.$sessionStorage.get('t', '')).trim().toLowerCase()
  }

  if (appType && acceptedAppType.includes(appType.toLowerCase())) {
    vue.$sessionStorage.set('t', appType)

    if (appType === AppTypeEnum.QR_SCAN_APP) {
      vue.$dataStore.App.Type.isQRScanApp = true
      handleQrData(to, vue)
    } else if (appType === AppTypeEnum.MOBILE_APP) {
      vue.$dataStore.App.Type.isMobileApp = true
    } else if (appType === AppTypeEnum.SELF_ORDERING_APP) {
      vue.$dataStore.App.Type.isSelfOrderingApp = true
    }

    if (url && url.searchParams) {
      url.searchParams.delete('t')
      url.searchParams.delete('d')
      history.replaceState(null, null, url)
    }
  } else {
    vue.$sessionStorage.remove('t')
    vue.$sessionStorage.remove('d')
    vue.$sessionStorage.remove('QRData')
    vue.$dataStore.App.Type.QrData = null
  }
}

const handleQrData = (to, vue) => {
  const url = new URL(window.location)
  const qrSourceType = vue.$t('Common.QrSourceType')
  const barcodeRegex = `(GFP)(${Object.keys(qrSourceType).join('|')})([A-Za-zΑ-Ωα-ωίϊΐόάέύϋΰήώΊΪΌΆΈΎΫΉΏ0-9 -/:]*)(GFP)`
  const data = String(getUrlParameter('d', url) || url.searchParams.get('d') || vue.$sessionStorage.get('d', '')).trim().toUpperCase()
  const isValid = new RegExp(barcodeRegex, 'gi').test(data)
  if (!isValid) return

  const regexResult = new RegExp(barcodeRegex, 'gi').exec(data)
  const type = regexResult.length === 5 && regexResult[2]
  const value = regexResult.length === 5 && regexResult[3]
  if (!type || !vue.$t('Common.QrSourceType').hasOwnProperty(type) || !value) return

  vue.$sessionStorage.set('d', data)
  const payload = {
    type  : type,
    value : value,
    source: vue.$t(`Common.QrSourceType.${type}.Id`)
  }
  vue.$sessionStorage.set('QRData', payload)
  vue.$dataStore.App.Type.QrData = payload

  if (url && url.searchParams) {
    url.searchParams.delete('t')
    url.searchParams.delete('d')
    history.replaceState(null, null, url)
  }

  // eslint-disable-next-line no-console
  console.log('QR RESULT ::', vue.$t(`Common.QrSourceType.${type}.Title`), `(${type}) :: ${value}`)
}
