<template>
  <v-container
    ref="container"
    v-scroll="onScroll"
    fluid
  >
    <template v-if="isLoading && !StoreMenu">
      <template v-for="(category) in 2">
        <v-row :key="`category-${category}`">
          <v-col cols="12">
            <v-skeleton-loader
              class="py-4"
              max-width="900"
              type="heading"
            />
          </v-col>

          <v-col cols="12">
            <v-container
              fluid
              ma-0
              pa-0
            >
              <v-row>
                <template v-for="(product) in 5">
                  <v-col
                    :key="`category-${category}-product-${product}`"
                    cols="12"
                    lg="4"
                    md="6"
                  >
                    <div>
                      <v-skeleton-loader
                        :types="{'v-skeleton-product-list-item': 'article, avatar'}"
                        class="elevation-2"
                        type="v-skeleton-product-list-item"
                      />
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </template>
    </template>

    <template v-else>
      <template v-for="(item, catIdx) in storeMenuItems">
        <v-row
          v-if="item.Category.Status && item.Items.length > 0"
          :id="`tab-${getTranslatedField(item.Category, 'Slug')}`"
          :key="`category-${item.Category.Id}-${catIdx}`"
        >
          <v-col cols="12">
            <h2>{{ toLocaleUpperCase(getTranslatedField(item.Category)) }}</h2>
          </v-col>

          <v-col cols="12">
            <v-container
              fluid
              ma-0
              pa-0
            >
              <v-row>
                <template v-for="(product, prodIdx) in item.Items">
                  <v-col
                    v-if="product.Active"
                    :key="`category-${item.Category.Id}-${catIdx}-product-${product.Id}-${prodIdx}`"
                    cols="12"
                    lg="4"
                    md="6"
                  >
                    <catalog-product-list-item
                      :disabled="AppConfig.StoreClosed.DisableProducts && storeIsClosedById(id)"
                      :product="product"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </template>
    </template>

    <v-row>
      <v-col
        class="py-10"
        cols="12"
      />
    </v-row>

    <select-store-dialog
      :stores="AppStores"
      :visible.sync="storeDialogVisible"
      @store:selected="onStoreSelected"
    />
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import API from '@/api/Api'
import SelectStoreDialog from '@/theme/default/views/store/SelectStoreDialog'
import CatalogProductListItem from '@/theme/default/views/store/CatalogProductListItem'
import { toLocaleUpperCase } from '@/lib/helpers'
import Translatable from '@/mixins/translatable'
import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name      : 'Store',
  components: {
    CatalogProductListItem,
    SelectStoreDialog
  },
  directives: {},
  mixins    : [i18nRouteMeta, Translatable, StoreCommon],
  props     : {},
  enums     : {},
  dataStore : {
    AppCart  : 'App.Cart',
    AppStores: 'App.Stores',
    StoreMenu: 'Store.Menu',
    StoreInfo: 'Store.Info'
  },
  data () {
    return {
      title             : '',
      description       : '',
      store             : null,
      slug              : null,
      id                : null,
      isLoading         : false,
      storeDialogVisible: false
    }
  },
  computed: {
    storeMenuItems () {
      return this.StoreMenu?.items || []
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.$bus.$emit('cart:get-data')

    this.$bus.$on('show:select-store', this.showSelectStoreDialog)
    this.$bus.$on('store:selected', this.onStoreSelected)
    this.$bus.$on('store:init-menu', this.initView)
    this.$bus.$on('store:get-menu', this.getData)

    this.initView()
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('show:select-store', this.showSelectStoreDialog)
    this.$bus.$off('store:selected', this.onStoreSelected)
    this.$bus.$off('store:init-menu', this.initView)
    this.$bus.$off('store:get-menu', this.getData)
  },
  destroyed () {
    this.StoreInfo = null
    this.StoreMenu = null
  },
  methods: {
    toLocaleUpperCase,
    initView () {
      this.StoreInfo = null
      this.StoreMenu = null

      this.slug = this.$route.params?.slug
      this.id = this.$route.params?.id

      this.StoreInfo = this.AppStores?.find(store => parseInt(store.Id) === parseInt(this.id))
      this.title = this.StoreInfo.Name
      this.description = this.StoreInfo.Desc

      this.getData()
    },

    getData () {
      if (!this.id) return

      this.isLoading = true

      API.Resource.Store.Menu(this.id)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.StoreMenu = API.responseData(response)

            const storeId = API.responseData(response)?.store?.StoreId
            const store = this.getStoreById(storeId)
            if (store) store.Status = API.responseData(response)?.store
          } else {
            this.StoreMenu = null
          }
        })
        .catch(e => {
          this.StoreMenu = null
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showSelectStoreDialog () {
      this.storeDialogVisible = true
    },

    onStoreSelected (store) {
      if (!store || this.$route.params.id === store.Id) return

      this.$router.push({
        name  : 'Store',
        params: {
          slug: store.Slug,
          id  : store.Id
        }
      })

      this.initView()
    },

    onScroll () {
      const offsetTop = 144

      const elements = [...this.$refs.container.children]
      elements.forEach(el => {
        const elementRect = el.getBoundingClientRect()
        if (elementRect.top < offsetTop) {
          this.$bus.$emit('category:select', el.id)
        }
      })
    }

  }
}
</script>

<style scoped>
/deep/ .v-skeleton-loader__v-skeleton-product-list-item {
  align-content: center;
  align-items: center;
  display: flex;
  padding: 0 16px;
}

/deep/ .v-skeleton-loader__article {
  min-width: 120px;
  width: 100%;
}

/deep/ .v-skeleton-loader__article .v-skeleton-loader__heading {
  margin: 16px 0 16px 0;
}

/deep/ .v-skeleton-loader__article .v-skeleton-loader__paragraph {
  padding: 16px 0 16px 0;
}

/deep/ .v-skeleton-loader__v-skeleton-product-list-item .v-skeleton-loader__avatar {
  margin-left: 16px;
  border-radius: 6px;
  height: 115px;
  min-width: 167px;
  width: 167px;
  flex-wrap: nowrap;
  text-wrap: nowrap;
}
</style>
