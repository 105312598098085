export default {
  Home: {
    Title: 'Αρχική',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Home.Title',
      Desc : '',
      Img  : ''
    }
  },

  Store: {
    Title: '@:Store.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Store.Title',
      Desc : '',
      Img  : ''
    }
  },

  CheckoutDetails: {
    Title: '@:Checkout.Details.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.CheckoutDetails.Title',
      Desc : '',
      Img  : ''
    }
  },

  CheckoutSuccess: {
    Title: '@:Checkout.Success.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.CheckoutSuccess.Title',
      Desc : '',
      Img  : ''
    }
  },

  CheckoutFailure: {
    Title: '@:Checkout.Failure.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.CheckoutFailure.Title',
      Desc : '',
      Img  : ''
    }
  },

  OrderStatus: {
    Title: '@:Checkout.Success.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.OrderStatus.Title',
      Desc : '',
      Img  : ''
    }
  },

  MarketplaceAccountConnected: {
    Title: 'Επιτυχής Σύνδεση',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.MarketplaceAccountConnected.Title',
      Desc : '',
      Img  : ''
    }
  },

  AccountPersonalInfo: {
    Title: '@:Account.PersonalInfo.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.AccountPersonalInfo.Title',
      Desc : '',
      Img  : ''
    }
  },

  AccountAddresses: {
    Title: '@:Account.Addresses.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.AccountAddresses.Title',
      Desc : '',
      Img  : ''
    }
  },

  AccountOrderHistory: {
    Title: '@:Account.OrderHistory.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.AccountOrderHistory.Title',
      Desc : '',
      Img  : ''
    }
  },

  AccountSettings: {
    Title: '@:Account.Settings.Title',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.AccountSettings.Title',
      Desc : '',
      Img  : ''
    }
  }
}
