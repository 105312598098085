import BaseValidator from '@/lib/data/validator/BaseValidator'
import ValidatorTypesEnum from '@/lib/data/validator/ValidatorTypesEnum'

export default class ResetPasswordValidator extends BaseValidator {
  validatorRules = {
    Email: {
      required: true,
      type    : ValidatorTypesEnum.Email
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
