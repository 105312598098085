import { render, staticRenderFns } from "./RecommendationCard.vue?vue&type=template&id=17ee3b94&scoped=true"
import script from "./RecommendationCard.vue?vue&type=script&lang=js"
export * from "./RecommendationCard.vue?vue&type=script&lang=js"
import style0 from "./RecommendationCard.vue?vue&type=style&index=0&id=17ee3b94&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ee3b94",
  null
  
)

export default component.exports