<template>
  <v-container
    fluid
    pa-0
  >
    <!-- Payment Form -->

    <!-- Complete Order -->
    <v-row>
      <v-col cols="12">
        <v-btn
          :disabled="disabled || loading"
          :large="$vuetify.breakpoint.xsOnly"
          :loading="loading"
          :x-large="$vuetify.breakpoint.smAndUp"
          block
          class="text-capitalize pl-2 pr-3 white--text"
          color="brand-primary"
          depressed
          @click="completeOrder"
        >
          <v-badge
            v-if="cartProductsCount"
            class="mr-2"
            color="white"
            inline
          >
            <template #badge>
              <span class="brand-primary--text">{{ cartProductsCount }}</span>
            </template>
          </v-badge>

          {{ $t('Checkout.Button.CompleteOrder') }}

          <v-spacer />

          <span class="ml-4">{{ orderTotal | currency }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import CartCommon from '@/mixins/cart/cartCommon'
import PaymentGatewayCommon from '@/mixins/payment/paymentGatewayCommon'
import API from '@/api/Api'

export default {
  name      : 'GenericPaymentGateway',
  components: {},
  directives: {},
  mixins    : [CartCommon, PaymentGatewayCommon],
  props     : {},
  enums     : {},
  dataStore : {
    AppCart: 'App.Cart'
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    requestData () {
      this.submitData()
    },

    onRequestSuccess (response) {
      const data = API.responseData(response)
      if (!data?.OrderId) {
        this.loading = false
        return
      }

      this.requestUpdateCart(response)
      this.requestSuccessNotify(response)
      this.parseRequestMessages(response)
      this.$router.replace({
        name  : 'CheckoutSuccess',
        params: { orderId: data.OrderId }
      })
    }
  }
}
</script>

<style scoped>

</style>
