export default {
  Details: {
    Title: 'Ολοκλήρωση Παραγγελίας',

    OrderDetails: {
      Title: 'Επιλεγμένα Προϊόντα'
    },

    CustomerDetails: {
      Title       : 'Στοιχεία Πελάτη',
      Notification: {
        Login          : 'Έχετε ήδη λογαριασμό;',
        Logout         : 'Καλώς ήρθατε πίσω {user}. Δεν είστε εσείς;',
        NoAccount      : 'Όχι, δεν έχω λογαριασμό. Κάντε',
        ContinueAsGuest: 'ή συνεχίστε ως επισκέπτης.'
      },
      Field: {
        Firstname: {
          Label      : 'Όνομα',
          Placeholder: '',
          Hint       : ''
        },
        Lastname: {
          Label      : 'Επώνυμο',
          Placeholder: '',
          Hint       : ''
        },
        Email: {
          Label      : 'Email',
          Placeholder: '',
          Hint       : ''
        },
        Phone: {
          Label      : 'Κινητό',
          Placeholder: '',
          Hint       : ''
        }
      }
    },

    OrderComments: {
      Title: 'Σχόλια Παραγγελίας',
      Field: {
        Comments: {
          Label      : 'Σχόλια Παραγγελίας',
          Placeholder: '',
          Hint       : ''
        }
      }
    },

    PaymentDetails: {
      Title: 'Πληρωμή Παραγγελίας'
    }
  },

  Success: {
    Title: 'Κατάσταση Παραγγελίας',

    Status: {
      Title: 'Κατάσταση Παραγγελίας',
      Body : {
        Stay   : 'Παραμείνετε στη σελίδα και παρακολουθήστε την πορεία της παραγγελίας. Όταν θα είναι έτοιμη ( κατάσταση',
        Receive: ') θα μπορείτε να την παραλάβετε από το κατάστημα με τον κωδικό παραγγελίας',
        Change : '. Θέλετε να αλλάξετε κάτι; Επικοινωνήστε απευθείας με το κατάστημα.'
      }
    },

    BrandSlider: {
      Title: 'Αγαπημένα Brands ✨'
    },

    Stores: {
      Title : 'Καταστήματα',
      Status: {
        Time: 'Εκτιμώμενος Χρόνος'
      }
    },

    NotFound: {
      Title   : 'Η παραγγελία δεν βρέθηκε!',
      Subtitle: 'Ανακαλύψτε απίθανα καταστήματα, προσθέστε προϊόντα και δημιουργήστε μια παραγγελία!'
    }
  },

  Failure: {
    Title: 'Αποτυχία Παραγγελίας',

    Status: {
      Title: 'Αποτυχία Παραγγελίας',
      Body : 'Δυστυχώς η παραγγελία απέτυχε και δεν εκτελέστηκε. Δοκιμάστε ξανά αργότερα.'
    },

    BrandSlider: {
      Title: 'Αγαπημένα Brands ✨'
    },

    Stores: {
      Title : 'Καταστήματα',
      Status: {
        Time: 'Εκτιμώμενος Χρόνος'
      }
    },

    NotFound: {
      Title   : 'Η παραγγελία δεν βρέθηκε!',
      Subtitle: 'Ανακαλύψτε απίθανα καταστήματα, προσθέστε προϊόντα και δημιουργήστε μια παραγγελία!'
    }
  },

  Button: {
    CompleteOrder: 'Ολοκλήρωση Παραγγελίας'
  }
}
