import { API } from '@/api/Api'

export default {
  async Details (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Account.Details
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Orders (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Account.Orders
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Delete (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Account.Delete
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Export (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Account.Export
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  },

  async Pin (data = {}, config = {}) {
    const Endpoint = API.Endpoint.Account.Pin
    const Data = data
    const Config = { ...config }
    return await API.RESTRequest(Endpoint, Data, Config)
  }
}
