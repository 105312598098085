<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row class="fill-height align-center justify-center text-center">
      <v-col
        cols="12"
        md="8"
        sm="10"
      >
        <div class="text-center">
          <div class="mb-6">
            <v-icon
              color="brand-primary"
              size="256"
            >
              mdi-account-check
            </v-icon>
          </div>

          <h1>{{ $t('Common.Marketplace.Account.Connected.Title') }}</h1>

          <h4 class="font-weight-regular mt-4">
            {{ $t('Common.Marketplace.Account.Connected.Subtitle') }}
          </h4>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'

export default {
  name      : 'MarketplaceAccountConnected',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : [],
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>

</style>
