<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        offset-sm="1"
        sm="10"
      >
        <v-container
          fluid
          pa-0
        >
          <v-row>
            <v-col cols="12" />
          </v-row>

          <v-row>
            <v-col cols="12">
              <h1 class="font-weight-black">
                {{ $t('Account.Title') }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-tabs
                show-arrows
              >
                <v-tabs-slider color="brand-primary" />

                <template v-for="(item, i) in items">
                  <v-tab
                    v-if="item.active"
                    :key="i"
                    :to="{name: item.to}"
                    class="text-none title font-weight-regular"
                  >
                    {{ item.text }}
                  </v-tab>
                </template>
              </v-tabs>

              <v-divider />
            </v-col>
          </v-row>

          <v-row v-if="user">
            <v-col cols="12">
              <router-view :item="clone(user.Info)" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import { toLocaleTitleCase } from '@/lib/helpers'
import { clone } from '@/lib/utils/helper'

export default {
  name      : 'Account',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  enums     : {},
  dataStore : {},
  data () {
    return {
      items: [
        {
          text  : toLocaleTitleCase(this.$t('Account.PersonalInfo.Title')),
          to    : 'AccountPersonalInfo',
          active: true
        },
        {
          text  : toLocaleTitleCase(this.$t('Account.Addresses.Title')),
          to    : 'AccountAddresses',
          active: false
        },
        {
          text  : toLocaleTitleCase(this.$t('Account.OrderHistory.Title')),
          to    : 'AccountOrderHistory',
          active: true
        },
        {
          text  : toLocaleTitleCase(this.$t('Account.Settings.Title')),
          to    : 'AccountSettings',
          active: true
        }
      ]
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: { clone }
}
</script>

<style scoped>

</style>
