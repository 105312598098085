export default {
  Title: 'Stores | Store | Stores',

  SelectStoreDialog: {
    Title   : 'Stores',
    Subtitle: 'Select store'
  },

  Status: {
    Opened: 'Open',
    Closed: 'Closed'
  },

  OrderTime: {
    Suffix  : '@:Store.OrderTime.SuffixMD',
    SuffixXL: 'minutes',
    SuffixMD: 'mins.',
    SuffixXS: '"'
  }
}
