export default {
  Title: 'Προφίλ',

  PersonalInfo: {
    Title: 'Προσωπικές πληροφορίες'
  },
  Addresses: {
    Title      : 'Διευθύνσεις',
    NoAddresses: {
      Title   : 'Δεν έχετε προσθέσει ακόμη διευθύνσεις',
      Subtitle: 'Θα βρείτε όλες τις διευθύνσεις σας εδώ'
    }
  },
  OrderHistory: {
    Title   : 'Ιστορικό παραγγελιών',
    NoOrders: {
      Title   : 'Δεν έχετε κάνει ακόμη παραγγελίες',
      Subtitle: 'Θα βρείτε όλες τις λεπτομέρειες των παραγγελιών σας εδώ'
    }
  },
  Settings: {
    Title: 'Ρυθμίσεις',

    Profile      : 'Προφίλ',
    ChangePass   : 'Αλλαγή Κωδικού',
    Notification : 'Ενημερώσεις',
    Account      : 'Λογαριασμός',
    DeleteAccount: 'Οριστική Διαγραφή Λογαριασμού',
    ExportAccount: 'Εξαγωγή Προσωπικών Δεδομένων',

    Field: {
      Firstname: {
        Label      : 'Όνομα',
        Placeholder: 'Όνομα',
        Hint       : ''
      },
      Lastname: {
        Label      : 'Επώνυμο',
        Placeholder: 'Επώνυμο',
        Hint       : ''
      },
      Email: {
        Label      : 'Email',
        Placeholder: 'Email',
        Hint       : ''
      },
      Phone: {
        Label      : 'Κινητό',
        Placeholder: 'Κινητό',
        Hint       : ''
      },
      Password: {
        Label      : 'Κωδικός',
        Placeholder: 'Κωδικός',
        Hint       : ''
      },
      NewPass: {
        Label      : 'Νέος Κωδικός',
        Placeholder: 'Νέος Κωδικός',
        Hint       : ''
      },
      NewPassConfirm: {
        Label      : 'Επιβεβαίωση Κωδικού',
        Placeholder: 'Επιβεβαίωση Κωδικού',
        Hint       : ''
      },
      Newsletter: {
        Label      : 'Newsletter',
        Placeholder: 'Newsletter',
        Hint       : ''
      },
      Push: {
        Label      : 'Push Notifications',
        Placeholder: 'Push Notifications',
        Hint       : ''
      },
      Sms: {
        Label      : 'SMS',
        Placeholder: 'SMS',
        Hint       : ''
      }
    }
  }
}
