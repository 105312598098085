<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h2>{{ pageTitle }}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="pageContent"
        class="text-justify"
        cols="12"
        v-html="pageContent"
      />

      <v-col cols="12">
        <v-form
          v-if="model"
          ref="form"
          v-model="isFormValid"
        >
          <v-container
            fluid
            pa-0
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="model.Firstname"
                  :disabled="userIsAuthenticated"
                  :error-messages="serverErrorMessages['Firstname']"
                  :hint="$t('Page.PageContact.Field.Firstname.Hint')"
                  :label="$t('Page.PageContact.Field.Firstname.Label')"
                  :placeholder="$t('Page.PageContact.Field.Firstname.Placeholder')"
                  :rules="model.validator.vuetifyFormFieldRules('Firstname')"
                  autocomplete="given-name"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  min="2"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="model.Lastname"
                  :disabled="userIsAuthenticated"
                  :error-messages="serverErrorMessages['Lastname']"
                  :hint="$t('Page.PageContact.Field.Lastname.Hint')"
                  :label="$t('Page.PageContact.Field.Lastname.Label')"
                  :placeholder="$t('Page.PageContact.Field.Lastname.Placeholder')"
                  :rules="model.validator.vuetifyFormFieldRules('Lastname')"
                  autocomplete="family-name"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  min="2"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="model.Email"
                  :disabled="userIsAuthenticated"
                  :error-messages="serverErrorMessages['Email']"
                  :hint="$t('Page.PageContact.Field.Email.Hint')"
                  :label="$t('Page.PageContact.Field.Email.Label')"
                  :placeholder="$t('Page.PageContact.Field.Email.Placeholder')"
                  :rules="model.validator.vuetifyFormFieldRules('Email')"
                  autocomplete="email"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  type="email"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="model.Phone"
                  v-mask="'##############'"
                  :disabled="userIsAuthenticated"
                  :error-messages="serverErrorMessages['Phone']"
                  :hint="$t('Page.PageContact.Field.Phone.Hint')"
                  :label="$t('Page.PageContact.Field.Phone.Label')"
                  :placeholder="$t('Page.PageContact.Field.Phone.Placeholder')"
                  :rules="model.validator.vuetifyFormFieldRules('Phone')"
                  autocomplete="mobile phone"
                  clearable
                  color="brand-primary"
                  filled
                  flat
                  hide-details="auto"
                  type="tel"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="model.Comments"
                  :error-messages="serverErrorMessages['Comments']"
                  :hint="$t('Page.PageContact.Field.Comments.Hint')"
                  :label="$t('Page.PageContact.Field.Comments.Label')"
                  :placeholder="$t('Page.PageContact.Field.Comments.Placeholder')"
                  :rules="model.validator.vuetifyFormFieldRules('Comments')"
                  auto-grow
                  color="brand-primary"
                  filled
                  hide-details="auto"
                  rows="5"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="model.TermsCondition"
                  :error-messages="serverErrorMessages['TermsCondition']"
                  :rules="model.validator.vuetifyFormFieldRules('TermsCondition')"
                  :value="true"
                  class="mt-0"
                  name="TermsCondition"
                  required
                >
                  <template #label>
                    <div>
                      {{ $t('Auth.RegisterDialog.Field.TermsCondition.Label') }}
                      <a
                        :href="`/${$i18n.locale}/page/policies/terms`"
                        target="_blank"
                        @click.stop
                      >
                        {{
                          $t('Auth.RegisterDialog.Field.TermsCondition.Link')
                        }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  :disabled="!isFormValid || isFormSaving"
                  :large="$vuetify.breakpoint.xsOnly"
                  :loading="isFormSaving"
                  :x-large="$vuetify.breakpoint.smAndUp"
                  block
                  class="text-capitalize white--text"
                  color="brand-primary"
                  depressed
                  @click="onContactClick"
                >
                  {{ $t('Common.Button.Send') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import FormDataCommon from '@/mixins/form/formDataCommon'
import ContactModel from '@/api/models/contact/ContactModel'
import API from '@/api/Api'
import ApiResponseEnum from '@/api/enums/ApiResponseEnum'

export default {
  name      : 'PageContact',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta, FormDataCommon],
  props     : {
    pageTitle: {
      type   : String,
      default: ''
    },

    pageContent: {
      type   : String,
      default: ''
    }
  },
  enums    : {},
  dataStore: {},
  dataModel: ContactModel,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.$bus.$on('Auth:Login:Success', this.onLogin)
    this.$bus.$on('Auth:Logout:Success', this.onLogout)
    this.$nextTick(() => {
      this.onLogin()
    })
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$bus.$off('Auth:Login:Success', this.onLogin)
    this.$bus.$off('Auth:Logout:Success', this.onLogout)
  },
  destroyed () {
  },
  methods: {
    onLogin () {
      if (this.userIsAuthenticated && this.model) this.model = new ContactModel(this.user.Info)
    },

    onLogout () {
      this.resetForm()
    },

    onContactClick () {
      this.saveFromData()
    },

    saveFromData () {
      if (!this.validateForm() || this.isFormSaving) return
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      this.isFormSaving = true

      this.model.Send()
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.handleFormSaveResponse(error.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },

    handleFormSaveResponse (response) {
      const data = API.responseData(response) || {}
      const status = response?.data?.status
      const message = data?.Messages

      if (API.isResponseSuccess(response) && data.Success) {
        if (data.Success) {
          this.formSaveSuccess(data)
          this.resetForm()

          this.$bus.$emit('app:show-notification', {
            body   : data.Success,
            type   : 'success',
            icon   : 'mdi-check-circle',
            timeout: 5000
          })
        } else {
          if (status === ApiResponseEnum.VALIDATOR_ERROR) {
            this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
          } else if (status === ApiResponseEnum.GENERIC_ERROR) {
            this.setServerErrorMessages(message)
          } else {
            this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
          }
        }
      } else {
        if (status === ApiResponseEnum.VALIDATOR_ERROR) {
          this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
        } else if (status === ApiResponseEnum.GENERIC_ERROR) {
          this.setServerErrorMessages(message)
        } else {
          this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
