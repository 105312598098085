import BaseResource from '@/lib/data/resource/BaseResource'
import PaymentTypeEnum from '@/api/enums/PaymentTypeEnum'
import GuestModel from '@/api/models/customer/GuestModel'

export default class CheckoutPaymentResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Customer = new GuestModel(payload.Customer ?? null)
    this.Payment = payload.Payment ?? PaymentTypeEnum.CASH
    this.QrData = payload.QrData ?? null
    this.Comment = payload.Comment ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
