<template>
  <v-row justify="center">
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          flat
          height="80"
          max-height="80"
        >
          <v-avatar>
            <v-icon
              color="brand-primary"
              x-large
            >
              mdi-login
            </v-icon>
          </v-avatar>

          <v-toolbar-title class="pl-3">
            <div class="body-3">
              {{ $t('Auth.ResetPasswordDialog.Title') }}
            </div>
            <div class="text-caption">
              {{ $t('Auth.ResetPasswordDialog.Subtitle') }}
            </div>
          </v-toolbar-title>

          <v-spacer />

          <v-btn
            class="grey lighten-3 ma-0"
            color="black"
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider />

        <v-card-text class="pa-4">
          <v-form
            v-if="model"
            ref="form"
            @submit.prevent
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="model.Email"
                    :hint="$t('Auth.ResetPasswordDialog.Field.Email.Hint')"
                    :label="$t('Auth.ResetPasswordDialog.Field.Email.Label')"
                    :placeholder="$t('Auth.ResetPasswordDialog.Field.Email.Placeholder')"
                    :rules="model.validator.vuetifyFormFieldRules('Email')"
                    clearable
                    name="Email"
                    outlined
                    prepend-inner-icon="mdi-email"
                    type="text"
                  />
                </v-col>

                <v-col
                  v-if="showGenericError && genericError"
                  class="py-0"
                  cols="12"
                >
                  <v-alert
                    border="left"
                    dense
                    text
                    type="error"
                  >
                    {{ genericError }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-btn
                    :disabled="isFormSaving"
                    :loading="isFormSaving"
                    block
                    class="text-capitalize white--text"
                    color="brand-primary"
                    depressed
                    x-large
                    @click="onResetPasswordClick"
                  >
                    {{ $t('Auth.Button.ResetPassword') }}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    block
                    class="text-none"
                    plain
                    small
                    @click="onLoginClick"
                  >
                    {{ $t('Auth.Button.WithAccount') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import DialogVisible from '@/mixins/dialog/dialogVisible'
import DialogData from '@/mixins/dialog/dialogData'
import API from '@/api/Api'
import ResetPasswordModel from '@/api/models/user/ResetPasswordModel'

export default {
  name      : 'ResetPasswordDialog',
  components: {},
  mixins    : [DialogVisible, DialogData],
  props     : {},
  dataModel : ResetPasswordModel,
  data () {
    return {}
  },
  computed: {},
  methods : {
    onLoginClick () {
      this.closeDialog()
      this.$bus.$emit('login:show-dialog')
    },

    onResetPasswordClick () {
      this.saveFromData()
    },

    saveFromData () {
      if (!this.validateForm() || this.isFormSaving) return
      if (typeof this.clearServerErrorMessages === 'function') this.clearServerErrorMessages()
      this.isFormSaving = true

      this.model.ResetPassword()
        .then(response => {
          this.handleFormSaveResponse(response)
        })
        .catch(error => {
          this.handleFormSaveResponse(error.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },

    handleFormSaveResponse (response) {
      const data = API.responseData(response) || {}
      const message = data?.Messages

      if (API.isResponseSuccess(response)) {
        if (message.Success) {
          this.formSaveSuccess(data)
        } else {
          this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
        }
      } else {
        this.formSaveError(message?.Error || message?.Warning || message?.Info, true)
      }
    }
  }
}
</script>

<style scoped>

</style>
