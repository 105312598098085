import API from '@/api/Api'
import { clone } from '@/lib/utils/helper'
import NotificationTypeEnum from '@/api/enums/NotificationTypeEnum'
import { isArray, isObject } from '@/lib/utils/type'
import StoreCommon from '@/mixins/store/storeCommon'

export default {
  name: 'cartActions',

  dataStore: {
    AppCart: 'App.Cart'
  },

  mixins: [StoreCommon],

  data () {
    return {
      IsLoading    : false,
      couponLoading: false,
      couponValue  : ''
    }
  },

  created () {
    this.$bus.$on('cart:get-data', this.cartGet)
    this.$bus.$on('cart:set-data', this.cartSetData)

    const excludedRoutes = ['Home', 'Store', 'CheckoutDetails']
    if (!excludedRoutes.includes(this.$route.name)) this.cartGet()
  },

  beforeDestroy () {
    this.$bus.$off('cart:get-data', this.cartGet)
    this.$bus.$off('cart:set-data', this.cartSetData)
  },

  computed: {
    CartPromiseQueue () {
      return window.CartPromiseQueue
    }
  },

  methods: {
    cartGet () {
      this.CartPromiseQueue.push(() => API.Resource.Cart.Get()
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.cartSetData(API.responseData(response))
          }
        })
        .catch(e => {
        })
        .finally((response) => {
          this.$bus.$emit('cart:get:response', API.responseData(response))
        }))
    },

    cartAddProduct (product) {
      if (!product || product?.Loading) return
      const payload = { menu: product }

      this.setCartProductLoader(product.RowId, true)
      this.setCatalogProductLoader(product.Id, true)

      this.CartPromiseQueue.push(() => API.Resource.Cart.Add(payload)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.cartSetData(API.responseData(response))
          }
        })
        .catch(e => {
        })
        .finally((response) => {
          this.setCartProductLoader(product.RowId, false)
          this.setCatalogProductLoader(product.Id, false)
          this.$bus.$emit('cart:add:response', product, API.responseData(response))
        }))
    },

    cartRemoveProduct (product) {
      if (!product || product?.LoadingRemove) return
      const payload = { menu: product }

      this.setCartProductLoader(product.RowId, true, 'LoadingRemove')

      this.CartPromiseQueue.push(() => API.Resource.Cart.Remove(payload)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.cartSetData(API.responseData(response))
          }
        })
        .catch(e => {
        })
        .finally((response) => {
          this.setCartProductLoader(product.RowId, false, 'LoadingRemove')
          this.$bus.$emit('cart:remove:response', product, API.responseData(response))
        }))
    },

    cartProductOptions (product) {
      if (!product || product?.LoadingShow) return

      this.setCartProductLoader(product.RowId, true, 'LoadingShow')

      this.CartPromiseQueue.push(() => API.Resource.Cart.Options(product.StoreId, product.Id, product.RowId)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const product = clone(API.responseData(response))
            product.ModifierRuns = 0
            this.selectedProduct = product
            this.productDetailsDialogVisible = true
          }
        })
        .catch(e => {
        })
        .finally((response) => {
          this.setCartProductLoader(product.RowId, false, 'LoadingShow')
          this.$bus.$emit('cart:options:response', API.responseData(response))
        }))
    },

    cartAddCoupon (coupon) {
      if (!coupon || this.couponLoading) return

      this.couponLoading = true

      this.CartPromiseQueue.push(() => API.Resource.Cart.CouponAdd(coupon)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.cartSetData(API.responseData(response))
          }
        })
        .catch(e => {
        })
        .finally((response) => {
          this.couponLoading = false
          this.$bus.$emit('cart:coupon-add:response', coupon, API.responseData(response))
        }))
    },

    cartRemoveCoupon (coupon) {
      if (!coupon || this.couponLoading) return

      this.couponLoading = true

      this.CartPromiseQueue.push(() => API.Resource.Cart.CouponRemove(coupon)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            this.cartSetData(API.responseData(response))
          }
        })
        .catch(e => {
        })
        .finally((response) => {
          this.couponLoading = false
          this.$bus.$emit('cart:coupon-remove:response', coupon, API.responseData(response))
        }))
    },

    cartSetData (data) {
      if (!data) return

      const cartData = {
        Items        : data?.Items || [],
        Stores       : data?.Stores || [],
        Totals       : data?.CartTotals || [],
        Messages     : data?.Messages || [],
        CartTotal    : data?.CartTotal || 0,
        OrderTotal   : data?.OrderTotal || 0,
        TotalItems   : data?.TotalItems || 0,
        PaymentMethod: this.selectedPaymentMethod
      }

      this.$set(this, 'AppCart', cartData)

      this.cartParseMessages(cartData)
      this.cartUpdateStores(cartData)
    },

    cartUpdateStores (cartData) {
      if (!isArray(cartData?.Stores)) return

      cartData.Stores.forEach(cartStore => {
        const store = this.getStoreById(cartStore.StoreId)
        if (store) store.Status = cartStore
      })
    },

    cartParseMessages (data) {
      const CartMessages = data.Messages || []

      if (isObject(CartMessages)) {
        if (CartMessages.Success) {
          this.showCartNotification(CartMessages.Success, '', '', '', NotificationTypeEnum.SUCCESS)
        } else if (CartMessages.Info) {
          this.showCartNotification(CartMessages.Info, '', '', '', NotificationTypeEnum.INFO)
        } else if (CartMessages.Warning) {
          this.showCartNotification(CartMessages.Warning, '', '', '', NotificationTypeEnum.WARNING)
        } else if (CartMessages.Error) {
          this.showCartNotification(CartMessages.Error, '', '', '', NotificationTypeEnum.ERROR)
        }
      }

      if (isArray(CartMessages)) {
        CartMessages.forEach(item => {
          const store = this.getStoreById(item.StoreId)

          if (item.Messages?.Success) {
            this.showCartNotification(item.Messages.Success, store.Name || '', '', store.Logo || '', NotificationTypeEnum.SUCCESS)
          } else if (item.Messages?.Info) {
            this.showCartNotification(item.Messages.Info, store.Name || '', '', store.Logo || '', NotificationTypeEnum.INFO)
          } else if (item.Messages?.Warning) {
            this.showCartNotification(item.Messages.Warning, store.Name || '', '', store.Logo || '', NotificationTypeEnum.WARNING)
          } else if (item.Messages?.Error) {
            this.showCartNotification(item.Messages.Error, store.Name || '', '', store.Logo || '', NotificationTypeEnum.ERROR)
          }

          if (item.Fetch) this.getStoreMenu(item.StoreId)
        })
      }
    },

    getStoreMenu (storeId) {
      if (this.$route.name !== 'Store' || parseInt(this.$route.params.id) !== parseInt(storeId)) return
      this.$bus.$emit('store:get-menu')
    },

    setCartProductLoader (rowId, loading, property = 'Loading') {
      if (!rowId) return

      const cartProduct = this.getProductInCartByRowId(rowId)
      if (cartProduct) this.$set(cartProduct, property, loading)
    },

    getProductInCartByRowId (rowId) {
      return this.AppCart?.Items?.find(item => item.RowId === rowId) || null
    },

    setCatalogProductLoader (productId, loading, property = 'Loading') {
      if (!productId) return

      const catalogProduct = this.getProductInCatalogById(productId)
      if (catalogProduct) this.$set(catalogProduct, property, loading)
    },

    getProductInCatalogById (productId) {
      if (!productId) return null

      const storeMenuItems = this.StoreMenu?.items || []
      for (let i = 0; i < storeMenuItems.length; i++) {
        const products = storeMenuItems[i]?.Items || []
        for (let ii = 0; ii < products.length; ii++) {
          if (products[ii].Id === productId) {
            return products[ii]
          }
        }
      }

      return null
    },

    showCartNotification (message, title, icon, img, type = 'success') {
      if (!message) return

      this.$bus.$emit('app:show-notification', {
        title: title || '',
        body : message || '',
        type : type || 'success',
        icon : icon || '',
        img  : img || ''
      })
    }
  }
}
